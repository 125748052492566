import React, { useEffect, useState, useRef }from 'react'
// import { css } from '@emotion/core'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link as GatsbyLink, navigate } from 'gatsby'
// import { navigate } from "@reach/router"
import { connect } from 'react-redux';
import { ScrollTo, ScrollArea, ScrollToHOC } from "react-scroll-to";
import { CSSTransition } from 'react-transition-group';
import smoothscroll from 'smoothscroll-polyfill';
import arrow_cta from '../../images/other/cta-arrow.svg'
import IconFacebook from '../../images/overlay/icon-facebook.svg'
import IconInstagram from '../../images/overlay/icon-instagram.svg'
import IconTwitter from '../../images/overlay/icon-twitter.svg'
import MapIcon from '../../images/overlay/overlay-icon-map.svg'
import IconPhone from '../../images/overlay/overlay-icon-phone.svg'
import IconStreet from '../../images/overlay/overlay-icon-pin.svg'
import IconWeb from '../../images/overlay/overlay-icon-world.svg'
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX,resetActiveCategory, setSlideIndex, setInitializedSlider } from '../../state/actions'
import { GlobalStateContext } from "../ParticipantInfinite/GlobalState"
import View from "../ParticipantInfinite/View";
import ImageCarousel from "./ImageCarousel";
import ParticipantScrollContainer from './ParticipantScrollContainer'
import FluidImage from "./SingleImage"
// import {  } from "@reach/router"




// const ScrollHOC = ScrollToHOC(function(props) {
//   const { children, scroll} = props;
//   smoothscroll.polyfill();

//   console.log('ScrollToHoc scroll prop: ', scroll)
//   return (
//     <>
//       <h1>Above Scroll area</h1>
//       <ScrollArea 
//         css={css`
//         height: calc(var(--vh, 1vh) * 100 - 95px);
//         height: 5000px;
//         background: magenta;
//         overflow-y: scroll;
//     `}
      
//         className="lll"
//         id="fooz"
//       >

//         <button
//           type="button"
//           onClick={() => { 

//             console.log('ScrollToHoc onClick, scrollfunc: ', scroll)
//             // props.scroll({ id: "fooz", y: 500 })
//             scroll({
//               id: "fooz",
//               smooth: true,
//               y: 5000
//             });
//             // scroll({ y: 5000 })
//           }}
//         >
//           Scroll to TopZ
//         </button>
//         {children}
//       </ScrollArea>
//       <ScrollArea style={{ height: 1000 }}>
//         This container wont scroll
//       </ScrollArea>
//     </>
//   );
// })



const nextButtonText = '<span class="mi-arrow-cta2" ></span>'

const MapButtonMobile = styled(GatsbyLink)`
  display: flex;
  width: 80px;
  height: 40px;


  border: solid 2px #f6efeb;
  background-color: #f6efeb;
  border-radius: 20px;

  font-family: SofiaW01;
  font-size: 11px;
  font-weight: bold;


  line-height: 1.4;


  color: var(--black);
  position: relative;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  img{
    margin-left: 4px;
  }

  position: fixed;
  top: 19px;
  right: 18px;

  pointer-events: all;
  @media(min-width: 768px){
    display: none;
  }
`

const DebugOverlay = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: yellow;

`


const ParticipantSingle = (props) => {
  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const { data, pageContext, isStateInitialized, 
    isMapView, isCategoryView, isCategoryViews, activated_participant_id, active_participant_id,
    initParticipantCategory, setInitalizedToTrue,
    setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, participants, setCurrentParticipants, setActivatedParticipantOffsetX, lastCategoryRoute, activeCategoryId, activeCategorySlug, resetActiveCategory, participantData, browserWidth, setSlideIndex, setInitializedSlider, blah, isCaView, isMaView, pData, filteredParticipants} = props;




  // console.log('\n\n\n SINGLE VIEW lastCategoryRoute: ',  lastCategoryRoute)
  const isCatV = isCaView; // isCategoryView; // || lastCategoryRoute === '/erkunden/'
  const isSingleView =  isMapView && !isCatV;

  const isSingView = !isCaView && isMaView
  const [showParticipantContainer, setShowParticipantContainer] = useState(isSingView);
  
  // const getTimeout = (width) => {
  //   return  width && width < 768 ? { enter: 2300, exit: 1300}  : {enter: 2800, exit: 1800}
  // }

  const getTheTimeout = (width) => {
    // return  width && width < 768 ? 400  : 900
    // const newTimeOut = (width && width < 768) ? { enter: 2300, exit: 1300}  : {enter: 2800, exit: 1800}
    const newTimeOut = (width && width < 768) ? { enter: 1300, exit: 300}  : {enter: 1200, exit: 600}
    // console.log('new Timeout: ', newTimeOut);
    return  newTimeOut
  }

  const timeout = getTheTimeout(browserWidth)
  const [overlayTimeout, setOverlayTimeout] = useState(timeout);
  console.log('\n\n\n overlayTimeout: ', overlayTimeout)

  // const [slideIndex, setSlideIndex] = useState(0);


  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      console.log('mounted: isCategoryView', isCaView)
      setShowParticipantContainer(isSingView && !isCaView)

      // setOverlayTimeout(getTheTimeout(browserWidth))
    } else {
      console.log('upat3d: isCategoryView', isCaView)
      setShowParticipantContainer(isSingView && !isCaView)
      // setOverlayTimeout(getTheTimeout(browserWidth))
    }
    if(browserWidth){
      console.log('browserWidth: ', browserWidth)
      // setOverlayTimeout(getTheTimeout(browserWidth))
    }
  })

  // const dynamicStyle = (props) => css`
  //   background-color: ${props.isCategoryView ? 'rgba(255,255,0,0.2)' : 'rgba(255,0,0,0.2)'};
  //   pointer-events: ${props.isCategoryView ? 'none' : 'all'};
  //   `

  console.log('ParticipantSingle isCatV: ', isCaView)
  console.log('ParticipantSingle isSingleView: ', isSingView)
  const ParticipantContainer= styled.div`

  width: 100vw;
  height: calc(100vh - 80px);
  height: calc(var(--vh, 1vh) * 100 - 80px);
  position relative;
  z-index: 8;
  top 0;
  left: 0;

  overflow-y: scroll;

  position: absolute;
  top: 80px;
  transition-property: all;
  transition-duration: 5s;

`;


  // const {
  //   id,
  //   databaseId,
  //   title,
  //   slug,
  //   uri,
  //   participantCategories,
  //   mi_participant
  // } = data.wpParticipant

  // const {
  //   miParticipantSubheadline,
  //   miParticipantPhone,
  //   miParticipantOpeningTimes,
  //   miParticipantOpeningTimesTextarea,
  //   miParticipantContent,
  //   miParticipantPrivateWebsite,
  //   miParticipantFacebook,
  //   miParticipantInstagram,
  //   miParticipantTwitter,
  //   miParticipantGeodata,
  //   miParticipantHeroSlider,
  // } = mi_participant




  // let SomeComponent = styled('div')`${dynamicStyle}` 
  // const { isCategoryView = false } = props;


  // const getTransitionTimingFunction = (isCategoryView, isSingleView) => {
    
  //   if(isCategoryView){
  //     return 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  //   }
  //   if(isSingleView){
  //     return 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'
  //   }
  //   return 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  // }
  const StyleFluidImageWrapper = styled.div`   
  flex: 1 0 100%;
`


  const StreetWebPhone = styled.div`
  // padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
  flex-wrap: wrap;
  @media(min-width: 768px){
    flex-wrap: nowrap;
    padding: 5px 48px;
    justify-content: flex-start;
  }
`

  const Street = styled.div`
    position: relative;
    &::before{
      background: url(${IconStreet});
      background-repeat: no-repeat;
      background-position: center 2px;
      background-size: contain;
      content: ' ';
      position: absolute;
      width: 18px;
      height: 21px;
      left: 0;
    }
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 22px;

    font-family: SofiaW01;
    font-size: 12px;
    line-height: 1.18;
    margin-right: 26px;
    text-decoration: underline;


    @media(min-width: 768px){
      font-size: 14px;
      line-height: 1.29;
    }
`

  const Web = styled.div`
  position: relative;
  &::before{
    background: url(${IconWeb});
    background-repeat: no-repeat;
    background-position: center 1px;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
  }
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 22px;

  font-family: SofiaW01;
  font-size: 12px;
  line-height: 1.18;
  margin-right: 26px;
  text-decoration: underline;

  @media(min-width: 768px){
    font-size: 14px;
    line-height: 1.29;
  }
`

  const Phone = styled.div`
  position: relative;
  &::before{
    background: url(${IconPhone});
    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 18px;
    height: 21px;
    left: 0;
  }
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 22px;

  font-family: SofiaW01;
  font-size: 12px;
  line-height: 1.18;
  margin-right: 26px;
  text-decoration: underline;
  margin-right: 0;

  @media(min-width: 768px){
    font-size: 14px;
    line-height: 1.29;
  }
`
  const SingleHeadline = styled.h2`
  font-family: Rockwell;

  font-weight: bold;

  padding: 0 24px;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1.3;
  @media(min-width: 768px){
    padding: 0 48px;
    margin-top: 56px;
    font-size: 36px;
    line-height: 1.28;
    letter-spacing: normal;
  }
`

  const SingleSubHeadline = styled.span`
  display: flex;
  height: 32px;
  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 24px;
  color: ${({ theme }) => theme.mi_red};
  @media(min-width: 768px){
    padding: 0 48px;
  }
`

  const SingleContent = styled.div`
  padding: 0 24px 39px 24px;
  p{
    font-family: SofiaW01;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    max-width: 624px;
  }
  ul, li {
    font-family: SofiaW01;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    max-width: 624px;
  }
  h3{
    font-family: SofiaW01;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.53px;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 624px;
    &:first-of-type{
      margin-top: 26px;
    }
  }
  @media(min-width: 768px){
    padding: 0 50px 72px 48px;
    p, ul, li{
      font-size: 18px;
      line-height: 1.78;
    }
    p{
      margin-bottom: 32px;
    }
    h3{
      letter-spacing: 0.6px;
      font-size: 18px;
      &:first-of-type{
        margin-top: 54px;
      }
    }
  }
`

  const MetaContainerWrap = styled.div`
border-top: 2px solid ${({ theme }) => theme.mi_background_real};
  &:last-of-type{
    border-bottom: 2px solid ${({ theme }) => theme.mi_background_real};
  }
  @media(min-width:768px){
    border-top: 1px solid ${({ theme }) => theme.mi_background_real};
    &:last-of-type{
      border-bottom: 1px solid ${({ theme }) => theme.mi_background_real};
    }
  }
`




  const PictureCreditContainerWrap = styled.div`
border-top: 2px solid ${({ theme }) => theme.mi_background_real};
&:last-of-type{
  border-bottom: 2px solid ${({ theme }) => theme.mi_background_real};
}
@media(min-width:768px){
  border-top: 1px solid ${({ theme }) => theme.mi_background_real};
  &:last-of-type{
    border-bottom: 1px solid ${({ theme }) => theme.mi_background_real};
  }
}
`


  const MetaContainer = styled.div`
  max-width: 624px;
padding: 39px 24px 48px 24px;
@media(min-width: 768px){
  margin: 33px 48px 42px 48px;
  padding: 0;
  display: flex;
}`

  const NextContainerWrap = styled.div`
// background: #9ac9df;
border-top: 2px solid ${({ theme }) => theme.mi_background_real};
&:last-of-type{
  border-bottom: 2px solid ${({ theme }) => theme.mi_background_real};
}
@media(min-width:768px){
  border-top: 1px solid ${({ theme }) => theme.mi_background_real};
  &:last-of-type{
    border-bottom: 1px solid ${({ theme }) => theme.mi_background_real};
  }
}
`


  const NextContainer = styled.div`
  // background: #9ac9df;
  max-width: 624px;
  padding: 39px 24px 48px 24px;
  @media(min-width: 768px){
    margin: 33px 48px 42px 48px;
    padding: 0;
    display: flex;
  }`




  const PictureCreditContainer = styled.div`
max-width: 624px;
padding: 39px 24px 48px 24px;

p{
  font-family: SofiaW01;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin: 0;
}

@media(min-width: 768px){
  padding: 33px 48px 42px 48px;
  display: flex;
}`


  const OpeningContainer = styled.div`
@media(min-width: 768px){
  flex: 1 0 auto;
}
`

  const MoreLinksContainer = styled.div`
@media(min-width: 768px){
  flex: 1 0 auto;
}
`
 
  const OpeningHeadline = styled.h3`
  font-family: SofiaW01;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 22px;
`
  const OpeningItemContainer = styled.ul`
  list-style: none;
display:flex;
flex-wrap: wrap;
justify-content: space-between;
max-width: 200px;
margin-bottom: 36px;
padding-left: 0;
@media(min-width: 768px){
  margin-bottom: 0;
}
`

  const OpeningItem = styled.li`
font-family: SofiaW01;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
display: flex;
flex: 1 0 100%;

.left{
  flex: 1 0 35%;
}
.right{
  flex: 1 0 65%;
}
&.hasAddition{
  &:last-of-type{
    margin-bottom: 24px;
  }
}

`

  const OpeningItemAddition = styled.div`
font-family: SofiaW01;
font-size: 16px;
line-height: 1.5;
letter-spacing: normal;
display: flex;
flex: 1 0 100%;
`



  const FacebookTwitterInsta = styled.div`
padding: 0;
display: flex;
display: flex;
`

  const Facebook = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconFacebook});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`
  const Twitter = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconTwitter});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`
  const Instagram = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconInstagram});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`




  const ButtonNext= styled.button`
  outline: none;
  z-index: 3;
  border-radius: 6px;
  border: 1px solid black;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.mi_white};
  color: ${({ theme }) => theme.mi_black};

  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 24px;


  width: 100%;
  text-decoration: none;
  .mi-arrow-cta{
    display: block;
    width: 18px;
    height: 10px;
    align-self: center;
    background-image: url(${arrow_cta});
    background-size: contain;
    background-repeat: no-repeat;

    align-self: flex-end;
    margin-bottom: 5px;
    margin-left: 8px;
  }
  .mi-arrow-cta2{
    display: block;
    width: 18px;
    height: 10px;
    align-self: center;
    background-image: url(${arrow_cta});
    background-size: contain;
    background-repeat: no-repeat;

    align-self: flex-end;
    margin-bottom: 5px;
    margin-left: 8px;
    margin-right: 8px;
  }
  @media(min-width: 768px){
    margin: 36px 0px 36px 0;
    // white-space: nowrap;
    // max-width: 302px;
    margin-left: 0;
    margin-top: 36px;
    // margin-bottom: 100px;

    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: transform, box-shadow;
    transition-duration: 150ms;
    &:hover{
      transform: scale3d(1.02,1.02,1.02);
      box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.12);
    }
  }
`




  // console.log('participantData: ', participantData)

  const ppp = participantData || pData;
  const hasOpeningTimes = ppp ? ppp.miParticipantOpeningTimes && 
  ppp.miParticipantOpeningTimes.length ||
  ppp.miParticipantOpeningTimesTextarea : false

  const hasOpeningTimesAndAddition = ppp ? ppp.miParticipantOpeningTimes && 
  ppp.miParticipantOpeningTimes.length &&
  ppp.miParticipantOpeningTimesTextarea : false

  const hasMoreLinks = ppp ? ppp.miParticipantFacebook || 
  ppp.miParticipantTwitter ||
  ppp.miParticipantInstagram : false


  const encodedStreet = ppp? encodeURI(ppp.miParticipantGeodata.streetAddress) : '';

  const handleMapButtonClick = e => {
    console.log('####handle map button click')
    // setSlideIndex(0)
    setIsMapView(true);
    setIsCategoryView(true);
    setInitializedSlider(false)

  }

  const nextItemTitle = () => {
    const currentIndex = filteredParticipants.indexOf(active_participant_id);
    const nextIndex = (currentIndex + 1) % filteredParticipants.length;
    const nextId = filteredParticipants[nextIndex]
    const nextPareticipant = participants.find(p => p.id === nextId);

    const getNextParticipantTitle = (p) => {
      return p.shortTitle ? p.shortTitle : p.title
    }

    return nextPareticipant ? `${getNextParticipantTitle(nextPareticipant)}` : '';
  }

  const handleNextButtonClick = () => {
    console.log('####handle next button click')
    // setSlideIndex(0)
    const currentIndex = filteredParticipants.indexOf(active_participant_id);
    const nextIndex = (currentIndex + 1) % filteredParticipants.length;
    const nextId = filteredParticipants[nextIndex]
    const nextPareticipant = participants.find(p => p.id === nextId);

    console.log('ututu currentIndex: ', currentIndex)
    console.log('ututu nextIndex: ', nextIndex)
    console.log('ututu currentId: ', active_participant_id)
    console.log('ututu nextId: ', nextId)
    console.log('ututu nextPareticipant: ', nextPareticipant)
    // console.log('ututu uri: ', nextPareticipant.uri)



    // console.log('hhh handleClick, e:', e);
    // console.log('hhh handleClick, window.location:', window.location);
    // e.preventDefault();
    // console.log('hhh handleClick, id: ', id)
    // console.log('hhh handleClick, ref: ', refi)
    // console.log('hhh handleClick, scrollFunc: ', scrollFunc)
    // const history = createHistory(window)
    // console.log('hhh handleClick, history: ', history)

    // history.navigate(`${window.location.pathname}#fcard_${id}`);
    // setActivePageAnchor(id);

    
    // console.log('offsetTop: ', refi.current.offsetTop)
    // // scrollFunc({ ref: refi, x: 20, y: 1500 })


     
    // scrollFunc({ y: refi.current.offsetTop - scrollOffset(is_mobile, index), smooth: true })
    if(nextPareticipant){
      // setActiveParticipant(nextPareticipant.id)
      // activateParticipant(nextPareticipant.id)
      navigate(nextPareticipant.uri);
      
      activateParticipant(nextPareticipant.id)
      setActiveParticipant(nextPareticipant.id)
      
      
      setTimeout(() => {
        setInitializedSlider(false)
      }, 200)
    }


    // scrollFunc({ x: 0, y: -110, smooth: true })

  }

  function convertString(input){
    return input.split('<a').join('<a target="_blank" rel="noopener noreferrer" ')
  }
  
  return (
    <GlobalStateContext.Consumer>
      {g => (

        <div
          style={
            {
              width: '100vw',
              height: '100%',
              zIndex: '8',
              left: '0',
              overflowY: 'scroll',
              position: 'absolute',
              pointerEvents: 'none'
            }
          }
        >
          <CSSTransition
            in={showParticipantContainer}
            timeout={overlayTimeout}
            // timeout={2000}
            classNames="misingle"
            // unmountOnExit
          >
            <div className={isSingView? 'misingle misingle-direct' : 'misingle'}>
              {ppp ? (
                <>

                  <ParticipantScrollContainer active_participant_id={active_participant_id}> 
                    {/* {ppp ? (
                      <> */}
                    {/* <div>isSingView: {isSingView? 'true' : 'false'}</div>
            <div>showParticipantContainer: {showParticipantContainer? 'true' : 'false'}</div> */}
                    {/* <DebugOverlay>
                <div>
                  id:
                  {participantData.id}
                </div>
                <div>
                  active_participant_id:
                  {active_participant_id}
                </div>
              </DebugOverlay> */}
                    <ImageCarousel images={ppp.miParticipantHeroSlider} />
                    {/* <StyleFluidImageWrapper>
                <FluidImage image={participantData.miParticipantHeroSlider[0]} style={{marginBottom: '96px', marginTop:'96px'}} />
              </StyleFluidImageWrapper> */}
                    <StreetWebPhone>
                      {ppp.miParticipantGeodata.streetName ? (
                        <Street>
                          <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${encodedStreet}&query_place_id=${ppp.miParticipantGeodata.placeId}`} title={`${ppp.miParticipantShorttitle} auf Google Maps`}>
                            {ppp.miParticipantGeodata.streetName} 
                            {' '}
                            {ppp.miParticipantGeodata.streetNumber}
                          </a>

                        </Street>
                      )
                        : null}
                      {ppp.miParticipantPrivateWebsite ? (
                        <Web><a rel="noopener noreferrer" target="_blank" href={ppp.miParticipantPrivateWebsite}>Website</a></Web>
                      ) : null}
                      {ppp.miParticipantPhone ? (
                        <Phone>
                          <a href={`tel:${ppp.miParticipantPhone}`}>{ppp.miParticipantPhone}</a>
                        </Phone>
                      ) : null}
                    </StreetWebPhone>

                    <SingleHeadline>
                      {/* {active_participant_id} 
                    {' '} */}
                      {ppp.title}
                    </SingleHeadline>
                    {ppp.miParticipantSubheadline?
                      <SingleSubHeadline>{ppp.miParticipantSubheadline}</SingleSubHeadline>
                      : null}
              
                    <SingleContent key={`single_content_${ppp.id}`} dangerouslySetInnerHTML={{ __html: convertString(ppp.miParticipantContent) }} />
                    {hasOpeningTimes || hasMoreLinks ? (
                      <MetaContainerWrap>
                        <MetaContainer>
                          {hasOpeningTimes ? (
                            <OpeningContainer>
                              <OpeningHeadline>Öffnungszeiten</OpeningHeadline>
                              <OpeningItemContainer>
                                {ppp.miParticipantOpeningTimes && ppp.miParticipantOpeningTimes.length ?
                                  (
                                    <>
                                      {ppp.miParticipantOpeningTimes.map((o,index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                        <OpeningItem className={hasOpeningTimesAndAddition ? 'hasAddition' : ''} key={`otimes_${ppp.id}_${index}`}>
                                          <div className="left">{o.miParticipantOpeningTimesDay}</div>
                                          <div className="right">{o.miParticipantOpeningTimesTimeframe}</div>
                                        </OpeningItem>
                                      ))}
                                    </>

                                  ) : null}

                                {ppp.miParticipantOpeningTimesTextarea?  <OpeningItemAddition>{ppp.miParticipantOpeningTimesTextarea}</OpeningItemAddition> : null}
                              </OpeningItemContainer>
                            </OpeningContainer>
                          ) : null}

                          {
                            hasMoreLinks ? (
                              <MoreLinksContainer>
                                <OpeningHeadline>Weitere Links</OpeningHeadline>

                                <FacebookTwitterInsta>
                                  {ppp.miParticipantFacebook ? (
                                    <>
                                      <Facebook 
                                        rel="noopener noreferrer" 
                                        target="_blank" 
                                        href={ppp.miParticipantFacebook}
                                        title="Facebook"
                                      />
                                    </>
                                  ) : null}
                                  {ppp.miParticipantTwitter ? (
                                    <>
                                      <Twitter
                                        rel="noopener noreferrer" 
                                        target="_blank" 
                                        href={ppp.miParticipantTwitter} 
                                        title="Twitter"
                                      />
                                    </>
                                  ) : null}
                                  {ppp.miParticipantInstagram ? (
                                    <Instagram
                                      rel="noopener noreferrer" 
                                      target="_blank" 
                                      href={ppp.miParticipantInstagram} 
                                      title="Instagram"
                                    />
                                  ) : null}
                                </FacebookTwitterInsta>
                              </MoreLinksContainer>
                            ) : null
                          }

                        </MetaContainer>
                      </MetaContainerWrap>
                    ) : null }
                    {ppp.miPictureCredits? (
                      <PictureCreditContainerWrap>
                        <PictureCreditContainer>
                          <p>{ppp.miPictureCredits}</p>
                        </PictureCreditContainer>
                      </PictureCreditContainerWrap>
                    ) : null}
                    {filteredParticipants && filteredParticipants.length > 1 ? (
                      <NextContainerWrap>
                        <NextContainer>
                          <ButtonNext
                            title={`Nächstes Portrait „${nextItemTitle()}“ besuchen`}
                            onClick={handleNextButtonClick}
                            dangerouslySetInnerHTML={{ __html: `Nächstes Portrait „${nextItemTitle()}“ besuchen ${nextButtonText} `}}
                          />
                        
                          {/* <button type="button" onClick={() => scroll({ id: "foob", y: 500 })}>
                      Scroll to Bottom
                    </button> */}
                          {/* </>
                    ) : <h1>No data</h1>} */}
                        </NextContainer>
                      </NextContainerWrap>
                    ) : null}

                  </ParticipantScrollContainer>

                  <MapButtonMobile onClick={handleMapButtonClick} to={lastCategoryRoute || activeCategorySlug || '/erkunden/'}>
                    Karte   
                    <img src={MapIcon} alt="" />


                  </MapButtonMobile>
                  {/* <View globalState={g} pageContext={pageContext}  /> */}
                </>
              ) : null}

            </div>

          </CSSTransition>

        </div>

      )}
    </GlobalStateContext.Consumer>
  )

}

const getParticipantData = state => {
  const items = state.participants.items && state.participants.items.length;
  console.log('res res items: ', items)
  const item = items ? state.participants.items.find(p => p.id === state.participants.activated_participant_id) : false;

  console.log('res res item ?: ', item)

  const res = item && item.data ? item.data : null;
  console.log('res res: ', res);
  return res;
}
const mapStateToProps = state => {
  // console.log('mapStateToProps participantCategoryPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    lastCategoryRoute: state.route.href,
    participants: state.participants.items,
    // isMapView: state.map.isMapView,
    // isCategoryView: state.view.isCategoryView,
    // isMaView : state.map.isMapView,
    // isCaView : state.view.isCategoryView,
    active_participant_id: state.participants.active_participant_id,
    activated_participant_id: state.participants.activated_participant_id,
    activeCategoryId: state.participants.active_category.id,
    activeCategorySlug: state.participants.active_category.slug,
    participantData: getParticipantData(state),
    browserWidth: state.responsive.innerWidth,
    filteredParticipants: state.participants.filteredItems,

  }
};

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
  setActiveParticipant, 
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX,
  resetActiveCategory,
  setSlideIndex,
  setInitializedSlider
};

// function mergeProps(stateProps, dispatchProps, ownProps) {
//   // return { ...ownProps, todos: stateProps.todos[ownProps.userId],
//   //   addTodo: text => dispatchProps.addTodo(ownProps.userId, text)}
//   const mergedProps = { ...stateProps, ...ownProps, ...dispatchProps}
//   // return {...ownProps, ...dispatchProps}
//   return mergedProps;
// }

export default connect(mapStateToProps, mapDispatchToProps, null)(ParticipantSingle)


