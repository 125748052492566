/** @jsx jsx */
import { useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { ScrollToHOC, ScrollArea } from "react-scroll-to";

const ScrollContainer = ScrollToHOC(function (props) {
  const { children, scroll, active_participant_id, refs } = props;

  useEffect(() => {
    if (active_participant_id) {
      // const headerHeight = document
      //   .getElementById("filterbar-header")
      //   .getBoundingClientRect().height;
      // const elementTop = refs[active_participant_id].current.offsetTop;

      // const scrollToY = elementTop;

      const scrollToY = 0;

      // console.log(
      //   "offsetTop: ",
      //   elementTop,
      //   "headerHeight: ",
      //   headerHeight,
      //   " scrollToY: ",
      //   scrollToY
      // );
      scroll({
        id: "food",
        smooth: true,
        y: scrollToY
      });
    }
  }, [active_participant_id]);

  return (
    <ScrollArea
      css={css`
        flex: 1 0 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
      `}
      id="food"
    >
      {children}
    </ScrollArea>
  );
});

export { ScrollContainer as default };
