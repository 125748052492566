/** @jsx jsx */
import { useState, useEffect } from "react";
import { css, ClassNames, jsx } from "@emotion/core";
import styled from '@emotion/styled'
import { Link as GatsbyLink, navigate, useStaticQuery, graphql } from 'gatsby'

import { connect } from "react-redux";
import { useSpring, animated } from "react-spring";
import {
  Tooltip,
} from 'react-tippy';
import { CSSTransition } from 'react-transition-group';
import { setIsSearchView, searchUpdateInputValue, searchClearSuggestions, setIsSearchInitialResultsResults, setIsSearchResultsView, setFilteredParticipants } from '../../state/actions'

import Business from '../FilterBar/FilterButtons/Business'
import Link from '../Link'
import { MyConnectedAutoThingy } from "../SearchBarMobile"



const SearchWrapper = styled(animated.div)`
  // position: fixed;
  // top: 80px;
  // right: 80px;
  // width: 280px;
  // height: 42px;
  // display: flex;
  align-items: center;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // border: 1px solid lightgrey;
  // background-color: white;
  // z-index: 1;
  position: fixed;
  top: 0;
  left: 0;

  @media(min-width: 768px){

    left: 47px;
    height: 50px;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    border-radius: 50%;
    // background: yellow;
    top: 25px;
  }
`;


const SearchIconOpenClose = styled(animated.div)`
  display: block;
  // transform: translate3d(0,0,0);
  // background: yellow;
  position: fixed;
  right: 60px;
  @media(min-width: 768px){
    position: relative;
    right: initial;
  }

`
const StyledLink = styled(Link)`

  transition: 0.8s;
  width: 40px;
  height: 50px;
  margin: 0px 11px 0px 0;
  display: block;

  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 50ms;
  transition-delay: 50ms;
  background-color: transparent;
  &:hover{
    transform: scale3d(1.2,1.2,1.2);
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 150ms;
  }
  @media(min-width: 768px){
    transition-duration: 150ms;
    width: 50px;
    border-radius: 50%;
    background-color: #ffffff;
  }
`

const SVGWrap = styled.div`
transform: translate3d(0, 0, 0);
transition: 0.8s;
height: 50px;
width: 50px;
padding: 12px 0 0 12px;

@media(min-width: 768px){
  transition-delay: 200ms;
}
`

const SVGCircle = styled.svg`
width: 18px;
height: 18px;
display: block;

overflow: visible;

@media(min-width: 768px){
  transition-delay: 350ms;
}
`

const SVGLine = styled.svg`
overflow: visible;
width: 10px;
height: 10px;
display: block;
transition: 0.8s;


@medi(min-width: 768px){
  transform: translate3d(13px, -3px, 0);
  transition-delay: 350ms;
}
`

// const AutoThingy = animated(MyConnectedAutoThingy);
// const AutoThingy = animated(MyConnectedAutoThingy)
const AutoThingy = MyConnectedAutoThingy

const FilterCategories = (props) => {


  // console.log('>>>>FilterCategories props: ', props);

  const datas = useStaticQuery(graphql`
  query {
    allWpParticipantCategory {
        nodes {
          id
          name
          databaseId
          uri
          nodeType
        }
    }
    allWpTaxonomy( filter: { name: { eq: "moabit_participant_category" }} ) 
    {
      nodes {
        archivePath
        name
        nodeType
        restBase
      }
    }
  }
`)

  const data = [...datas.allWpTaxonomy.nodes, ...datas.allWpParticipantCategory.nodes ]


  const { 
    resetActiveCategory ,
    isMaView,
    isCaView,
    setActiveParticipant, activateParticipant, participants, setActivatedParticipantOffsetX,
    isPhone, innerWidth, setInitializedSlider,
    initialCategoryId, initialCategoryName, initParticipantCategory,
    isStateInitialized,
    isSearchView,
    setIsSearchView,
    searchUpdateInputValue, 
    searchClearSuggestions,
    lunrIndex,
    setIsSearchInitialResultsResults,
    setIsSearchResultsView,
    onLeave,
    onEnter,
    isSearchResultsView,
    resultCount,

    setFilteredParticipants,
  } = props


  const clearSearch = () => {
    console.log('mama clearSearch')
    searchUpdateInputValue('')
    searchClearSuggestions()
  }

  const isSingle = isMaView && !isCaView;

  const [searchInProp, setSearchInProp] = useState(isSearchView);


  const getAnimatedWidth = (toggle, isP, iWidth) => {
    console.log('getAnimatedWidth: isP, iWidth', isP, iWidth)

    if(isP && iWidth){
      console.log('YES getAnimatedWidth: isP && iWidth', iWidth)

      return toggle ? `${iWidth}px` : "0px"
    } 
    console.log('NO getAnimatedWidth: NOT isP && iWidth', iWidth)
    return toggle ? "412px" : "0px"
  }

  const getAnimatedIconPos = (toggle, isP, iWidth) => {
    console.log('getAnimatedIconPos: isP, iWidth', isP, iWidth)
    if(isP && iWidth){
      console.log('YES getAnimatedIconPos: isP && iWidth', iWidth)
      return toggle ? 'translate3d(85px,-10px,0)' : 'translate3d(5px,-10px,0)'
      // return toggle ? `translate3d(${iWidth-50},0,0)` : `translate3d(${iWidth-100},0,0)`
    } 
    console.log('NO getAnimatedIconPos: NOT isP && iWidth', iWidth)
    // return toggle ? "translate3d(362px,0,0)" : "translate3d(7px,0,0)"
    return "translate3d(11px,0,0)" 

  }

  // const [searchInput, setSearchInput] = useState("");
  const [toggle, setToggle] = useState(isSearchView);
  const [phone, setPhone] = useState(isPhone);
  const [innerW, setInnerW] = useState(innerWidth);
  // const animateWidth = useSpring({ width: toggle ? "412px" : "0px" });
  const animateWidth = useSpring({ width: getAnimatedWidth(toggle, phone, innerW)});
  const animatePos = useSpring({ 
    // transform: getAnimatedIconPos(toggle, phone, innerW),
    // background: phone && innerW ? 'blue' : 'red',
    // eslint-disable-next-line no-nested-ternary
    transform:  phone && innerW && toggle ? 'translate3d(40px, 10px, 0px);' : 
      toggle ? 'translate3d(362px,0,0)' :
      phone ? 'translate3d(5px, 15px, 0px)' :
        'translate3d(0px,0,0)'
  } );
  const animateAuto = useSpring({ opacity: toggle ? 1 : 0, width: getAnimatedWidth(toggle, isPhone, innerWidth), pointerEvents: toggle ? "all" : "none" });

  
  
  // const animateOpacity = useSpring({ opacity: toggle ? 0 : 1 });

  // const [backInProp, setBackInProp] = useState(isMaView && !isCaView && !isPhone);
  // const filterInProp = false;

  // setFilterInProp()
  // setBackInProp()
  // const initialFilterInProp = ;
  // const initialBackInProp = !filterInProp;

  useEffect(()=>{
    const isSingleView = isMaView && !isCaView;

    // single phone:
    // filter : true
    // back : false

    // category phone:
    // filter: true
    // back: false


    // else phone:
    // filter: false
    // back: false


    // single desktop:
    // filter: false
    // back: true


    // category desktiop:
    // filter: true
    // back false

    // else desktop:
    // filter: false
    // back: false

    if(isPhone){
      setPhone(true)
    } else {
      setPhone(false)
    }

    if(innerWidth){
      setInnerW(innerWidth)
    } else {
      setInnerW(0)
    }


    if(isCaView) {
      setSearchInProp(true)
      // setBackInProp(false)
      console.log('ANOW isCaView -> filter true, back false')
    } else if(isSingleView && isPhone){
      setSearchInProp(true)
      // setBackInProp(false)
      console.log('ANOW isSingleView && isPhone -> filter true, back false')

    } else if(isSingleView && !isPhone){
      setSearchInProp(false)
      // setBackInProp(true)
      console.log('ANOW isSingleView && !isPhone -> filter false, back true')

    } else {
      setSearchInProp(false)
      // setBackInProp(false)
      console.log('ANOW else -> filter false, back false')


    }

    if(isSearchView){
      setToggle(true)
    } else {
      setToggle(false)
    }
    // if(isSingleView && !isPhone){ // single
    //   console.log('ANOW setFilterInProp(true)')
    //   console.log('ANOW setBackInProp(false)')
    //   setFilterInProp(false)
    //   setBackInProp(true)

    //   // setBackInProp(true)
    // } else if(isCaView && !isPhone) {
    //   console.log('ANOW setFilterInProp(false)')
    //   console.log('ANOW setBackInProp(true)')

    //   setFilterInProp(false)
    //   setBackInProp(false)
    //   // setBackInProp(false)
    // }
  }, [isCaView, isMaView, isPhone, innerWidth, isSearchView])


  // console.log('FILTERBAR: isSINGLE: ', isSingle);

  // console.log('SETIS_MAPIFJDLS: ', setIsMapView)
  const triggerAction = (cat) => {
    setIsSearchView(true);
    // setIsMapView(true)
    // setIsCategoryView(true)
    // const newLocation = {
    //   pathname: cat? cat.uri : '/erkunden/'
    // }
    // updateRoute(newLocation)

    // if(cat){
    //   // console.log('triggerAction FilterCat: cat is there', cat )
    //   // console.log('participants: ', participants)
    //   // console.log('cat.databaseId: ', cat.databaseId)

    //   // console.log('Filter categories, cat: ', cat)
    //   // resetActiveCategory(cat.databaseId, cat.name, cat.uri)
    //   const firstOfCat = participants.find(p => p.categoryId === cat.databaseId)
    //   const firstOfCatId = firstOfCat? firstOfCat.id : null;


    //   initParticipantCategory(cat.databaseId, cat.name );
    //   console.log('FFF cat: ', cat);
    //   console.log('FFF participants: ', participants);
    //   console.log('FFF firstOfCatId: ', firstOfCatId);
    //   if(firstOfCatId){
    //     setActiveParticipant(firstOfCatId)
    //     activateParticipant(firstOfCatId)
    //     setActivatedParticipantOffsetX(24);
    //   }
    // } else {
    //   // console.log('triggerAction FilterCat: cat is not there', cat )
    //   const firstId = participants[0].id
    //   console.log('FFF cat: ', cat);
    //   console.log('FFF participants: ', participants);
    //   console.log('FFF firstOfCatId: ', firstId);
    //   // console.log('triggerAction FilterCat: cat is not there firstParticipantId', firstId )

    //   setActiveParticipant(firstId)
    //   activateParticipant(firstId)
    //   setActivatedParticipantOffsetX(24);

    //   resetActiveCategory(null)
    //   initParticipantCategory(null, null)
    // }
  }

  // const toIgnore = [
  //   'Weekly Rundown',
  //   'Design',
  //   'Inspiration',
  //   'Uncategorised',
  //   'UX',
  // ]

  const Wrapper = styled.section`
  width: 100vw;
  height: 60px;
  height: 88px;
  // border-top: solid 2px ${({ theme }) => theme.mi_white};
  // border-bottom: solid 2px ${({ theme }) => theme.mi_white};
  // background-color: ${({ theme }) => theme.mi_background_real};
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1 0 100vw;
  order: 2;
  z-index: 9;
  flex-wrap: wrap;


  posiion: fixed;

  @media(min-width: 768px){
    order: 3;

    /* display: none !important; */
    /*width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);*/
    position: fixed;
    top: 0;
    left: 42px;
    width: auto;
    z-index: 17;
    display: flex;
    align-items: center;
    height: 96px;
    box-shadow: none;
    padding-left: 0;

    border: none;
  }
`;



  const FilterBar = styled.ul`
  flex: 1 0 100vw;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  position: relative;
  top: -2px;

  @media(min-width: 768px){
    flex: 1 0 auto;
    top: initial;
    padding: 0 24px 0 0;
  }
`;

  const FilterItem = styled.div`
  // width: 48px;
  // height: 48px;

  // margin-right: 15px;
  // overflow: hidden;
  // &:last-of-type{
  //   margin-right: 0;
  // }

  // &.active{

  // }
  // a {
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  // }
  // @media(min-width: 768px){
  //   height: 60px;
  //   width: 60px;
  // }
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

  const bigStyle1 =`
width: 48px;
height: 48px;

margin-right: 15px;
overflow: hidden;
&:last-of-type{
  margin-right: 0;
}

&.active{

}
a {
  display: block;
  width: 100%;
  height: 100%;
}
@media(min-width: 768px){
  height: 60px;
  width: 60px;
}
`

  const disabledStyle =`
width: 48px;
height: 48px;
background: green;

margin-right: 15px;
overflow: hidden;
&:last-of-type{
  margin-right: 0;
}

&.active{

}
a {
  display: block;
  width: 100%;
  height: 100%;
}
@media(min-width: 768px){
  height: 60px;
  width: 60px;
}
`

  const BackButton = styled(GatsbyLink)`
    display: block;
    width: 50px;
    height: 50px;

    margin-right: 15px;
    // overflow: hidden;
    &:last-of-type{
      margin-right: 0;
    }
`;

  const FilterBarCurrentLabel = styled.div`
flex: 1 0 100vw;
width: 100vw;
height: 24px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
border: solid 2px  ${({ theme }) => theme.mi_white};
background-color:  ${({ theme }) => theme.mi_white};

font-family: SofiaW01;
font-size: 12px;
font-weight: bold;
line-height: 1.88;
letter-spacing: 0.5px;
margin-bottom: auto;
padding-left: 30px;
display: flex;
align-items: center;
@media(min-width: 768px){
  display: none;
}

`
  // from https://github.com/emotion-js/emotion/issues/1196
  // and from https://codesandbox.io/s/emotion-z8jpe?file=/index.js:264-308
  const styles = `
  width: 48px;
  height: 48px;

  margin-right: 15px;
  overflow: hidden;
  &:last-of-type{
    margin-right: 0;
  }

  &.active{

  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media(min-width: 768px){
    height: 60px;
    width: 60px;
  }
  position: relative;
  `;
  // const disabled = false; // not actually necessary

  // const handleBackButtonClick = e => {
  //   console.log('####handle back button click')
  //   // setIsMapView(true);
  //   // setIsCategoryView(true);
  //   setInitializedSlider(false)

  // }
  function getSvgTransform(isSearchV, isP) {
    if(!isP){
      return isSearchV ? 'translate3d(-6px, 4px, 0) rotate3d(0,0,1, 90deg)' : 'translate3d(0, 0, 0)'
    }
    // scale3d(1.8, 1.8, 1.8) translate3d(-3px, 6px, 0px) rotate3d(0, 0, 1, 90deg)
    return isSearchV ? 'scale3d(1.8,1.8,1.8) translate3d(-3px, 6px, 0px) rotate3d(0,0,1, 90deg)' : 'scale3d(0.8,0.8,0.8) translate3d(0, 0, 0)'
  }

  function onClickSearchIconOrCross(e){
    console.log('onClickSearchIconOrCross, e: ', e)
    e.preventDefault();
    setIsSearchInitialResultsResults(true)

    if(isSearchView){
      console.log('LEAVE LEAVE StyledLink onclick isSearchView true');
      // clearSearch()
      const allParticipantIds = participants.map(p => p.id)
      onLeave(allParticipantIds)
      navigate('/erkunden');
    } else {
      console.log('onClickSearchIconOrCross != searchView')
      // setIsSearchView(true)
      // searchClearSuggestions();
      // searchUpdateInputValue('')
      // clearSearch()
      onEnter()
      navigate('/suchen');
    }
  }

  return (
    <div
      style={
        {
          // width: '100vw',
          // height: '100%',
          // zIndex: '8',
          // left: '0',
          // overflowY: 'scroll',
          // position: 'absolute',
          // pointerEvents: 'none'
          pointerEvents: isSearchView ? 'all' : 'none',
        }
      }
      css={css`
      width: 100vw;
      height: 60px;
      height: 88px;
      // border-top: solid 2px #fff;
      // border-bottom: solid 2px #fff;

      // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      flex: 1 0 100vw;
      order: 2;
      z-index: 8;
      flex-wrap: wrap;
      position: relative;


      position: fixed;
      z-index: 10000;
      left: 0;
      top: 0;
      max-width: 100vw;

      pointer-events: ${isSearchView ? 'all' : 'none'}
      @media(min-width: 768px){
        background-color: #f2ebe7;
        position: relative;

        order: 3;
    
        /* display: none !important; */
        /*width: 50vw;
        height: calc(100vh - 95px);
        height: calc(var(--vh, 1vh) * 100 - 95px);*/
        position: fixed;
        top: 0;
        left: 42px;
        width: auto;
        z-index: 17;
        display: flex;
        align-items: center;
        height: 96px;
        box-shadow: none;
        padding-left: 0;
    
        border: none;
        border-radius: 50%;

      }
      `}
    >
      {/* <FilterBarCurrentLabel>Suche</FilterBarCurrentLabel> */}


      <CSSTransition
        in={searchInProp}
        timeout={200}
        classNames="seba"
        unmountOnExit
        key="sseba"
      >
        <div className="seba">

          <SearchWrapper 
            style={animateWidth}
            css={css`
              pointer-events: ${isSearchView ? 'all' : 'none'}
              // display: flex;
              // justify-content: ${isSearchView ? 'flex-end' : 'flex-start'}


          `}
          >
            {/* <SearchIcon /> */}
            {/* <button type="button" onClick={() => setToggle(!toggle)}>Toggle</button> */}
            <Tooltip
              key="tt-search"
              title="Suchen"
              position="bottom"
              trigger="mouseenter"
              tag="div"
              disabled={isSearchView}
              style={{
                width: 50,
                height: 50
              }}
              distance="-35"
              offset="-25"
            >
              <SearchIconOpenClose
              
                style={animatePos}
                css={css`
              display: block;
              z-index: 7;
              // position: relative;
              // background: blue;

                // transform: ${isPhone ? `translate3d(${innerWidth-100},0,0)` : `translate3d(${innerWidth-100},0,0)`}

              `}
              > 
                <StyledLink
                  onClick={onClickSearchIconOrCross}
                  // to={isSearchView ? '/erkunden' : '/suchen'}
                  style={
                    {
                      pointerEvents: 'all',
                      backgroundColor: isSearchView || isPhone ? 'transparent' : '#ffffff',
                    }
                  }
                >
                  {/* {isSearchResultsView ? (<div>{resultCount}</div>) : null} */}
                  <SVGWrap
                    style={
                      {
                        transform: getSvgTransform(isSearchView, isPhone)
                      }
                    }
                  >
                    <SVGCircle className="svgcircle" id="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path 
                        id="middleBarCircle" 
                        d="M5.42822 12.6839L12.3696 5.53395C15.4083 2.73356 9.08601 1.00046 9.08601 1.00046C4.66774 1.00046 1 4.58173 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9C17 4.64961 13.5275 1.11027 9.20306 1.00253" 
                        style={
                          {
                            stroke: isPhone && toggle ? '#B5A49A' : isPhone ? 'black' : '#B5A49A',
                            strokeWidth: isPhone && toggle ? 1 : isPhone? 3 : 2,
                            transition: '0.8s',
                            strokeLinecap: 'round',
                            strokeDasharray: '49 60',
                            strokeDashoffset: isSearchView ? 39 : -18,
                          }
                        }
                      />
                    </SVGCircle>
                    <SVGLine 
                      className="svgline" 
                      width="10" 
                      height="10" 
                      viewBox="0 0 10 10" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                      style={
                        {
                          transform: isSearchView ? 'translate3d(4px, -14px, 0)' : 'translate3d(13px, -3px, 0)',
                        }
                      }
                    >
                      <path d="M1.51446 1.48815L8.48555 8.51185" stroke={`${isPhone && toggle ? '#B5A49A' : isPhone ? 'black' : '#B5A49A'}`} strokeWidth={`${isPhone && toggle ? 1 : isPhone ? 3 : 2}`} strokeLinecap="round" />
                    </SVGLine>
                  </SVGWrap>
                </StyledLink>
              </SearchIconOpenClose>
            </Tooltip>
            <AutoThingy 
              aniStyle={animateAuto}
              idx={lunrIndex}
            />

            {/* <SearchForm>
                <SearchInput
                  placeholder="Sök"
                  type="text"
                  value={searchInput}
                  onChange={event => setSearchInput(event.target.value)}
                  style={animateOpacity}
                />
              </SearchForm> */}
          </SearchWrapper>

          {/* <FilterBar>
              {data.map((cat) => {

                const filterCat = !isStateInitialized ? initialCategoryId : filter_category_id; // ||

                // const isActive = filterCat === cat.databaseId;
                const isActive = !filterCat;
                // return (<div key="adfsjklasdjfö">dongs</div>)
                return (
                  <ClassNames key="cn-all">  
                    {({ css }) => {
                      return (
                        <Tooltip
                          key="tt-all"
                          title="Suche"
                          position="bottom"
                          trigger="mouseenter"
                          tag="li"
                          className={css(styles)} 
                        >
                          <FilterItem 
                            className={isActive? 'active' : ''} 
                            key={cat.archivePath}
                              
                          >
                            <Link
                              key={`link_${cat.archivePath}`}
                              onClick={() => triggerAction(null)}
                              to={cat.archivePath}
                            >
                            </Link>
                          </FilterItem>
                        </Tooltip>
                      )
                    }}
                  </ClassNames>
                )

                return false
              })}
            </FilterBar> */}
        </div>
      </CSSTransition>

      {/* <ReactTooltip place="bottom" effect="solid" className="mi-tooltip" /> */}
    </div>
  )
}

const mapStateToProps = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    isSearchView: state.search.isSearchView,
    lunrIndex: state.search.index,
    isSearchResultsView: state.search.isSearchResultsView,
    resultCount: state.participants.filteredItems.length,
    participants: state.participants.items,
    isPhone: state.responsive.isPhone,
    innerWidth: state.responsive.innerWidth,
  }
};


// export default FilterCategories
const mapDispatchToProps = function mapDispatchToProps(dispatch){
  return {
    setIsSearchView,
    searchUpdateInputValue, 
    searchClearSuggestions,
    setIsSearchInitialResultsResults,
    setFilteredParticipants,
    onLeave(participantIds) {
      console.log('onLeave called onLeave participantIds: ', participantIds)
      dispatch(searchUpdateInputValue(''))
      dispatch(searchClearSuggestions())
      // dispatch(searchUpdateInputValue(''));
      dispatch(setIsSearchResultsView(false))
      dispatch(setFilteredParticipants(
        participantIds
      ))
    },
    onEnter () {
      dispatch(setIsSearchInitialResultsResults(true));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterCategories);