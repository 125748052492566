const defaults = {
  active_category : {
    id: null,
    name: ''
  }
}

function map(state = defaults, action ) {
  switch(action.type){
  case 'RESET_ACTIVE_CATEGORY':
    // console.log('RESET_ACTIVE_CATEGORY ACTION: ', action)
    return { 
      ...state,
      active_category: { 
        id: action.participant_category_id, 
        name: action.participant_category_name,
        slug: action.participant_category_slug
      }
    }

  case 'SET_ACTIVE_PARTICIPANT':
    return { 
      ...state,
      active_participant_id: action.active_participant_id, 
    }

  case 'SET_SLIDE_INDEX':
    return { 
      ...state,
      current_slide_index: action.slide_index, 
    }

  case 'SET_INIATLIZED_SLIDER':
    console.log('SET_INIATLIZED_SLIDER')
    return { 
      ...state,
      slider_initialized: action.slider_initialized, 
    }
    
    

  case 'SET_SINGLE_PARTICIPANT_DATA':
    console.log('reducer SET_SINGLE_PARTICIPANT_DATA')
    console.log('action.participant_data.id', action.participant_data.id)
    return { 
      ...state,
      items: state.items.map((item, index) => {
        console.log('   item.id: ', item.id)
        if (item.id !== action.participant_data.id) {
          // This isn't the item we care about - keep it as-is
          return item
        } 
        return {
          ...item,
          data: action.participant_data,
          fetched: true
        }
        


      })
      
      
      // [...state.items, {...state.items.find(p => p.id === action.participant_data.id), data: action.participant_data} ]
      // participantData: action.participant_data, 
    }

  case 'SET_CURRENT_PARTICIPANTS':
    return { 
      ...state,
      items: action.participants, 
    }
  
  case 'SET_FILTERED_PARTICIPANTS':
    console.log('REDUCER SET_FILTERED_PARTICIPANTS', action);
    return { 
      ...state,
      filteredItems: action.participantsIds, 
    }
    

  case 'ACTIVATE_PARTICIPANT':
    return { 
      ...state,
      activated_participant_id: action.activated_participant_id, 
    }

  case 'OPEN_PARTICIPANT':
    return { 
      ...state,
      opened_participant_id: action.opened_participant_id, 
    }

  case 'SET_ACTIVATED_PARTICIPANT_OFFSET_X':
    return { 
      ...state,
      activated_participant_offset_x: action.activated_participant_offset_x, 
    }

  case 'INITIALIZE_PARTICIPANT_CATEGORY':
    // console.log('INITIALIZE_PARTICIPANT_CATEGORY ACTION: ', action)
    return { 
      ...state,
      active_category: { 
        id: action.participant_category_id, 
        name: action.participant_category_name  
      }
    }
  default:
    // console.log('REDUCER default map_count')
    return state;
  }
}

export default map;
