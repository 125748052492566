import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import mapStyles from './gmaps-styles-moabiterinsel.json';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
`;

const mapOptions = {
  styles: mapStyles, // straight out of something like snazzymaps
  controlSize: 30,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "greedy",
  draggable: true,
  minZoom: 14,
  maxZoom: 18

};


// const handleOnChange = (bounds, places) => {
//   if (bounds.nw.lat >= 45 || bounds.se.lat <= -85) {
//       fitBounds(myMap);
//   }
// };

const GoogleMap = ({ children, ...props }) => {
  const { center, places } = props;


  const [myMap, setMyMap] = useState(null);
  // const [myPlaces, setMyPlaces] = useState(null);
  
  // const fitBounds = (map) => {
  //    const bounds = new window.google.maps.LatLngBounds();
  //    myPlaces.map(place => {
  //      bounds.extend(place.pos);
  //      return place.id;
  //    });
  //    map.fitBounds(bounds);          
  //  };
  
  const handleApiLoaded = (map, maps) => {
    setMyMap(map)
    // fitBounds(map);
  };

  useEffect(() => {
    if (myMap && places.length) {
      const bounds = new window.google.maps.LatLngBounds();
      places.map(place => {
        const placePos = new window.google.maps.LatLng(place.lat, place.lng);
        bounds.extend(placePos);
        return place.id;
      });
      myMap.fitBounds(bounds)
    }
  }, [places])

  return (
    <Wrapper>
      <GoogleMapReact
        // onChange={({ bounds }) => handleOnChange(bounds, places)}    
        center={center}
        options={mapOptions}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
        }}
        {...props}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  )};

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
