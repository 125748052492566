import React from 'react';
import styled from '@emotion/styled'
import { navigate } from "@reach/router"
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import gastronomy from "../../images/icons/map-pin-gastronomie.svg"
import business from "../../images/icons/map-pin-gewerbe.svg"
import arts from "../../images/icons/map-pin-kunsthandwerk.svg"
import social from "../../images/icons/map-pin-soziales-kulutr.svg"
import { setIsMapView, 
  setIsCategoryView, 
  setActiveParticipant, 
  activateParticipant, 
  setActivatedParticipantOffsetX,
} from '../../state/actions'
import placesFromProps from "../CardBox/helpers"
import FluidImage from "../FluidImage"
import GoogleMap from "./GoogleMap";

let LOS_ANGELES_CENTER = [34.0522, -118.2437]

const StyledMarker = styled.div`
  width: 37px;
  height: 37px;
  position: relative;
  // position: absolute;

  &.active{
    // transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    // transition-property: transform, box-shadow;
    // transition-duration: 150ms;
    &.arts{
      filter: drop-shadow(0 0 5px rgba(0, 131, 200, 0.5));
    }
    &.social{
      filter: drop-shadow(0 0 5px rgba(87, 193, 139, 0.5));
    }
    &.business{
      filter: drop-shadow(0 0 5px rgba(254, 193, 16, 0.5));
    }
    &.gastronomy{
      filter: drop-shadow(0 0 5px rgba(234, 82, 35,0.5));
    }
    transform: scale3d(1.9, 1.9, 1.9);
    &:hover{
      transform: scale3d(1.9, 1.9, 1.9);
      // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
  }
  @media(min-width: 768px){
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: transform, box-shadow;
    transition-duration: 200ms;
    will-change: transform;
    transition-delay: 0;
    &:hover{
      transform: scale3d(1.6,1.6,1.6);
      // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
  }

`
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledPopUpPhoto = styled.div`
width: 140px;
height: 80px;
flex: 1 0 100%;
opacity: 0;
pointer-events: none;
@media(min-width: 768px){
  // opacity: 1;
  // pointer-events: all;
}
`

const StyledPopUpTitle = styled.div`
width: 140px;
height: 60px;
font-family: SofiaW01;
font-size: 12px;
line-height: 1.4;
text-align: center;
color: var(--black);
display: flex;
align-items: center;
justify-content: center;
align-content: center;
opacity: 0;
pointer-events: none;
@media(min-width: 768px){
  // opacity: 1;
  // pointer-events: none;
}
`


const StyledPopUp = styled.div`
text-decoration: none;
opacity: 0;
pointer-events: none;
@media(min-width: 768px){
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: transform, opacity;
  transition-duration: 250ms;
  transition-delay: 50ms;
  pointer-events: none;
  opacity: 0;
  display: flex;
  transform: translate3d(0,30px,0) scale3d(0.8,0.8,0.8);
  width: 140px;
  height: 140px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;

  position: relative;
  bottom: 200px;
  left: -50px;
  flex-wrap: wrap;
  z-index: 13;
  &.active{
    transform: translate3d(0,0px,0) scale3d(1,1,1);
    opacity: 1;
    pointer-events: all;
    ${StyledPopUpTitle} {
      opacity: 1 !important;
      pointer-events: all;
    }
    ${StyledPopUpPhoto} {
      opacity: 1 !important;
      pointer-events: all;
    }
  }
}
`

const PopUp = (props) => {
  const { place, className } = props;
  const { title, id, shortTitle, heroImage, uri } = place;
  const displayTitle = shortTitle && shortTitle !== '' ? shortTitle : title;
  return (
    <StyledPopUp key={`info_window_${id}`} className={className}>
      <StyledPopUpPhoto>
        {heroImage && heroImage[0] ? 
          <FluidImage className="mi-card-image" image={heroImage[0]} style={{marginBottom: '0px'}} /> : null }
      </StyledPopUpPhoto>
      <StyledPopUpTitle dangerouslySetInnerHTML={{ __html: `${displayTitle}`}} />
    </StyledPopUp>
  )
}

const Marker = ({ show, place, activated_participant_id, ...props }) => {
  const { 
    setIsMapView,
    setIsCategoryView,
    setActiveParticipant,
    activateParticipant,
    setActivatedParticipantOffsetX,
    lastCategoryRoute,
    isMapView,
    isCategoryView

  } = props;

  const participantId = place.id;
  const id = place.categoryId
  const isActive = activated_participant_id === place.id

  const handleLinkClick = participantId => (e, target) => {
    const isSingleView =  isMapView && !isCategoryView;
    if (typeof window !== 'undefined') {
      if (e) {
        e.preventDefault()
        if(isSingleView){
          navigate(lastCategoryRoute || '/erkunden/')
          setTimeout(() => {
            setActiveParticipant(participantId)
            activateParticipant(participantId)

          }, 1000)
        } else {
          setActiveParticipant(participantId)
          activateParticipant(participantId)
          navigate(lastCategoryRoute || '/erkunden/')
        }

      } else {
        console.log('MARKO NO e: ')
      }
    }
  }


  function Mark({ place, activated_participant_id, participantId, categoryId, markerIsActive }) {
    switch (categoryId) {
    case 9:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active arts' : ''}>
            <StyledImage src={arts} alt="" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 7:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active social' : ''}>
            <StyledImage src={social} alt="" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 5:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active business' : ''}>
            <StyledImage src={business} alt="" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 8:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active gastronomy' : ''}>
            <StyledImage src={gastronomy} alt="" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    default:
      return null;
    }
  }

  return ( Mark({place, activated_participant_id, participantId, categoryId: id, markerIsActive: isActive}));
};

// const placesFromProps = (props) => {
//   if(props.places){
//     const  transformedPlaces = props.places.map(p => {
//       const { latitude, longitude, streetAddress } = p.mi_participant.miParticipantGeodata;
//       const categoryId = p.participantCategories.nodes[0].databaseId;

//       const shortTitle = p.mi_participant.miParticipantShorttitle
//       const heroImage =p.mi_participant.miParticipantHeroSlider

//       return {
//         title: p.title,
//         shortTitle,
//         heroImage,
//         formatted_address : streetAddress,
//         id: p.databaseId,
//         lat : latitude,
//         lng : longitude,
//         show: false,
//         categoryId,
//         uri: p.uri,
//       }
//     })
//     return transformedPlaces;
//   }
//   return []

// }


/**
 * Get a center latitude,longitude from an array of like geopoints
 *
 * @param array data 2 dimensional array of latitudes and longitudes
 * For Example:
 * $data = array
 * (
 *   0 = > array(45.849382, 76.322333),
 *   1 = > array(45.843543, 75.324143),
 *   2 = > array(45.765744, 76.543223),
 *   3 = > array(45.784234, 74.542335)
 * );
*/
function GetCenterFromDegrees(data)
{
  // console.log('GetCenterFromDegrees ', data)
  const numCoords = data.length;
  let lat;
  let lon;
  
  if (!(data.length > 0)){
    return false;
  } 


  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  for(let i = 0; i < data.length; i += 1){
    lat = data[i][0] * Math.PI / 180;
    lon = data[i][1] * Math.PI / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= numCoords;
  Y /= numCoords;
  Z /= numCoords;

  lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  lat = Math.atan2(Z, hyp);

  const newX = (lat * 180 / Math.PI);
  const newY = (lon * 180 / Math.PI);

  return [newX, newY];
}

const mapStateToPropsMarker = state => {
  return {
    filter_category_id: state.participants.active_category.id,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id,
    lastCategoryRoute: state.route.href,
    filteredParticipants: state.participants.filteredItems,
  }
};

const mapDispatchToPropsMarker = {
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant,
  activateParticipant,
  setActivatedParticipantOffsetX,
};

const ConnectedMarker = connect(mapStateToPropsMarker, mapDispatchToPropsMarker)(Marker)

const MoabitMap = ( props ) => {
  const places = placesFromProps(props)
  const placesAsPairs = places.map(p => [p.lat, p.lng])
  LOS_ANGELES_CENTER = GetCenterFromDegrees(placesAsPairs) || [52.5292237, 13.330749]

  const { activated_participant_id, filter_category_id, initialCategoryId, filteredParticipants } = props
  const centerPair = places.find(p => p.id === activated_participant_id)
  let centerLoc = centerPair? [centerPair.lat, centerPair.lng] : LOS_ANGELES_CENTER
  const filteredPlaces = filter_category_id? places.filter(p => p.categoryId === filter_category_id) : places;
  const filteredPlacesIds = filteredPlaces.map(p => p.id)// ? places.filter(p => p.categoryId === filter_category_id) : places;

  const places3 = places
    .filter(p => {
      if(filteredParticipants && filteredParticipants.length) {
        const index = filteredParticipants.indexOf(p.id)
        console.log('rarara index: ', index)
        return  index !== -1
      }
      // return filteredPlacesIds.indexOf(p.id) !== -1
      return false
    })
    .map((place) => {
      console.log('rarara place.id: ', place.id)
      return place
    })


  return (
    <>
      <GoogleMap
        center={centerLoc}
        defaultZoom={15}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        places={places3}
      >
        {places3 && places3.length? places3.map((place) => (
          <ConnectedMarker
            markerId={place.id}
            key={place.id}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
            place={place}
            style={{zIndex: 300}}
            activated_participant_id={activated_participant_id}
          />
        )) : null }
      </GoogleMap>
    </>
  );
}

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    filter_category_id: state.participants.active_category.id,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id,

  }
};

export default connect(
  mapStateToProps,
  null
)(MoabitMap);