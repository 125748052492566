import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
import logo from "../../images/logo-moabiterinsel.svg"
import { setIsMapView, setIsCategoryView } from '../../state/actions'

const FooterMenu = ( props ) => {
  const { 
    data,
    setIsMapView,
    setIsCategoryView
  } = props;
  return (
    <StyledFooterWrapper>
      <StyledFooter>
        <GatsbyLink 
          className='mi_footer_home'
          key="/"
          to="/"
          title="Zur Startseite"
          onClick={() => {
            setIsCategoryView(false)
            setIsMapView(false)}}
        />
        {data.map((item) => (
          <GatsbyLink 
            className="mi_footer_link"
            key={item.url}
            to={item.path}
            onClick={() => {
              setIsCategoryView(false)
              setIsMapView(false)}}
          >
            {item.label}
          </GatsbyLink>
        ))}
      </StyledFooter>
    </StyledFooterWrapper>
  )}

const StyledFooterWrapper = styled.footer`
  display: flex;
  justify-conten: center;
  background-color:  ${({ theme }) => theme.mi_white};
  position: relative;
  z-index: 5;
  `

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 53px 48px;
  column-gap: 7.9px;
  row-gap: 11px;
  padding: 36px 32px 10px 32px;
  padding: 36px 0 20px 0;

  width: 100%;
  .mi_footer_home {
    width: 100%;
    height: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgNDUgNDYiIHdpZHRoPSI0NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0yMi45ODMgMzAuODIgMTAuNTE4LTI3LjAxNnYyMi44ODhoLTMuNjE0djMuODA0aDExLjUxM3YtMy44MDRoLTMuNjk2di0yMi44ODhoMy42OTZ2LTMuODA0aC0xMC41OTlsLTguMTAyIDIwLjg4NC04LjA2MS0yMC44ODRoLTEwLjQ5OHYzLjgwNGgzLjcxNnYyMi44ODhoLTMuNzE2djMuODA0aDEwLjg2M3YtMy44MDRoLTMuMzd2LTIyLjg4OGwxMC40MzYgMjcuMDE2eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0ibTE3LjE2MiAzNy4zNjUtMTcuMTYyIDguNDA1di0yNi4zMWwxNy4xNjItOC40MDUgMTAuMjk2IDQuMzg1IDE3LjE2Mi04Ljc3djI2LjU4N2wtMTcuMTYyIDguNDkzeiIgZmlsbD0iI2VhNTIyMyIvPjxwYXRoIGQ9Im0xNy4xNjIgMzcuMzY1di0yNi4zMWwxMC4yOTYgNC4zODV2MjYuMzF6IiBmaWxsPSIjZDE0YTIwIi8+PHBhdGggZD0ibTE3LjE2MiAzNy4zNjV2LTI2LjMxbDEwLjI5NiA0LjM4NXYyNi4zMXoiIGZpbGw9IiNkMTRhMjAiLz48cGF0aCBkPSJtMjIuOTgzIDMwLjgyIDEwLjUxOC0yNy4wMTZ2MjIuODg4aC0zLjYxNHYzLjgwNGgxMS41MTN2LTMuODA0aC0zLjY5NnYtMjIuODg4aDMuNjk2di0zLjgwNGgtMTAuNTk5bC04LjEwMiAyMC44ODQtOC4wNjEtMjAuODg0aC0xMC40OTh2My44MDRoMy43MTYgMy43NzdsMTAuNDM2IDI3LjAxNnoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=);
    grid-column: 6 / span 2;
    background-repeat: no-repeat;
    background-position: center top;
    align-self: start;
    justify-self: center;
  }
  
  .mi_footer_link {
    display: flex;
    font-family: SofiaW01;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.mi_black};
    padding: 10px 5px;
    align-self: center;
    text-decoration: none;
    &:nth-of-type(2){
      grid-column: 2 / span 3;
      justify-self: start;
    }
    &:nth-of-type(3){
      grid-column: 6 / span 3;
      justify-self: end;
    }
    &:nth-of-type(4){
      grid-column: 10 / span 2;
      justify-self: end;
    }
    @media(max-width: 320px) {
      font-size: 14px;
    }
    @media(max-width: 280px) {
      &:nth-of-type(2){
        grid-column: 1 / span 3;
      }
      &:nth-of-type(4){
        grid-column: 11 / span 2;
      }
    }

  }

  @media(min-width: 476px){
    .mi_footer_link {
      &:nth-of-type(3){
        grid-column: 5 / span 3;
        justify-self: end;
      }
    }
  }
  @media(min-width: 768px) {
    display: flex;
    max-width: 1140px;
    margin: 0 auto;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
    .mi_footer_home {
      margin-right: auto;
      width: 87px;
      height: 88px;
      background-position: center;
      // padding: 21px 21px 21px 0;
      padding: 0;
      width: 45px;
    }
    .mi_footer_link{
      margin-left: -8px;
      margin-right: 26px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  @media(min-width: 1140px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Footer = (props) => {
  const {
    setIsMapView,
    setIsCategoryView
  } = props;
  return (
    <StaticQuery
      query={graphql`
      query {
        wpMenu(name: { eq: "Footer Navigation" }) {
          menuItems {
            nodes {
              url
              label
              target
              path
            }
          }
        }
        }
    `}
      render={(data) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
        return <FooterMenu setIsCategoryView={setIsCategoryView} setIsMapView={setIsMapView} data={data.wpMenu? data.wpMenu.menuItems.nodes : []} {...props} />
      }}
    />
  )
}
const mapStateToProps = state => {
  // console.log('mapStateToProps footer: ', state)
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
