import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled'
import FocusLock from 'react-focus-lock';
// import { useOnClickOutside } from '../../../layouts/hooks';
import Burger from '../burger'
import HamburgerMenu from '../menu'

const BurgerAll = ( props ) => {
  // const { ref, open, setOpen } = props;
  const menuId = "main-menu";
  const [open, setOpen] = useState(false);
  const node = useRef();

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
  
      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    [ref, handler],
    );
  };

  useOnClickOutside(node, () => setOpen(false));
  return (
    <StyledBurgerAll>
      <StyledMenuBackDrop open={open} />
      <StyledMenu open={open} ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <HamburgerMenu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </StyledMenu>
    </StyledBurgerAll>
  )
}

const StyledMenuBackDrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background-color: ${({ theme }) => theme.mi_black };
  transition-timing-function: ${({ open }) => open ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ open }) => open ? '250ms' : '200ms'};
  opacity: ${({ open }) => open ? 0.25 : 0};
  transition-property: opacity;
  pointer-events: ${({ open }) => open ? 'all' : 'none'};
`

const StyledMenu = styled.div`
  position: fixed;
  z-index: 16;
  pointer-events: ${({ open }) => open ? 'all' : 'none'};
`

const StyledBurgerAll = styled.div``

export default BurgerAll;
