import React from "react"
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"


const StyledImgWrap = styled.div`

`
const StyledImg = styled(GatsbyImage)`
  display: block;
  margin: 0 !important;
`

const Caption = styled.div`
width: 100%
font-family: SofiaW01;
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
text-align: right;


p{
  font-family: SofiaW01;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-right: 0;
  margin-right: 0.4em;
  margin-top: 0.4em;
}
`


function convertString(input){
  return input.split('<a').join('<a target="_blank" rel="noopener noreferrer" ')
}


const FluidImage = ({ image, withFallback = true, ...props }) => {
  const data = useStaticQuery(graphql`
      query {
          fallbackImage: file(relativePath: { eq: "fallback.svg" }) {
              publicURL
          }
      }
  `)
  const { cap } = props;
  /**
   * Return fallback Image, if no Image is given.
   */
  if (!image) {
    return withFallback ? <img src={data.fallBackImage.publicURL} alt="Fallback" {...props} /> : null
  }

  if (image && image.imageFile) {

    return (
      <StyledImgWrap>
        <StyledImg fluid={image.imageFile.childImageSharp.fluid} alt={image.altText} {...props} />
        <Caption dangerouslySetInnerHTML={{ __html: cap && cap !== '' ? convertString(cap) : image.caption }} />
      </StyledImgWrap>
    )
  }

  return <img src={image.sourceUrl} alt={image.altText} {...props} />
}

export default FluidImage