import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled'

const HeaderBackDrop = (props) => {
  const { pageLayout, children } = props;

  // determined if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = useState(false);

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });
  
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <StyledHeaderBackDrop 
      data-active={scrolled}
      id="mi-main-header"
      className={`mi-header-bd-${pageLayout}`}
    >

      {children}
    </StyledHeaderBackDrop>
  )
}

const StyledHeaderBackDrop = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    // background-color: ${({ theme }) => theme.mi_background_real};

    &[data-active='true'] {
      // background-color: ${({ theme }) => theme.mi_background_real};
      transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
      transition-duration: 100ms;
      background-color: ${({ theme }) => theme.mi_background_real};
     
      &.mi-header-bd-home{
        background: #9ac9df;
      }
    }



    display: flex;
    justify-content: space-between;
    transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
    transition-property: background-color;

    transition-duration: 250ms;
    @media(min-width: 769px){
      background-color: transparent;
      height: 96px;
     
      &.mi-header-bd-home{
        // background: #9ac9df;
        background: #9ac9df;
      }
      &[data-active='true'] {
        // background-color: ${({ theme }) => theme.mi_background_real};
        transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
        transition-duration: 200ms;
        // &.mi-header-bd-home{
        //   // background: #9ac9df;
        //   background: #9ac9df;
        //   // background-color: ${({ theme }) => theme.mi_background_real};
        // }
      }
    }
    `

export default HeaderBackDrop;
