import React, { useEffect, useState, createRef, useCallback } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
// import styled from '@emotion/styled'

import { connect } from 'react-redux';
import { ScrollToHOC, ScrollArea } from "react-scroll-to";
import smoothscroll from 'smoothscroll-polyfill';

import { setIsMapView, 
  setIsCategoryView, 
  setActiveParticipant, 
  activateParticipant, 
  openParticipant, 
  setActivatedParticipantOffsetX,
  resetActiveCategory,
  setInitalizedToTrue,
  setFilteredParticipants,
  // setIsSearchResultsView
} from '../../state/actions'

import FluidImage from "../FluidImage"
import ArtsAndCraftsCardIcon from './CardIcons/ArtsAndCrafts'
import BusinessCardIcon from './CardIcons/Business'
import GastronomyIcon from './CardIcons/Gastronomy'
import SocialCardIcon from './CardIcons/Social'
import placesFromProps from "./helpers"

import {
  StyledMobileCardOuter,
  StyledMobileCardInner,
  StyledMobileCardPhoto,
  StyledMobileCardContent,
  StyledMobileCardIcon,
  StyledMobileCardText,
} from "./styledCards";

const DebugOverlay = styled.div`
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: yellow;
`

const Card = ( props ) => {
  const { 
    setActiveParticipant,
    activateParticipant,
    setActivatedParticipantOffsetX,
    refs,
    place,
    active_participant_id,
    apID
  } = props;

  const { slug, uri, title, id, category, shortTitle, heroImage } = place;
  const displayTitle = shortTitle && shortTitle !== '' ? shortTitle : title;
  const catId = category.id;

  const handleLinkClick = (e, target) => {
    console.log('handleLinkClick')
    if (typeof window !== 'undefined') {
      if (e) {
        setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
        setActiveParticipant(id)
        activateParticipant(id)
      }
    }
  }

  const [isActive, setIsActive] = useState(apID === id)
  useEffect(()=>{
    smoothscroll.polyfill();
    if(active_participant_id && id){
      if(active_participant_id === id){
        setIsActive(true)
      } else if(isActive) {
        setIsActive(false);
      }
    }
  }, [active_participant_id, id]);

  const FancyStyledMobileCardOuter = React.forwardRef((props, ref) => {
    return (
      <StyledMobileCardOuter 
        ref={ref} 
        className={isActive ? `mi-card mi-card-${catId} mi-card-active` : `mi-card mi-card-${catId}`}
        key={slug}
        to={uri}
        title={title}
        onClick={handleLinkClick}
        id={`mi_card_mobile_${id}`}
      >
        {props.children}
      </StyledMobileCardOuter>
    )}
  );

  return (
    <FancyStyledMobileCardOuter ref={refs[place.id]}>
      <StyledMobileCardInner>
        <StyledMobileCardPhoto>
          {heroImage && heroImage[0] ? 
            <FluidImage className="mi-card-image" image={heroImage[0]} style={{marginBottom: '0px'}} /> : null }
        </StyledMobileCardPhoto>
        <StyledMobileCardContent>
          <StyledMobileCardIcon>
            {catId === 9 ? <ArtsAndCraftsCardIcon active={isActive}  /> : null }
            {catId === 7 ? <SocialCardIcon active={isActive}  /> : null }
            {catId === 5 ? <BusinessCardIcon active={isActive}  /> : null }
            {catId === 8 ? <GastronomyIcon active={isActive}  /> : null } 
          </StyledMobileCardIcon>
          <StyledMobileCardText dangerouslySetInnerHTML={{ __html: `${displayTitle}`  }} />
        </StyledMobileCardContent>
      </StyledMobileCardInner>
    </FancyStyledMobileCardOuter>
  )
}

function useTimeout(callback, delay) {
  const timeoutRef = React.useRef();
  const callbackRef = React.useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setTimeout kicks in, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // timeout will be reset.

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the timeout:

  React.useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);

      // Clear timeout if the components is unmounted or the delay changes:
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  // In case you want to manually clear the timeout from the consuming component...:
  return timeoutRef;
}


const Papa = ScrollToHOC(function(props) {
  // console.log('papa children: ', children); 
  // console.log('papa props: ', props);
  const { children, scroll, active_participant_id, apID, activated_participant_offset_x, isPhone, refs, isStateInitialized, setInitalizedToTrue } = props;

  const [isLoading, setLoading] = useState(true);
  const [isLoadingX, setLoadingX] = useState(true);
  const [showLoaderX, setShowLoaderX] = useState(false);
  
  
  const [showLoader, setShowLoader] = useState(false);


  const [scrollPosY, setScrollPosY] = useState(0);
  const [scrollPosX, setScrollPosX] = useState(24);

  useEffect(() => {
    console.log('happa useEffect isStateInitialized: ', isStateInitialized)
    console.log('happa refs: ', refs);
    
    // if (!isStateInitialized) {
    //   console.log('!isStateInitialized setInitalizedToTrue();setInitalizedToTrue();setInitalizedToTrue();')
    //   // setInitalizedToTrue();
    // }
    if (active_participant_id && !isPhone) {
      console.log('happa useEffect active_participant_id && !isPhone')
      console.log("useEffect >>> active_participant_id", active_participant_id);
      const headerHeight = document.getElementById("foo").offsetTop;
      console.log('happa active_participant_id: ', active_participant_id, refs)
      console.log('happa refs[active_participant_id]', refs[active_participant_id])
      if(refs[active_participant_id]){
        console.log('happa refs[active_participant_id].current', refs[active_participant_id].current)
      }

      if(refs[active_participant_id] && refs[active_participant_id].current) {
        const elementTop = refs[active_participant_id].current.offsetTop;
        const scrollToY = elementTop - headerHeight - 44;
        console.log('happa noPhone scrollto ', active_participant_id)

        console.log('happi ', active_participant_id, ' elementTop: ', elementTop, 'scrollToY: ', scrollToY)
        setLoading(true);
        setShowLoader(false);
        setScrollPosY(scrollToY)
        window.setTimeout(() => setLoading(false), 300);
        // scroll({
        //   id: "foo",
        //   smooth: true,
        //   y: scrollToY
        // });
      }

    }
    if (activated_participant_offset_x && isPhone) {
      console.log("useEffect >>> activated_participant_offset_x", activated_participant_offset_x);
      console.log("useEffect >>> active_participant_id", active_participant_id);
      console.log("useEffect >>> refs[active_participant_id]", refs[active_participant_id]);
      const leftPos = document.getElementById("foo").offsetLeft;
      if(refs[active_participant_id] && refs[active_participant_id].current) {
        const elementLeft = refs[active_participant_id].current.offsetLeft;
        const scrollToX = elementLeft - leftPos -24;
        console.log('sssss setLoadingX: true')
        setLoadingX(true);
        console.log('sssss setShowLoaderX: false')
        setShowLoaderX(false);
        console.log('sssss setScrollPosX: ', scrollToX)
        setScrollPosX(scrollToX)

        // scroll({
        //   id: "foo",
        //   smooth: true,
        //   x: scrollToX
        // });
        window.setTimeout(() => {
          console.log('sssss setLoadingX: false')
          setLoadingX(false)}, 750);
      }
    // }
    }}, [active_participant_id, activated_participant_offset_x, isPhone, refs ]);


  // After 2 second, we want to show a loader:
  useTimeout(() => {
    
    setShowLoader(true)
    scroll({
      id: "foo",
      smooth: true,
      y: scrollPosY
    });
  }, isLoading ? 10 : null);
  
  
  useTimeout(() => {
    console.log('sssss now show loaderX: ')
    setShowLoaderX(true)
    console.log('sssss scrollt to: ', scrollPosX)
    scroll({
      id: "foo",
      smooth: true,
      x: scrollPosX
    });
  }, isLoadingX ? 400 : null);
  return (
    <ScrollArea 
      css={css`
      order: 3;
      flex: 1 0 100vw;
      display: flex; 
      flex-wrap: no-wrap;
      overflow-x: scroll;
      padding: 24px 0;  
      width: 100vw;
      height: 233px;
      background-color: #f2ebe7;
      // padding-right: 24px;
      &:before{
        flex: 0 0 32px;
        content: '';
        height: 100%;
      }
      &:after{
        flex: 0 0 12px;
        content: '';
        height: 100%;
      }

      @media(min-width: 768px){
        align-content: flex-start;
    
        padding: 0 24px;
        border-top: 2px solid #fff;
        flex: 1 0 50vw;
        order: 1;
        flex-wrap: wrap;
        overflow-y: scroll;
        width: 50vw;
        height: calc(100vh - 95px);
        height: calc(var(--vh, 1vh) * 100 - 95px);
        /* display: none !important; */
        &:before{
          flex: 0 0 100%;
          content: '';
          width: 100%;
          height: 20px;
        }
        &:after{
          width: 100%;
          height: 20px;
          flex: 0 0 100%;
          content: '';
        }
      }
    `}
      
      className="mi-card-container"
      id="foo"
    >
      {children}
    </ScrollArea>
  );
})


const ParticipantCardsMobile = ( props ) => {
  const places2 = placesFromProps(props)
  console.log('asta props.places: ', props.places)
  console.log('asta places (placesFromProps) : ', places2)
  const { 
    activated_participant_offset_x, 
    filter_category_id, 
    isPhone, 
    isStateInitialized,
    setInitalizedToTrue,
    initialCategoryId, 
    initialParticipantId, 
    setFilteredParticipants,
    filteredParticipants,
    places,
    // setIsSearchResultsView,
    isSearchResultsView

  }  = props;

  const elRefs = places2.reduce((acc, place) => {
    acc[place.id] = createRef(null);
    return acc;
  }, {});

  // const filterCat = !isStateInitialized ? initialCategoryId : filter_category_id; // || initialCategoryId;
  // console.log('filterCat?', filterCat || 'no' )
  // const filteredPlaces = filterCat ? places.filter(p => p.categoryId === filterCat) : places;

  // const filteredPlaces = filterCat? places.filter(p => p.categoryId === filterCat) : places;
  // let filteredPlacesLet = places; 

  // const filteredPlaces = places.filter(p => filteredParticipants.indexOf(p.id) !== -1)

  // useEffect(() => {
  //   console.log('momomo Filter cat? ', filterCat)
  //   console.log('momomo places? ', places)
  //   const ids = filteredPlacesLet.map(p => p.id)
  //   console.log('momomo ids? ', ids)
  //   setFilteredParticipants(ids)
  //   // filteredPlacesLet = places.filter(p => filteredParticipants.indexOf(p.id) !== -1)
  //   console.log('momomo filteredPlacesLet? ', filteredPlacesLet)
  // }, [filteredParticipants])
  
  const filterCat = !isStateInitialized ? initialCategoryId : filter_category_id; // || initialCategoryId;
  // const filteredPlacesIds = filterCat? places2.filter(p => p.categoryId === filterCat).map(p => p.id) : places2.map(p => p.id);
  const filteredPlacesIds = filterCat? places2.filter(p => p.categoryId === filterCat).map(p => p.id) : places2.map(p => p.id);
  // setFilteredParticipants(filteredPlacesIds)

  useEffect(() => {
    const filterCat2 = !isStateInitialized ? initialCategoryId : filter_category_id; // || initialCategoryId;
    // const filteredPlacesIds2 = filterCat2? places2.filter(p => p.categoryId === filterCat).map(p => p.id) : [];
    if(!isSearchResultsView){
      const filteredPlacesIds2 = filterCat2? places2.filter(p => p.categoryId === filterCat).map(p => p.id) : places2.map(p => p.id);
      setFilteredParticipants(filteredPlacesIds2)

    }
    // const filteredPlacesIds2 = filterCat2 && !isSearchResultsView? places2.filter(p => p.categoryId === filterCat).map(p => p.id) : places2.map(p => p.id);

    // const getFilteredPlaces = (filterCat, isSearchResultsView, places ) {
    //   if(isSearchResultsView){

    //   } else if(filterCat2)
    // }

    // const filteredPlacesIds2 = getFilteredPlaces(filterCat2, isSearchResultsView, places)


    console.log('asta places2: ', places2)
    console.log('asta filterCat2: ', filterCat2)
    // console.log('asta filteredPlacesIds2: ', filteredPlacesIds2)
    // console.log('asta filteredPlacesIds', filteredPlacesIds2)
    // setFilteredParticipants(filteredPlacesIds2)

    console.log('ttt filterCat: ', filterCat2);
    console.log('ttt filteredPlacesIds ', filteredPlacesIds);

  }, [filterCat, places, isSearchResultsView])

  const pId = initialParticipantId;
  // pId = 7338;
  const pOffsetX = activated_participant_offset_x;
  // pOffsetX =  0;

  console.log('rarara : filteredParticipants', filteredParticipants)
  // places2.filter(p => {
  //   console.log('rarara : p.id', p.id)
  //   console.log('rarara: filteredParticipants.indexOf(p.id) ', filteredParticipants.indexOf(p.id))
  // })
  const places3 = places2
    .filter(p => {
      if(filteredParticipants) {
        console.log('nonono filteredParticipants', filteredParticipants)
        const index = filteredParticipants.indexOf(p.id)
        console.log('rarara index: ', index)
        return  index !== -1
      }
      return filteredPlacesIds.indexOf(p.id) !== -1
    })
    .map((place) => {
      console.log('rarara place.id: ', place.id)
      return place
    })

  console.log('rarara places 3: ', places3)
  return (
    <>
      <Papa
        key="moinsn"
        active_participant_id={pId}
        apID={pId}
        activated_participant_offset_x={pOffsetX} 
        isStateInitialized={isStateInitialized}
        setInitalizedToTrue={setInitalizedToTrue}
        isPhone={isPhone}
        refs={elRefs}
      >

        {places2
          .filter(p => filteredParticipants && filteredParticipants.length ? filteredParticipants.indexOf(p.id) !== -1 : filteredPlacesIds.indexOf(p.id) !== -1)
          .map((place) => {
            return (
              <Card 
                apID={pId}
                key={place.id} 
                place={place}
                refs={elRefs}
                {...props}
              />
            )
          })}
      </Papa>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    active_participant_id: state.participants.active_participant_id,
    activated_participant_id: state.participants.activated_participant_id,
    opened_participant_id: state.participants.opened_participant_id,
    activated_participant_offset_x: state.participants.activated_participant_offset_x,
    activeCategoryId: state.participants.active_category.id,
    activeCategoryName: state.participants.active_category.name,
    filter_category_id: state.participants.active_category.id,
    activeCategorySlug: state.participants.activeCategorySlug,
    // ap_opened: state.participants.active_participant.category_opened,
    isPhone: state.responsive.isPhone,
    isStateInitialized: state.isStateInitialized,
    filteredParticipants: state.participants.filteredItems,
    isSearchResultsView: state.search.isSearchResultsView,

  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant,
  activateParticipant,
  openParticipant,
  setActivatedParticipantOffsetX,
  resetActiveCategory,
  setInitalizedToTrue,
  setFilteredParticipants,
  // setIsSearchResultsView,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  // return { ...ownProps, todos: stateProps.todos[ownProps.userId],
  //   addTodo: text => dispatchProps.addTodo(ownProps.userId, text)}
  // console.log('eee ParticipantCardsMobile mergeProps: ');
  // console.log('eee stateProps: ', stateProps);
  // console.log('eee ownProps: ', ownProps);
  const mergedProps = { ...ownProps, ...stateProps, ...dispatchProps}
  // console.log('eee mergedProps: ', mergedProps);
  return mergedProps;
}



export default connect(mapStateToProps, 
  mapDispatchToProps,
  mergeProps,
  { forwardRef: true }, // options
)(ParticipantCardsMobile)
