const defaults = {
  opt_in : {
    confirmed: false, // user confirmed map usage
    persisted : false // confirmation is saved as cookie
  },
}

function map(state = defaults, action ) {
  switch(action.type){
  case 'OPT_IN_GOOGLE_MAPS':
    return { 
      ...state, 
      opt_in : { 
        confirmed: true, 
        persisted: state.opt_in.persisted
      }
    };
  case 'SET_IS_MAP_VIEW':
    return { 
      ...state, 
      isMapView : action.isMapView
    };
  default:
    // console.log('REDUCER default map_count')
    return state;
  }
}

export default map;
