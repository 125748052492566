import React from "react";
import { CardIconGastronomy } from "../../styledCards";

export default function ArtsAndCrafts(props) {
  const { active } = props;

  return (
    <CardIconGastronomy 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M22.44,19.8 L22.44,7.62811681 L21.6442666,7.64275691 C19.2292098,7.73165802 17.32,9.7082573 17.32,12.12 L17.32,19.8 L22.44,19.8 Z" className={active? 'hov mi-bl-active' : 'hov mi-bl-inactive'} />
        <path fillRule="nonzero" d="M21.8,7 C22.1282149,7 22.3987246,7.24706572 22.4356943,7.56536248 L22.44,7.64 L22.44,24.28 C22.44,24.6334622 22.1534622,24.92 21.8,24.92 C21.4717851,24.92 21.2012754,24.6729343 21.1643057,24.3546375 L21.16,24.28 L21.16,7.64 C21.16,7.28653776 21.4465378,7 21.8,7 Z M14.76,7.64 C15.0882149,7.64 15.3587246,7.88706572 15.3956943,8.20536248 L15.4,8.28 L15.4,12.12 C15.4,13.6678648 14.3010148,14.9590478 12.8407593,15.2558342 L12.84,23.64 C12.84,23.9934622 12.5534622,24.28 12.2,24.28 C11.8717851,24.28 11.6012754,24.0329343 11.5643057,23.7146375 L11.56,23.64 L11.5600545,15.2559995 C10.1432734,14.9684339 9.06638,13.7448431 9.00295649,12.2588094 L9,12.12 L9,8.28 C9,7.92653776 9.28653776,7.64 9.64,7.64 C9.96821494,7.64 10.2387246,7.88706572 10.2756943,8.20536248 L10.28,8.28 L10.28,12.12 C10.28,12.9560013 10.8143041,13.6672092 11.5600569,13.9307685 L11.56,8.28 C11.56,7.92653776 11.8465378,7.64 12.2,7.64 C12.5282149,7.64 12.7987246,7.88706572 12.8356943,8.20536248 L12.84,8.28 L12.8405765,13.9305445 C13.5522212,13.6787572 14.0711715,13.019285 14.1167407,12.2328146 L14.12,12.12 L14.12,8.28 C14.12,7.92653776 14.4065378,7.64 14.76,7.64 Z" className={active? 'hov mi-fk-active' : 'hov mi-fk-inactive'} />
      </g>
    </CardIconGastronomy>
  )
}
