/* eslint-disable camelcase */
import React from 'react';
import styled from '@emotion/styled'
import MoabitMap from './MoabitMap';
import OptIn from './OptInDialog';

export default {
  title: 'MarkerInfo Examples',
};

export const MoabitMapWrapperMobile = ( props ) => {
  // eslint-disable-next-line camelcase
  const { map_opt_in_confirmed, isMaView } = props;
  return (

    <Wrapper style={isMaView? {display: 'block'} : {display: 'none'}}>
      {!map_opt_in_confirmed
        ? <OptIn {...props} />
        : <MoabitMap {...props} />}
    </Wrapper>
  )}

const Wrapper = styled.section`
  order: 1;
  flex: 1 0 100vw;
  width: 100vw;
  height: calc(100vh - 80px - 60px - 233px - 28px);
  height: calc(var(--vh, 1vh) * 100 - 80px - 60px - 233px - 28px);

  position: sticky;
  @media(min-width: 768px){
    order: 2;
    flex: 1 0 50vw;
    /* display: none !important; */
    width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);
    border-top: 2px solid ${({ theme }) => theme.mi_white};
    border-left: 2px solid ${({ theme }) => theme.mi_white};
  }
`;
