import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="60" 
      height="60" 
      viewBox="0 0 60 60"
    >
      <g fill="none" fillRule="evenodd" transform="translate(4 5)">
        <polygon xmlns="http://www.w3.org/2000/svg" points="26 0 46.328 9.789 51.348 31.786 37.281 49.425 14.719 49.425 .652 31.786 5.672 9.789" transform="rotate(-180 26 26)" className={active? 'mi-bg-active' : 'mi-bg-inactive'} />
        <g fillRule="nonzero" transform="translate(13 21)">
          <polygon className={active? 'mi-di-active' : 'mi-di-inactive'} points=".742 8.035 13 20 25.258 8.035 20.907 .603 5.093 .603" />
          <path fill="#0083CA" className={active? 'mi-dm-active' : 'mi-dm-inactive'} d="M20,7 C20.5522847,7 21,7.44771525 21,8 C21,8.51283584 20.6139598,8.93550716 20.1166211,8.99327227 L20,9 L13,9 C12.4477153,9 12,8.55228475 12,8 C12,7.48716416 12.3860402,7.06449284 12.8833789,7.00672773 L13,7 L20,7 Z" transform="rotate(-180 16.5 8)" />
        </g>
        <path className={active? 'mi-sh-active' : 'mi-sh-inactive'} fillRule="nonzero" d="M27,5 C27.407841,5.30588078 27.5155105,5.86348933 27.2690296,6.29497485 L27.2,6.4 L24.8,9.6 C24.4686292,10.0418278 23.8418278,10.1313708 23.4,9.8 C22.992159,9.49411922 22.8844895,8.93651067 23.1309704,8.50502515 L23.2,8.4 L25.6,5.2 C25.9313708,4.7581722 26.5581722,4.66862915 27,5 Z M2.31850414,5.10432455 L2.4,5.2 L4.8,8.4 C5.13137085,8.8418278 5.0418278,9.46862915 4.6,9.8 C4.19215895,10.1058808 3.62670731,10.053113 3.28149586,9.69567545 L3.2,9.6 L0.8,6.4 C0.46862915,5.9581722 0.5581722,5.33137085 1,5 C1.40784105,4.69411922 1.97329269,4.74688696 2.31850414,5.10432455 Z M14,0 C14.5128358,0 14.9355072,0.38604019 14.9932723,0.883378875 L15,1 L15,5 C15,5.55228475 14.5522847,6 14,6 C13.4871642,6 13.0644928,5.61395981 13.0067277,5.11662113 L13,5 L13,1 C13,0.44771525 13.4477153,0 14,0 Z" transform="translate(12 10)" />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 150ms;
  }
  .mi-bg-active{
    fill: #0083CA;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-sh-active{
    fill: #000000;
  }
  .mi-sh-inactive{
    fill: #AE9C93;
  }
  .mi-dm-active{
    fill: #0083CA;
  }
  .mi-dm-inactive{
    fill: #AE9C93;
  }
  .mi-di-active{
    fill: #ffffff;
  }
  .mi-di-inactive{
    fill: #E9D8CF;
  }
  &:hover{
    transform: scale3d(1.1,1.1,1.1);
    .mi-bg-inactive{
      fill: #0083CA;
    }
    .mi-sh-inactive{
      fill: #000000;
    }
    .mi-dm-inactive{
      fill: #0083CA;
    }
    .mi-di-inactive{
      fill: #ffffff;
    }
  }

`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "resetActiveCategory" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/

/*
const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(50%)'};
  transition-timing-function: ${({ active }) => active ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ active }) => active ? '1250ms' : '1200ms'};
  transition-property: transform;
`
*/

