/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import {reducer as responsive } from '../../utils/media-query-tracker';
import category_filter from './all/categoryFilter';
import isStateInitialized from './all/initialized';
import map from './all/map';
// import maps_api_key from './all/mapsApiKey';
import anchor from './all/pageAnchor';
import participants from './all/participants';
import route from './all/route';
import search from './all/search';
import view from './all/view';

const rootReducer = combineReducers({
  isStateInitialized, 
  map,
  participants,
  // maps_api_key,
  category_filter,
  responsive,
  view,
  route,
  anchor,
  search
});

export { rootReducer as default }
