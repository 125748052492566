import React from "react";
import styled from '@emotion/styled'

export default function IconNext(props) {
  const { active } = props;

  return (
    <Icon>
      <StyledSVG 
        active={active}
        xmlns="http://www.w3.org/2000/svg"
        width="24" 
        height="24" 
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </StyledSVG>
    </Icon>
  )
}


const Icon = styled.div`
  filter: drop-shadow(0 0 2px rgba(0, 0, 0,0.55));
  width: 32px;
  height: 32px;
  @media(min-width: 768px){
    width: 50px;
    height: 50px;
  }
`



const StyledSVG = styled.svg`  
  width: 100%;
  height: 100%;
  flex: 0 1 24px;
  fill: #fff;
`
