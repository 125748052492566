import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'



const CardIconBusiness = styled.svg`
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 500ms;
  }

  .mi-hd-active{
    fill: #000000;
  }
  .mi-hd-inactive{
    fill: #FEC110;
  }
  .mi-bg-active {
    fill: #FFF3CF;
  }
  .mi-bg-inactive{
    fill: #FFF3CF;
  }
`
const CardIconGastronomy = styled.svg`
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 500ms;
  }
  .mi-bl-active{
    fill: #FBDCD3;
  }
  .mi-bl-inactive{
    fill: #FBDCD3;
  }
  .mi-fk-active{
    fill: #000000;
  }
  .mi-fk-inactive{
    fill: #EA5223;
  }
`

const CardIconSocial = styled.svg`
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 500ms;
  }

  .mi-ft-active{
    fill: #000000;
  }
  .mi-ft-inactive{
    fill: #57C18B;
  }
  .mi-hd-active {
    fill: #DDF2E7;
  }
  .mi-hd-inactive{
    fill: #57C18B;
  }
`

const CardIconArts = styled.svg`
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;
  width: 100%;
  height: 100%;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 500ms;
  }
  .mi-dm-active{
    fill: #ffffff;
  }
  .mi-dm-inactive{
    fill: #0083CA;
  }
  .mi-sh-active{
    fill: #000000;
  }
  .mi-sh-inactive{
    fill: #0083CA;
  }
  .mi-ds-active{
    fill: #000000;
  }
  .mi-ds-inactive{
    fill: #ffffff;
  }
`


const StyledMobileCardOuter = styled(GatsbyLink)`
  width: 144px;
  height: 184px;
  
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 12px;
  border-radius: 4px;
  text-decoration: none;
  // 5 -> business -> yellow
  // 7 -> social -> green
  // 8 -> gastro -> red
  // 9 -> artsncrafts -> blue

  &.mi-card{
    display: flex;
    transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
    transition-property: background-color;
    transition-duration: 190ms;
    &:hover{
      background-color: ${({ theme }) => theme.mi_yellow};
      transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    &.mi-card-5{
      &:hover{
        background-color: ${({ theme }) => theme.mi_yellow};
        transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }
    &.mi-card-7{
      &:hover{
        background-color: ${({ theme }) => theme.mi_green};
        transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }
    &.mi-card-8{
      &:hover{
        background-color: ${({ theme }) => theme.mi_red};
        transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }
    &.mi-card-9{
      &:hover{
        background-color: ${({ theme }) => theme.mi_blue};
        transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
      }
    }
    &.mi-card-active{
      &.mi-card-5{
        background-color: ${({ theme }) => theme.mi_yellow};
      }
      &.mi-card-7{
        background-color: ${({ theme }) => theme.mi_green};
      }
      &.mi-card-8{
        background-color: ${({ theme }) => theme.mi_red};
      }
      &.mi-card-9{
        background-color: ${({ theme }) => theme.mi_blue};
      }
    }
  
  }

  &.mi-active-7{
    background-color: ${({ theme }) => theme.mi_green};
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  &.mi-active-5{
    background-color: ${({ theme }) => theme.mi_yellow};
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  &.mi-active-8{
    background-color: ${({ theme }) => theme.mi_red};
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  @media(min-width: 768px) {
    border-radius: 6px;
    position: relative;
    height: auto;
    &:before {
      display: block;
      content: "";
      width: 100%;
      /* padding-top: (47 / 48) * 100%; */
      padding-top: calc(0.9791666667 * 100%);
    }
    width: 288px;
    /* height: 282px; */
    margin-right: 48px;
    margin-bottom: 48px;

    width: calc(100% - 3rem);
    margin: 1.5rem;
  }

  @media(min-width: 1024px){
    width: calc(50% - 3rem);
    &:before {
      padding-top: calc(1.1 * 100%);
    }
  }

  @media(min-width: 1800px){
    width: calc(33.33% - 3rem);
    margin: 1.5rem;
  }
  /*
  @media(min-width: 2500px){
    width: calc(25% - 3rem);
    margin: 1.5rem;
    &:before {
      padding-top: calc(0.9791666667 * 100%);
    }
  }
  */
&:hover {
  ${CardIconArts} {
    transform: scale3d(1.2,1.2,1.2);

    .mi-dm-inactive{
      fill: #ffffff;
    }

    .mi-sh-inactive{
      fill: #000000;
    }

    .mi-ds-inactive{
      fill: #000000;
    }
  }
}

&:hover {
  ${CardIconSocial} {
    transform: scale3d(1.2,1.2,1.2);

    .mi-ft-inactive{
      fill: #000000;
    }

    .mi-hd-inactive{
      fill: #DDF2E7;
    }
  }
  ${CardIconBusiness} {
    transform: scale3d(1.2,1.2,1.2);

    .mi-hd-inactive{
      fill: #000000;
    }

    .mi-bg-inactive{
      fill: #FFF3CF;
    }
  }
  ${CardIconGastronomy} {
    transform: scale3d(1.2,1.2,1.2);

    .mi-bl-inactive{
      fill: #FBDCD3;
    }

    .mi-fk-inactive{
      fill: #000000;
    }
  }
}
`



const StyledMobileCardContent = styled.div`
  height: calc(100% - 81px);
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    flex: 1;
  }
`

const StyledMobileCardInner= styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`

const StyledMobileCardPhoto = styled.div`
  width: 144px;
  height: 81px;
  border-radius: 4px 4px 0 0;
  .mi-card-image{
    border-radius: 4px 4px 0 0;
  }
  @media(min-width: 768px){
    // width: 288px;
    // height: 162px;
    border-radius: 6px 6px 0 0;
    .mi-card-image{
      border-radius: 6px 6px 0 0;
    }
    width: 100%;
    height: auto;
  }

`
const StyledMobileCardIcon = styled.div`
  height: 32px;
  width: 32px;
  margin: 10px auto 7px;
  display: flex;
  justify-content: center;
  /* one column */
  @media(min-width: 768px){
    margin-top: 15px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 5vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
  /* two columns */
  @media(min-width: 1024px){
    margin-bottom: 0;
    margin-top: 10px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 3vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
  /* three columns */
  @media(min-width: 1800px){
    margin-top: 15px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 2vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
`

const StyledMobileCardText = styled.div`

  font-family: SofiaW01;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 11px 9px 11px;

  align-items: center;

  /*@media(min-width: 1200px){
    font-size: 22px;
    line-height: 1.55;
  }*/

  @media(min-width: 768px){

    flex: 2 0 auto;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 10vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }


  @media(min-width: 1024px){
    padding-bottom: 18px;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 1.5vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }


  @media(min-width: 1380px){
    padding-bottom: 18px;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 8vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }

  @media(min-width: 1800px){

    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 10vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }
`

const StyledMobileCardContainer = styled.div`
  order: 3;
  flex: 1 0 100vw;
  display: flex; 
  flex-wrap: no-wrap;
  overflow-x: scroll;
  padding: 24px;  
  width: 100vw;
  height: 233px;
  background-color: ${({ theme }) => theme.mi_background_real};
  padding-right: 24px;
  @media(min-width: 768px){
    align-content: flex-start;

    padding: 20px 24px;
    border-top: 2px solid ${({ theme }) => theme.mi_white};
    flex: 1 0 50vw;
    order: 1;
    flex-wrap: wrap;
    overflow-y: scroll;
    width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);
    /* display: none !important; */
  }

`

export {
  StyledMobileCardOuter,
  StyledMobileCardInner,
  StyledMobileCardPhoto,
  StyledMobileCardContent,
  StyledMobileCardIcon,
  StyledMobileCardText,
  StyledMobileCardContainer,
  CardIconArts,
  CardIconSocial,
  CardIconBusiness,
  CardIconGastronomy
}