/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, ClassNames, jsx } from "@emotion/core";
import styled from '@emotion/styled'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import { graphql, useStaticQuery } from "gatsby"
import lunr, { Index } from "lunr" // or any other 
// import stemmerSupport from 'lunr-languages'
// import de from 'lunr-languages/lunr.de'

import Autosuggest from 'react-autosuggest';
import { connect } from "react-redux";
import { useSpring, animated } from "react-spring";
import { CSSTransition } from 'react-transition-group';
import { debounce } from 'underscore';
import { searchUpdateInputValue, searchClearSuggestions, searchLoadSuggestionsBegin, searchMaybeUpdateSuggestions, setSearchResults, setFilteredParticipants, setIsSearchInitialResultsResults, setIsSearchNoResults, setIsSearchResultsView} from '../../state/actions'



require('lunr-languages//lunr.stemmer.support.js')(lunr);
require('lunr-languages/lunr.de.js')(lunr);


const SEARCH_QUERY = graphql`
  query SearchIndexQuery {
    LunrIndex
  }
`


const languages = [
  {
    name: 'C',
    year: 1972
  },
  {
    name: 'C#',
    year: 2000
  },
  {
    name: 'C++',
    year: 1983
  },
  {
    name: 'Clojure',
    year: 2007
  },
  {
    name: 'Elm',
    year: 2012
  },
  {
    name: 'Go',
    year: 2009
  },
  {
    name: 'Haskell',
    year: 1990
  },
  {
    name: 'Java',
    year: 1995
  },
  {
    name: 'Javascript',
    year: 1995
  },
  {
    name: 'Perl',
    year: 1987
  },
  {
    name: 'PHP',
    year: 1995
  },
  {
    name: 'Python',
    year: 1991
  },
  {
    name: 'Ruby',
    year: 1995
  },
  {
    name: 'Scala',
    year: 2003
  },
  {
    name: 'Scala Bala',
    year: 2003
  }
];

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const autoComplete = function autoComplete(searchTerm, index, store, onResultsChange, participants, isSearchResultsView){
  console.log('autocomplete this: ', this);
  console.log('bbba autoComplete searchTerm: ', searchTerm, 'index: ', index);
  console.log('bbba autoComplete store: ', store);
  const lowercaseSearchTerm = searchTerm.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, " ").replace(/ – /g,' ')
  const stopWords = 'aber alle allem allen aller alles als also am an ander andere anderem anderen anderer anderes anderm andern anderr anders auch auf aus bei bin bis bist da damit dann das dasselbe dazu daß dein deine deinem deinen deiner deines dem demselben den denn denselben der derer derselbe derselben des desselben dessen dich die dies diese dieselbe dieselben diesem diesen dieser dieses dir doch dort du durch ein eine einem einen einer eines einig einige einigem einigen einiger einiges einmal er es etwas euch euer eure eurem euren eurer eures für gegen gewesen hab habe haben hat hatte hatten hier hin hinter ich ihm ihn ihnen ihr ihre ihrem ihren ihrer ihres im in indem ins ist jede jedem jeden jeder jedes jene jenem jenen jener jenes jetzt kann kein keine keinem keinen keiner keines können könnte machen man manche manchem manchen mancher manches mein meine meinem meinen meiner meines mich mir mit muss musste nach nicht nichts noch nun nur ob oder ohne sehr sein seine seinem seinen seiner seines selbst sich sie sind so solche solchem solchen solcher solches soll sollte sondern sonst um und uns unse unsem unsen unser unses unter viel vom von vor war waren warst was weg weil weiter welche welchem welchen welcher welches wenn werde werden wie wieder will wir wird wirst wo wollen wollte während würde würden zu zum zur zwar zwischen über'.split(' ')
  const keywords = lowercaseSearchTerm.split(' ').filter(k => k !== '').filter(w => stopWords.indexOf(w) === -1)
  



  console.log('kkkeywords : ', keywords)
  if(lowercaseSearchTerm.length < 2){
    const noVal = true;
    onResultsChange([], noVal, isSearchResultsView)
    return [{
      name: 'ZERO_AUTO_SUGGESTIONS',
      year: 2021
    }] 
  }

  let andSearch = []

  keywords
    .filter(el => el.length > 0)
    // loop over keywords
    .forEach((el, i) => {
      console.log('ttu foreach el: ', el, i)
      // per-single-keyword results
      const keywordSearch = index
        .query(function(q) {
          // q.term(el)
          q.term(el, { editDistance: el.length > 5 ? 1 : 0 })
          q.term(el, {
            wildcard:
              lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
          })
        })
        .map(({ ref, matchData, score }) => {
          console.log('ttu ref: ', ref);
          console.log('ttu store[ref]', store[ref])

          return {
            ref,
            slug: ref,
            matchData,
            score,
            ...store[ref],
          }
        })
      // intersect current keywordSearch with andSearch
      andSearch =
        i > 0
          ? andSearch.filter(x => keywordSearch.some(el2 => el2.slug === x.slug))
          : keywordSearch
    })

  // let andSearch = []

  // const keywordSearch = index.query(function(q) {
  //   // exact matches should have the highest boost
  //   q.term(lowercaseSearchTerm, { boost : 100 })
  //   // wildcard matches should be boosted slightly
  //   q.term(lowercaseSearchTerm, {
  //     boost : 10,
  //     usePipeline : true,
  //     wildcard : lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING
  //   })
  //   // finally, try a fuzzy search, without any boost
  //   // q.term(lowercaseSearchTerm, { boost : 1, usePipeline : false, editDistance : 1 })
  // });
  // if (!keywordSearch.length) {
  //   return []
  //   // return "";
  // }
  // // intersect current keywordSearch with andSearch
  // andSearch =
  // i > 0
  //   ? andSearch.filter(x => keywordSearch.some(el => el.slug === x.slug))
  //   : keywordSearch

  console.log('ttu uu store: ', store)
  console.log('ttu uu results: ', andSearch)


  // const resultIds = andSearch.length? andSearch.map(p => p.id) : participants.map(p => p.id)
  const resultIds = andSearch.length? andSearch.map(p => p.id) : []
  onResultsChange(resultIds, null, isSearchResultsView)
  // dispatch(setFilteredParticipants(resultIds));
  
  const directPairs = [] //

  /*

    pairs of Fieldname and Result, ege:

    [Turmstraße, street], [Turmstraße 70, fullStreet ] , [vabali spa Berlin, title]


  */
  const stemmedPairs = new Set([]);

  const unstemmed = andSearch.length ? andSearch.map(function(v, i, a) { // extract unstemmed terms
    const unstemmedTerms = {};
    Object.keys(v.matchData.metadata).forEach(function(term) {


      Object.keys(v.matchData.metadata[term]).forEach(function(field) {
        // const matchComesFromField = field // title, street, streetFull, 
        const fieldVal = v[field]
        if(field !== 'content'){
          directPairs.push({name: fieldVal})
        } else {
          console.log('fff field === content')
          const stemmed = v.matchData.metadata[term][field].unstemmed[0]
          console.log('fff stemmed : ', stemmed)
          stemmedPairs.add({name: stemmed})          
        }

        v.matchData.metadata[term][field].unstemmed.forEach(function(word) {
          unstemmedTerms[word] = true;
        });
      });
    });
    return Object.keys(unstemmedTerms);
  }).reduce(function(a, b) { // flatten
    return a.concat(b);
  }).filter(function(v, i, a) { // uniq
    return a.indexOf(v) === i;
  }).map(r => ({name : r, year: 2021})).slice(0,5) : [{
    name: `ZERO_####${searchTerm}`,
    year: 2021
  }]

  // const res = directPairs
  //   .concat(unstemmed)

  const unique = [];
  const distinct = [];

  for( let i = 0; i < directPairs.length; i +=1 ){
    if( !unique[directPairs[i].name.toLowerCase()]){
      distinct.push(directPairs[i]);
      unique[directPairs[i].name.toLowerCase()] = 1;
    }
  }

  const stemmedArr = Array.from(stemmedPairs)
  for( let i = 0; i < stemmedArr.length; i +=1 ){
    if( !unique[stemmedArr[i].name.toLowerCase()]){
      distinct.push(stemmedArr[i]);
      unique[stemmedArr[i].name.toLowerCase()] = 1;
    }
  }

  return distinct.slice(0,5)

}



const getMatchingSearchValues = function getMatchingSearchValues(value, index, store, onResultsChange, participants, isSearchResultsView){
  console.log('bbba getMatchingSearchValues for index: ', index)
  console.log('bbba getMatchingSearchValues for value: ', value)
  console.log('bbba getMatchingSearchValues for store: ', store)
  console.log('bbba getMatchingSearchValues for this: ', this)
  console.log('bbba getMatchingSearchValues for onResultsChange: ', onResultsChange)
  const that = this;

  // const suggestMatch = idx.tokenSet; // .intersect(lunr.TokenSet.fromString(_suggest_keyword)).toArray();
  // const suggestMatch = index.tokenSet.intersect(lunr.TokenSet.fromString(`${value}*`)).toArray().map(w => ({name: w, year: 2021}));
  const suggestMatch = autoComplete(value, index, store, onResultsChange, participants, isSearchResultsView) // .bind(that)

  // const suggestMatch = index.search(value).map(m => ({name: m.ref, year: 2021}));
  console.log('bbba suggestMatch: ', suggestMatch)
  return suggestMatch;

  // const inputValue = value.trim().toLowerCase();
  // const inputLength = inputValue.length;
  // return inputLength === 0 ? [] : languages.filter(lang =>
  //   lang.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  // );
}; 


const StyledInput = styled.input`
  height: 79px;
  border: none;

  font-family: SofiaW01;
  font-size: 18px;

  color: black;
  padding-right: 0;
  width: calc(100% - 50px);
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }
  position: relative;
  z-index: 6;
  @media(min-width: 768px){
    font-size: 16px;
    border-radius: 25px;
    height: 50px;
  }
`


const renderCustomInputComponent = inputProps => (
  <StyledInput {...inputProps} />
);


const StyledSuggestions = styled.div`
  height: 50px;
  border: none;

  font-family: SofiaW01;
  font-size: 18px;
  color: black;
  padding-right: 0;
  width: calc(100% - 50px);
  // background: green;
  &:hover{
    // border-radius: 25px;
    background: white;
  }
  @media(min-width: 768px){
    border-radius: 25px;
  }
  @media(min-width: 768px){
    font-size: 16px;
  }


`


const StyledSuggestionsMessage = styled.div`
  height: 50px;
  border: none;

  font-family: SofiaW01;
  font-size: 17px;
  color: black;
  padding-right: 0;
  width: calc(100% - 50px);
  padding-left: 20px;


  font-weight: normal;

  font-size: 18px;


  letter-spacing: normal;
  color: #5d5d5d;
  @media(min-width: 768px){
    font-size: 16px;
    border-radius: 25px;
  }
`


function renderSuggestion(suggestion, { query }) {
  const matches = AutosuggestHighlightMatch(suggestion.name, query);
  const parts = AutosuggestHighlightParse(suggestion.name, matches);


  const regex = new RegExp('ZERO_(.*)');
  const isZeroVal= regex.test(suggestion.name)


  return (
    !isZeroVal ? (
      <StyledSuggestions>
        {parts.map((part, index) => {
          const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          )
        })}
      </StyledSuggestions>
    ) : null
  )
}

const StyledSuggestionsContainer = styled.div`
  border: none;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  top: -25px;
  z-index: 5;
  font-family: SofiaW01;
  font-size: 18px;
  color: black;
  padding-right: 0;
  width: 100%;
  // background: magenta;
  padding-top: 25px;
  @media(min-width: 768px){
    font-size: 16px;
    border-radius: 25px;



    top: -50px;
    padding-top: 50px;
  }
`

function renderSuggestionsContainer({ containerProps, children, query }) {


  const childrenz = React.Children.toArray(children).filter(ch => ch.props.items);


  const hasNoResults = this.props.autoCompleteNoResults;
  const isInitialResults = this.props.autoCompleteInitialResults;
  console.log('renderSuggestionsContainer props: ', this.props)

  console.log("childrenz: ", childrenz)

  const regex = new RegExp('ZERO_(.*)');
  
  const firstChildName = childrenz && childrenz.length ? childrenz[0].props.items[0].name : '';
  const isZeroVal= regex.test(firstChildName)

  const noRes = !!(childrenz && childrenz.length && childrenz[0].props.items.length === 1 && isZeroVal);



  return (
    <StyledSuggestionsContainer {...containerProps}>
      {noRes? null : children}
      { hasNoResults && !isInitialResults && noRes ?
        (
          <StyledSuggestionsMessage>
            Nichts gefunden
          </StyledSuggestionsMessage>
        ) : null }
      {/* <div>
        hasNoResults:
        {hasNoResults}
        {hasNoResults ? 'true' : 'false'}
        isInitialResults:
        {isInitialResults ? 'true' : 'false'}
      </div>
      {
        children ?
          (
            <div>
              Press Enter to search 
              {' '}
              <strong>{query}</strong>
            </div>
          ) : 
          (
            <div>
              Nichts gefunden
            </div>
          )
      } */}

    </StyledSuggestionsContainer>
  );
}

function randomDelay() {
  // return 30 + Math.random() * 1000;
  return 0;
}

function searchLoadSuggestions(value, index, store, that) {
  console.log('bbb searchLoadSuggestions value: ', value)
  console.log('bbb searchLoadSuggestions index: ', index)
  console.log('bbb searchLoadSuggestions store: ', store)
  console.log('bbb searchLoadSuggestions this: ', this)
  console.log('bbb searchLoadSuggestions that: ', that)
  // console.log('bbb searchLoadSuggestions store: ', store)
  return dispatch => {
    console.log('bbb dispatch searchLoadSuggestionsBegin')
    console.log('bbb dispatch searchLoadSuggestionsBegin this: ', this)
    console.log('bbb dispatch searchLoadSuggestionsBegin that: ', that)
    console.log('bbb dispatch dipatch:', dispatch)
    dispatch(searchLoadSuggestionsBegin());

    // const that = this;
    // console.log('kuzu that', that)



    dispatch(searchMaybeUpdateSuggestions(getMatchingSearchValues(value, index, store, that.onResultsChange, that.props.participants, that.props.isSearchResultsView), value));

    // Fake an AJAX call
    // setTimeout(() => {
    //   console.log('bbb dispatch searchMaybeUpdateSuggestions')
    //   dispatch(searchMaybeUpdateSuggestions(getMatchingSearchValues(value).bind(this), value));
    // }, randomDelay());
  };
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

const mapStateToProps = state => {
  return {
    val: state.search.value,
    suggestions: state.search.suggestions,
    isLoading: state.search.isLoading,
    isSearchView: state.search.isSearchView,
    isSearchResultsView: state.search.isSearchResultsView,
    lunrIndex: state.search.index,
    indexLoaded: state.search.indexLoaded,
    participants: state.participants.items,
    autoCompleteNoResults: state.search.autocomplete.noResults,
    autoCompleteInitialResults: state.search.autocomplete.initialResults,

  }
};

function mapDispatchToProps(dispatch) {
  return {
    setSearchResults,
    setFilteredParticipants,
    setIsSearchInitialResultsResults,
    setIsSearchNoResults,
    setIsSearchResultsView,
    onResultsChange(results, noval, isSearchResultsView){
      console.log('onResultsChange:  results:-> ', results)
      
      if(!results.length){
        dispatch(setIsSearchNoResults(true))

      } else {
        dispatch(setIsSearchNoResults(false))
        dispatch(setIsSearchInitialResultsResults(false))
      }
      if(!noval){
        dispatch(setIsSearchResultsView(true))
        dispatch(setFilteredParticipants(results))
      } else if(isSearchResultsView){
        dispatch(setFilteredParticipants([]))
      }
    },
    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }){
      console.log('onSuggestionSelected: ', event)
      console.log('onSuggestionSelected suggestion: ', suggestion)
      console.log('onSuggestionSelected suggestionValue: ', suggestionValue)
      console.log('onSuggestionSelected: suggestionIndex', suggestionIndex)
      console.log('onSuggestionSelected: sectionIndex', sectionIndex)
      console.log('onSuggestionSelected: method', method)
      const that = this;


      console.log('onSuggestionSelected: this.input', this.input)

      // autoComplete(value, index, store, onResultsChange, participants, isSearchResultsView) 

      // this.input.disabled = true

      this.setState({
        focused: false
      })
      // this.setDisabled(true);
      // this.input.addEventListener("click", function(e){
      //   console.log('kakaka click listener, e', e)
      // }, false);
      // this.input.disabled = false
      console.log('this.input.blur: ', this.input.blur)
      
      // dispatch(searchUpdateInputValue(suggestionValue));
      dispatch(searchLoadSuggestions(suggestionValue, that.index, that.store, that))
      // this.input.blur()
      // this.input.blur()
      // this.input.blur()
      // this.input.disabled = false

      // this.input.disabled = true
      // this.input.disabled = false
      // this.input.disabled = false
      // this.autosuggest.current.onSuggestionsClearRequested();
      // this.input.disabled = false;
    },
    onChange(event, { newValue }) {
      console.log('bbb onChange newValue', newValue)
      dispatch(searchUpdateInputValue(newValue));
    },

    onSuggestionsFetchRequested: debounce(function onSuggestionsFetchRequested(index, store, { value }) {
      console.log('bbba onSuggestionsFetchRequested')
      console.log('bbba onSuggestionsFetchRequested args: ', arguments)
      console.log('bbba onSuggestionsFetchRequested value: ', value)
      console.log('bbba onSuggestionsFetchRequested index: ', index)
      console.log('bbba onSuggestionsFetchRequested store: ', store)
      const that = this;
      console.log('bbb onSuggestionsFetchRequested this: ', this)

      // dispatch(searchLoadSuggestions(value, index, store).bind(this)).bind(that);
      dispatch(searchLoadSuggestions(value, index, store, that))
    }, 300),



    onSuggestionsClearRequested() {
      console.log('bbb onSuggestionsClearRequested')
      
      dispatch(searchClearSuggestions());


    }
  };
}



const FilterBarWrappedAnimatedV2 = styled(animated.div)`
// background: green;
opacity: 0.5;
// transform: translate3d(20px,30px,0);
position: absolute;
top: 0;

background: white;
height: 79px;
width: 0px;
z-index: 6;
pointer-events: none;
// height: 0;
@media(min-width: 768px){
  border-radius: 25px;
  height: 50px;
}
}
`


function shouldRenderSuggestions(value, reason) {
  // return value.trim().length > 2;
  return true;
}



class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // date: new Date()
      value : "",
      results: [],
      focused : true
    };

    // const idx = this.props.idx ? Index.load(this.props.idx) : Index.load({});
    this.onSuggestionsFetchRequested = () => {} // this.props.onSuggestionsFetchRequested.bind(this, idx, {})
    this.renderSuggestionsContainer = renderSuggestionsContainer.bind(this)

    // this.onSubmit = function(event){
    //   console.log('onSubmit onSubmit event: ', event);
    //   event.preventDefault()
    //   this.input.blur()
    // }

  }
  
  handleClick(e){
    e.preventDefault()
    this.input.disabled = false
  }

  componentDidMount(){

    console.log('lalelu: ', this.props.idx)

    const idx = this.props.idx ? Index.load(this.props.idx.index) : null;
    const store = this.props.idx ? this.props.idx.store : null;

    this.index = idx;
    this.store = store;
    this.onResultsChange= this.props.onResultsChange.bind(this);
    this.onSuggestionsFetchRequested=this.props.onSuggestionsFetchRequested.bind(this, idx, store)
    this.onSuggestionSelected=this.props.onSuggestionSelected.bind(this);
    // this.input.focus();

    // console.log('bbbau idx state on componentDidMount. this.props.idx', this.props.idx)
    // console.log('bbbau idx state on componentDidMount. this.props.onSuggestionsFetchRequested', this.props.onSuggestionsFetchRequested)
    // const idx = this.props.idx ? Index.load(this.props.idx.index) : null;
    // // const { store } = this.props.idx
    // console.log('bbbau idx store componentDidMount.', this.props.idx.store)
    // console.log('bbbau idx store componentDidMount.', this.props.idx.store)
    // this.onSuggestionsFetchRequested=this.props.onSuggestionsFetchRequested.bind(this, idx, this.props.idx.store)
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('bbbau componentDidUpdate :', this.onSuggestionsFetchRequested)
    // console.log('bbbau idx state on componentDidUpdate. this.props.onSuggestionsFetchRequested', this.props.onSuggestionsFetchRequested)

    // // if (prevProps.idx !== this.props.idx || !prevProps.idx) {
    // //   console.log('bbbau idx state has changed.')
    // //   // const { store } = this.props.idx
    // //   console.log('bbbau idx store has changed.', this.props.idx.store)

    // //   const idx = this.props.idx ? Index.load(this.props.idx.index) : null;
    // //   this.onSuggestionsFetchRequested=this.props.onSuggestionsFetchRequested.bind(this, idx, this.props.idx.store)
    // //   console.log('bbbau idx state on componentDidUpdate. this.onSuggestionsFetchRequested', this.onSuggestionsFetchRequested)

    // // }
    // console.log('bbbau prevProps.indexLoaded', prevProps.indexLoaded, ', this.props.indexLoaded', this.props.indexLoaded)

    if(prevProps.indexLoaded !== this.props.indexLoaded && this.props.indexLoaded){
      console.log('bbbau componentDidUpdate prevProps.indexLoaded !== this.props.indexLoaded && this.props.indexLoaded')
      console.log('bbbau idx', this.props.idx)

      const idx = this.props.idx ? Index.load(this.props.idx.index) : null;
      const store = this.props.idx ? this.props.idx.store : null;

      this.index = idx;
      this.store = store;

      // this.onSuggestionsFetchRequested=this.props.onSuggestionsFetchRequested.bind(this, idx, this.props.idx.store)
      this.onResultsChange= this.props.onResultsChange.bind(this);
      this.onSuggestionsFetchRequested=this.props.onSuggestionsFetchRequested.bind(this, idx, store)
      this.onSuggestionSelected=this.props.onSuggestionSelected.bind(this);
    }
    if(this.props.isSearchView){
      // this.input.focus();
      // this.input.focus();
      setIsSearchInitialResultsResults(true)
    }
  }

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
      // this.input.addEventListener('click', function(e){
      //   console.log('clickclickclik e: ', e)
      //   e.target.disabled = false;
      // })
      // this.input.on('click', {
        
      // })
    }
  };
  



  render() {
    const {
      val,
      suggestions,
      isLoading,
      isSearchView,
      isSearchResultsView,
      onChange,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      aniStyle,
      lunrIndex,
      indexLoaded,
      setFilteredParticipants,
      setIsSearchInitialResultsResults,
      setIsSearchNoResults,
      onResultsChange,
      participants,
      autoCompleteNoResults,
      autoCompleteInitialResults,
    } = this.props;

    const inputProps = {
      placeholder: "Ort suchen",
      value: val,
      onChange
    };

    // const [value, setValue] = useState("")
    // const [results, setResults] = useState([])
    // const { LunrIndex } = useStaticQuery(SEARCH_QUERY)
    // const LunrIndex = lunrIndex
    // const index = Index.load(LunrIndex)
    // const { store } = LunrIndex


    // const status = isLoading ? "Loading..." : "Type to load suggestions";
    const handleSubmit = function(e){
      e.preventDefault();
      this.input.blur()
    }

    return (
      <>
        {/* <div>{JSON.stringify(lunrIndex)}</div> */}
        <FilterBarWrappedAnimatedV2 style={aniStyle}>
          <form onSubmit={handleSubmit}>
            <Autosuggest
            // alwaysRenderSuggestions
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              // onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              renderInputComponent={renderCustomInputComponent}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              ref={this.storeInputReference}
              onSuggestionSelected={this.onSuggestionSelected}
              shouldRenderSuggestions={shouldRenderSuggestions}
            // focusInputOnSuggestionClick={false}
            // onClick={this.handleClick}
            // alwaysRenderSuggestions
            />
          </form>
        </FilterBarWrappedAnimatedV2>
      </>
    );
  }
}

const MyConnectedAutoThingy = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyComponent);

export { MyConnectedAutoThingy }
