import React, { useEffect } from 'react';
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import { withTheme, ThemeProvider } from 'emotion-theming'
import { connect } from "react-redux"
import ParticipantCardsMobile from '../components/CardBox';



import FilterCategoriesMobile from '../components/FilterBar'
import Footer from '../components/Footer'
import HeaderBackDrop from '../components/NewHeader/HeaderBackDrop'
import HomeLink from '../components/NewHeader/HomeLink'
import MapLink from '../components/NewHeader/MapLink'
import LogoWall from '../components/LogoWall';
import { MoabitMapWrapperMobile } from '../components/NewMap';
import BurgerAll from '../components/navigation/BurgerAll'
import MainMenu from '../components/navigation/mainmenu'
import ParticipantSingle from '../components/Participant'
import { GlobalStateContext } from "../components/ParticipantInfinite/GlobalState"

import Searchbar from "../components/Search/Searchbar";
import SearchResultsMobile from '../components/SearchResults';
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setActiveParticipant, setIsCategoryView, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX, setIsSearchView } from '../state/actions'
import GlobalStylesThemed from './global';
import theme  from './theme';



const Header= styled.header`
  &.mi-header-home{
    background: #9ac9df;
  }
`;


const Main= styled.main`
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &.mi-main-home{
    background: #9ac9df;
  }
`;

const WrapMapView= styled.div`
display: ${({isMaView, isCaView}) => isCaView || isMaView ? 'block' : 'none' };
@media(min-width: 768px){    
  display: flex;
  display: ${({isMaView, isCaView}) => isCaView || isMaView ? 'flex' : 'none' };
  }
`;


const GlobalStateDebug = styled.ul`
  padding: 0;
  list-style: none;
  background: green;
  position: fixed;
  top: 50px;
  right: 50px;
  width: auto;
  z-index: 100;
`

const GlobalStateDebugItem = styled.li`
  padding: 0;
  display: flex;
  justify-content: space-between;
  > div {
    padding: 10px;
  }
`

const makeGlobalStyles = t => {
  const cssStr = GlobalStylesThemed(t)
  return css`${cssStr}`
} 

const GlobalStyles = withTheme(({ theme }) => (
  <Global styles={makeGlobalStyles(theme)} />
))

const mapStateToProps = (state) => {
  return {
    isStateInitialized: state.isStateInitialized,
    active_category_id: state.participants.active_category.id,
    active_category_name: state.participants.active_category.name,
    active_participant_id: state.participants.active_participant_id,
    map_opt_in_confirmed: state.map.opt_in.confirmed,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    participants: state.participants.items,
    filteredParticipants: state.participants.filteredItems,
    isSearchView: state.search.isSearchView,
    searchResults: state.search.results,
  }
}

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant,
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX,
  setIsSearchView,
  // setFilteredParticipants,
};

const MoabitLayout = ( props ) => {
  const { 
    children, 
    pageContext, 
    places, 
    singleParticipant, 
    active_participant_id, 
    isStateInitialized, 
    setInitalizedToTrue, 
    initParticipantCategory, 
    setActiveParticipant, 
    setIsMapView, 
    setIsCategoryView, 
    map_opt_in_confirmed, 
    activateParticipant, 
    participants, 
    setCurrentParticipants, 
    allSourceParticipants, 
    setActivatedParticipantOffsetX, 
    isMapView, 
    isCategoryView, 
    initialIsMapView, 
    initialIsCategroyView, 
    initialCategoryId, 
    initialParticipantId, 
    initialCategoryName,
    active_category_id , 
    active_category_name,
    filteredParticipants,
    isSearchView,
    setIsSearchView,
    lunrIndex,
    searchResults,
  } = props;

  const { databaseId : pageId, layout: pageLayout, slug: pageSlug, categoryDatabaseId, name: categoryName} = pageContext;

  const diffit = { pageContext , active_participant_id, isStateInitialized,  map_opt_in_confirmed, isMapView, isCategoryView, initialIsMapView, initialIsCategroyView, initialCategoryId, initialParticipantId, active_category_id, active_category_name}

  console.log('uuu pageId: ', pageId, ' , pageLayout: ', pageLayout, ' , pageSlug: ', pageSlug )
  console.log('uuu pageContext: ', JSON.stringify(pageContext, null, 4))
  console.log('uuu diffit: ', JSON.stringify(diffit, null, 4))

  const isMaV = isMapView !== null && isStateInitialized ? isMapView : initialIsMapView;
  const isCatV = isCategoryView !== null && isStateInitialized ? isCategoryView : initialIsCategroyView;
  const activePartId = active_participant_id || initialParticipantId;
  let activCatId = active_category_id || initialCategoryId;
  const activeCatName = active_category_name || categoryName || 'Alle Kategorien';
  const plazez = places;

  const debugString = `
    isMav: ${isMaV? 'true' : 'false'} <br />
    isCatV: ${isCatV? 'true' : 'false'} <br />
    activCatId: ${activCatId} <br />
    activePartId: ${activePartId} <br />
    activeCatName: ${activeCatName} <br />
    plazez: ${plazez.map(p => p.title).join(' ')} <br />
    pageContext: ${JSON.stringify(pageContext, null, 4)}
  `
  useEffect(() => {
    activCatId = categoryDatabaseId || null;
    let activeCardId;
    console.log('hohoho activCatId: ', activCatId, ' plazez.length', plazez.length, ' , plazez: ', plazez);
    if(activCatId && plazez.length){
      const firstOfCat = plazez.find(p => p.participantCategories.nodes[0].databaseId === activCatId)
      activeCardId = firstOfCat? firstOfCat.databaseId : null;
    } else if (plazez.length){
      activeCardId = plazez[0].databaseId;
    }

    console.log('ANOW mpas.js useEffect pageLayout changed: ', pageLayout)
    console.log('HIHI participants: ', participants )
    console.log('HIHI allSourceParticipants: ', allSourceParticipants )
    if(participants.length === 0 && allSourceParticipants.length > 0){
      const ps = allSourceParticipants.map(p => {
        console.log('mumsen: ', p)
        return {
          id: p.databaseId,
          categoryId: p.participantCategories.nodes[0].databaseId,
          fetched: false,
          data: null,
          uri: p.uri,
          shortTitle: p.mi_participant.miParticipantShorttitle,
          title: p.title,
        }
      })
      setCurrentParticipants(ps)
    }

    switch(pageLayout){
    case 'search':
      console.log('ANOW CASE search')
      setIsMapView(true)
      setIsCategoryView(true)
      setIsSearchView(true)
      if(!isStateInitialized){
        setActivatedParticipantOffsetX(24);
        setInitalizedToTrue();
        setActiveParticipant(activeCardId, activCatId )
        initParticipantCategory(null, 'Alle Kategorien')
        activateParticipant(activeCardId)
      }
      break;
      
    case 'category':
      console.log('ANOW CASE category')
      setIsMapView(true)
      setIsCategoryView(true)
      setIsSearchView(false)
      if(!isStateInitialized){
        setActiveParticipant(activeCardId, activCatId )
        activateParticipant(activeCardId)
        initParticipantCategory(activCatId, categoryName)
        setInitalizedToTrue();
      }
      break;
    case 'category_index':
      console.log('ANOW CASE category_index')
      setIsMapView(true)
      setIsCategoryView(true)
      setIsSearchView(false)
      if(!isStateInitialized){
        setActivatedParticipantOffsetX(24);
        setInitalizedToTrue();
        setActiveParticipant(activeCardId, activCatId )
        initParticipantCategory(null, 'Alle Kategorien')
        activateParticipant(activeCardId)
      }

      break;
    case 'participant':
      console.log('ANOW CASE participant')
      console.log('ANOW CASE participant active_participant_id', active_participant_id)
      console.log('ANOW CASE participant activeCardId', activeCardId)
      setIsMapView(true)
      setIsCategoryView(false)
      setIsSearchView(false)
      if(!isStateInitialized){
        initParticipantCategory(initialCategoryId, initialCategoryName)
        setInitalizedToTrue();
      }
      setActiveParticipant(initialParticipantId, initialCategoryId);
      activateParticipant(initialParticipantId, initialCategoryId);
      // setActiveParticipant( active_participant_id || activeCardId, activCatId )
      // activateParticipant( active_participant_id || activeCardId)
      break;
    default: 
      console.log('ANOW CASE default')
      setIsMapView(false)
      setIsCategoryView(false)
      setIsSearchView(false)
      break;
    }
  }, [pageLayout, isSearchView])

  console.log('\n\n\n=====\nmap.js: isStateInitialized? ', isStateInitialized? 'true' : 'false')

  return (
    <GlobalStateContext.Consumer>
      {g => (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          
          <header id="mi-header" className={`mi-header-${pageLayout}`}>
            <HeaderBackDrop pageLayout={pageLayout}>
              <HomeLink />
              <MapLink isCategoryView={isCatV} isMapView={isMaV} /> 
              <BurgerAll />
              <MainMenu />
            </HeaderBackDrop>
          </header>

          {/* <GlobalStateDebug>
            <GlobalStateDebugItem>
              <div>isLoading</div>
              <div>{g.isLoading? 'true' : 'false'}</div>
            </GlobalStateDebugItem>
            <GlobalStateDebugItem>
              <div>cursor</div>
              <div>{g.cursor}</div>
            </GlobalStateDebugItem>
            <GlobalStateDebugItem>
              <div>useInfiniteScroll</div>
              <div>{g.useInfiniteScroll? 'true' : 'false'}</div>
            </GlobalStateDebugItem>
            <GlobalStateDebugItem>
              <div>hasMore</div>
              <div>{g.hasMore(pageContext)? 'true' : 'false'}</div>
            </GlobalStateDebugItem>
            <GlobalStateDebugItem>
              <div>isInitializing</div>
              <div>{g.isInitializing()? 'true' : 'false'}</div>
            </GlobalStateDebugItem>
          </GlobalStateDebug> */}
          <Main className={`mi-main-${pageLayout}`}>
            <WrapMapView isMaView={isMaV} isCaView={isCatV}>
              <MoabitMapWrapperMobile filteredParticipants={filteredParticipants} initialCategoryId={activCatId} isMaView={isMaV} map_opt_in_confirmed={map_opt_in_confirmed} places={plazez} {...props}  />


              <Searchbar lunrIndex={lunrIndex} isSearchView={isSearchView} isMaView={isMaV} isCaView={isCatV} initialCategoryName={activeCatName} initialCategoryId={activCatId}  /> 
              <FilterCategoriesMobile isSearchView={isSearchView} isMaView={isMaV} isCaView={isCatV} initialCategoryName={activeCatName} initialCategoryId={activCatId}  />

              {isSearchView
                ? <SearchResultsMobile filteredParticipants={filteredParticipants} isMapView={isMaV} isCategoryView={isCatV} initialParticipantId={activePartId} initialCategoryId={activCatId} places={plazez} results={searchResults} />
                : <ParticipantCardsMobile filteredParticipants={filteredParticipants} isMapView={isMaV} isCategoryView={isCatV} initialParticipantId={activePartId} initialCategoryId={activCatId} places={plazez} />}
              


            </WrapMapView>
            <ParticipantSingle isCaView={isCatV} isMaView={isMaV} participantData={singleParticipant} pData={singleParticipant} activeCategoryId={activCatId} activated_participant_id={singleParticipant? singleParticipant.id : null} places={plazez} {...props} />
            {children}
          </Main>
          <LogoWall />
          <Footer />
          {/* <div dangerouslySetInnerHTML={{ __html: debugString}} /> */}
        </ThemeProvider>
      )}
    </GlobalStateContext.Consumer>
  )}

const ConnectedMoabitLayout = connect(mapStateToProps, mapDispatchToProps)(MoabitLayout)

export default ConnectedMoabitLayout
