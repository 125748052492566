import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'


function thunkMiddleware(_ref) {
  const {dispatch} = _ref;
  const {getState} = _ref;

  return function (next) {
    return function (action) {
      return typeof action === 'function' ? action(dispatch, getState) : next(action);
    };
  };
}

// const asyncDispatchMiddleware = store => next => action => {
//   let syncActivityFinished = false;
//   let actionQueue = [];

//   function flushQueue() {
//     actionQueue.forEach(a => store.dispatch(a)); // flush queue
//     actionQueue = [];
//   }

//   function asyncDispatch(asyncAction) {
//     actionQueue = actionQueue.concat([asyncAction]);

//     if (syncActivityFinished) {
//       flushQueue();
//     }
//   }

//   const actionWithAsyncDispatch =
//       { ...action, asyncDispatch};

//   next(actionWithAsyncDispatch);
//   syncActivityFinished = true;
//   flushQueue();
// };

const initialState = {
  isStateInitialized: false,
  view: {
    isCategoryView: null,
  },
  map: {
    isMapView: null,
    // maps_api_key: process.env.REACT_APP_MAP_KEY,
    opt_in: {
      confirmed: typeof document !== 'undefined' 
        ? document.cookie.indexOf('mi_allow_gmaps=') > -1
        : false,
      // user confirmed map usage
      persisted : typeof document !== 'undefined' 
        ? document.cookie.indexOf('mi_allow_gmaps=') > -1
        : false, // confirmation is saved as cookie
      // count: 93, // for test pruposes only
    },
  },
  participants: {
    active_category: {
      id: null,
      name: null,
      slug: ''
    },
    active_participant_id: null,
    activated_participant_id: null,
    opened_participant_id: null,
    activated_participant_offset_x: 0,
    items: [],
    filteredItems: [],
    // participantData: null,
    current_slide_index: 0,
    slider_initialized: false,
  },
  search: {
    isSearchView: false,
    isSearchResultsView: false,
    value: '',
    suggestions: [
      {
        name: 'ZERO_INITIAL_SUGGESTIONS',
        year: 2021
      }],
    isLoading: false,
    indexLoaded: false,
    autocomplete: {
      noResults: false,
      initialResults: false
    }

  },
  anchor: null,
  // mapOptIn: {
  //   map_count: 111,
  //   maps_allowed: typeof document !== 'undefined' 
  //     ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //     : false,
  //   maps_allowed_with_cookie: typeof document !== 'undefined' 
  //     ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //     : false,
  // },
  // count: 2,
  // maps_allowed : typeof document !== 'undefined' 
  //   ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //   : false,

  category_filter: 'all',

  // gmaps: {
  //   loaded: false,
  //   map: null,
  //   google: null,
  //   zoom: 4
  // },
}

function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}

const store = configureStore( initialState )



export default store;