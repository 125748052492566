const defaults = {
  useSearch: true,
  isOpen: false,
  isActive: false,
  hasResults: false,
  resultsCount: 0,
  error: null,
  items: [], // participant ids,

  value: '',
  suggestions: [],
  isLoading: false,
  isSearchView: false
}

function search(state = defaults, action ) {
  switch(action.type){


  case 'SEARCH_SET_IS_SEARCH_VIEW':
    return { 
      ...state, 
      isSearchView : action.isSearchView
    };

  case 'SEARCH_SET_IS_SEARCH_RESULTS_VIEW':
    return { 
      ...state, 
      isSearchResultsView : action.isSearchResultsView
    };


  case 'SEARCH_UPDATE_INPUT_VALUE':
    return {
      ...state,
      value: action.value
    };

  case 'SEARCH_CLEAR_SUGGESTIONS':
    return {
      ...state,
      suggestions: [{name : 'ZERO_CLEAR_SUGGESTIONS', year: 2021}]
    };

  case 'SEARCH_LOAD_SUGGESTIONS_BEGIN':
    return {
      ...state,
      isLoading: true
    };

  case 'SEARCH_SET_SEARCH_INDEX_DATA':
    return {
      ...state,
      index: action.indexData
    };


  case 'SEARCH_SET_RESULTS':
    return {
      ...state,
      results: action.results
    }

  case 'SEARCH_SET_SEARCH_INDEX_DATA_LOADED':
    return {
      ...state,
      indexLoaded: action.loaded
    };

  case 'SEARCH_INITIAL_RESULTS':
    return {
      ...state,
      autocomplete: {
        ...state.autocomplete,
        initialResults: action.initialResults
      }
    };

  case 'SEARCH_NO_RESULTS':
    return {
      ...state,
      autocomplete: {
        ...state.autocomplete,
        noResults: action.noResults
      }
    };


  case 'SEARCH_MAYBE_UPDATE_SUGGESTIONS':
    // Ignore suggestions if input value changed
    if (action.value !== state.value) {
      return {
        ...state,
        isLoading: false
      };
    }

    return {
      ...state,
      suggestions: action.suggestions,
      isLoading: false
    };

  default: 
    return {
      ...state,
      suggestions: [
        {
          name: 'ZERO_MAYBE_SUGGESTIONS',
          year: 2021
        }
      ]
    }
  }
}

export default search;
