import React from "react"
import { Provider } from "react-redux"
import { GlobalState } from "./src/components/ParticipantInfinite/GlobalState"
import store from './src/state/createStore';
import { mediaQueryTracker} from './src/utils/media-query-tracker';


export default ( props ) => {
  const { element } = props;

  mediaQueryTracker({
    debounceTime: 150,
    queries: [
      {isPhone : "screen and (max-width: 767px)"},
      {isTablet: "screen and (min-width: 768px) and (max-width: 1024px)"},
      {isDesktop: "screen and (min-width: 1025px)"}
    ],
    windowDimensions: {
      doTrack: true,
      innerHeight: 0,
      innerWidth: 0
    }
  }, store.dispatch );
  return (
    <GlobalState>
      <Provider store={store}>
        {element}
      </Provider>
    </GlobalState>
  )
}
