import { navigate } from "@reach/router"

function setCookie(e, t, i) {
  let o = "";
  if (i) {
    const n = new Date;
    n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3)
    o = `; expires=${  n.toUTCString()}`
  }
  if(typeof document !== 'undefined') {
    document.cookie = `${e  }=${  t || ""  }${o  }; path=/`
  }
}

export const HYDRATE_PLACES = 'HYDRATE_PLACES';
export const hydratePlaces = () => {
  // console.log('index action: increment');
  return {
    type: HYDRATE_PLACES,
  };
};

export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const updateRoute = (location) => {
  // navigate(location.href);
  console.log('anaction  : ', UPDATE_ROUTE)
  console.log('updateRoute: location: ', JSON.stringify(location, null, 4))
  return {
    type: UPDATE_ROUTE,
    href : location.pathname
  };
};

export const INITIALIZE_PARTICIPANT_CATEGORY = 'INITIALIZE_PARTICIPANT_CATEGORY';
export const initParticipantCategory = ( participantCategoryId = null, participantCategoryName = '' ) => {
  // console.log('index action: initStateFromQuery');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', INITIALIZE_PARTICIPANT_CATEGORY)

  return {
    type: INITIALIZE_PARTICIPANT_CATEGORY,
    participant_category_id: participantCategoryId,
    participant_category_name: participantCategoryName
  };
};

export const SET_INIATLIZED_TO_TRUE = 'SET_INIATLIZED_TO_TRUE';
export const setInitalizedToTrue = () => {
  // console.log('index action: setInitalizedToTrue');
  console.log('anaction  : ', SET_INIATLIZED_TO_TRUE)

  return {
    type: SET_INIATLIZED_TO_TRUE,
  };
};

export const SET_SSR_DONE = 'SET_SSR_DONE';
export const setSSRDone = (done = false) => {
  // console.log('index action: setInitalizedToTrue');
  console.log('anaction  : ', SET_SSR_DONE)

  return {
    type: SET_SSR_DONE,
    done
  };
};


export const SET_IS_MAP_VIEW = 'SET_IS_MAP_VIEW';
export const setIsMapView = ( isMapView = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SET_IS_MAP_VIEW)

  return {
    type: SET_IS_MAP_VIEW,
    isMapView
  };
};

export const SET_IS_CATEGORY_VIEW = 'SET_IS_CATEGORY_VIEW';
export const setIsCategoryView = ( isCategoryView = false ) => {
  // console.log('index action: setIsCategoryView');
  console.log('anaction  : ', SET_IS_CATEGORY_VIEW)
  
  return {
    type: SET_IS_CATEGORY_VIEW,
    isCategoryView
  };
};

export const OPT_IN_GOOGLE_MAPS = 'OPT_IN_GOOGLE_MAPS';
export const optInMaps = () => {
  // console.log('index action: optInGoogleMaps');
  setCookie('mi_allow_gmaps', '', 3650);
  console.log('anaction  : ', OPT_IN_GOOGLE_MAPS)
  
  return {
    type: OPT_IN_GOOGLE_MAPS,
  };
};




export const RESET_ACTIVE_CATEGORY = 'RESET_ACTIVE_CATEGORY';
export const resetActiveCategory = (participantCategoryId = null, participantCategoryName = '', participantCategorySlug = '' )  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', RESET_ACTIVE_CATEGORY)

  return {
    type: RESET_ACTIVE_CATEGORY,
    participant_category_id: participantCategoryId,
    participant_category_name: participantCategoryName,
    participant_category_slug: participantCategorySlug
  };
};


export const SET_FILTERED_PARTICIPANTS = 'SET_FILTERED_PARTICIPANTS';
export const setFilteredParticipants = (participantsIds = [])  => {
  console.log('anaction  : ', SET_FILTERED_PARTICIPANTS, participantsIds)

  return {
    type: SET_FILTERED_PARTICIPANTS,
    participantsIds,
    // participant_category_id: participantCategoryId
  };
};

export const SET_CURRENT_PARTICIPANTS = 'SET_CURRENT_PARTICIPANTS';
export const setCurrentParticipants = (participants = [])  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_CURRENT_PARTICIPANTS)

  return {
    type: SET_CURRENT_PARTICIPANTS,
    participants,
    // participant_category_id: participantCategoryId
  };
};

export const SET_ACTIVE_PARTICIPANT = 'SET_ACTIVE_PARTICIPANT';
export const setActiveParticipant = (participantId = null, participantCategoryId = null)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_ACTIVE_PARTICIPANT, participantId)

  return {
    type: SET_ACTIVE_PARTICIPANT,
    active_participant_id: participantId,
    // participant_category_id: participantCategoryId
  };
};

export const SET_SLIDE_INDEX = 'SET_SLIDE_INDEX';
export const setSlideIndex = (slideIndex = 0)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_SLIDE_INDEX)

  return {
    type: SET_SLIDE_INDEX,
    slide_index: slideIndex,
    // participant_category_id: participantCategoryId
  };
};

export const SET_INIATLIZED_SLIDER = 'SET_INIATLIZED_SLIDER';
export const setInitializedSlider = (initializedSlider = false)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_INIATLIZED_SLIDER)

  return {
    type: SET_INIATLIZED_SLIDER,
    slider_initialized: initializedSlider,
    // participant_category_id: participantCategoryId
  };
};

export const ACTIVATE_PARTICIPANT = 'ACTIVATE_PARTICIPANT';
export const activateParticipant = (participantId = null)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', ACTIVATE_PARTICIPANT)

  return {
    type: ACTIVATE_PARTICIPANT,
    activated_participant_id: participantId,
  };
};


export const SET_SINGLE_PARTICIPANT_DATA = 'SET_SINGLE_PARTICIPANT_DATA';
export const setParticipantData = (participantData = null)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_SINGLE_PARTICIPANT_DATA)

  return {
    type: SET_SINGLE_PARTICIPANT_DATA,
    participant_data: participantData,
  };
};

export const SET_ACTIVATED_PARTICIPANT_OFFSET_X = 'SET_ACTIVATED_PARTICIPANT_OFFSET_X';
export const setActivatedParticipantOffsetX = (participantOffsetX = null)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', SET_ACTIVATED_PARTICIPANT_OFFSET_X)

  return {
    type: SET_ACTIVATED_PARTICIPANT_OFFSET_X,
    activated_participant_offset_x: participantOffsetX,
    // participant_category_id: participantCategoryId
  };
};

export const OPEN_PARTICIPANT = 'OPEN_PARTICIPANT';
export const openParticipant = (participantId = null)  => {
  // console.log('index action: RESET_ACTIVE_CATEGORY');
  // console.log('participantCategoryId: ', participantCategoryId)
  console.log('anaction  : ', OPEN_PARTICIPANT)

  return {
    type: OPEN_PARTICIPANT,
    opened_participant_id: participantId,
  };
};

export const SET_ACTIVE_PAGE_ANCHOR = "SET_ACTIVE_PAGE_ANCHOR";
export const setActivePageAnchor = (pageAnchorId = null) => {
  return {
    type: SET_ACTIVE_PAGE_ANCHOR,
    active_page_anchor: pageAnchorId
  };
};

/* SEARCH */

export const SEARCH_UPDATE_INPUT_VALUE = "SEARCH_UPDATE_INPUT_VALUE";
export const searchUpdateInputValue = (value) => {
  return {
    type: SEARCH_UPDATE_INPUT_VALUE,
    value
  };
};

export const SEARCH_CLEAR_SUGGESTIONS = "SEARCH_CLEAR_SUGGESTIONS";
export const searchClearSuggestions = () => {
  return {
    type: SEARCH_CLEAR_SUGGESTIONS
  };
};


export const SEARCH_LOAD_SUGGESTIONS_BEGIN = "SEARCH_LOAD_SUGGESTIONS_BEGIN";
export const searchLoadSuggestionsBegin = () => {
  return {
    type: SEARCH_LOAD_SUGGESTIONS_BEGIN
  };
};

export const SEARCH_MAYBE_UPDATE_SUGGESTIONS = "SEARCH_MAYBE_UPDATE_SUGGESTIONS";
export const searchMaybeUpdateSuggestions = (suggestions, value) => {
  return {
    type: SEARCH_MAYBE_UPDATE_SUGGESTIONS,
    suggestions,
    value
  };
};

export const SEARCH_SET_IS_SEARCH_VIEW = "SEARCH_SET_IS_SEARCH_VIEW";
export const setIsSearchView = ( isSearchView = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_SET_IS_SEARCH_VIEW)

  return {
    type: SEARCH_SET_IS_SEARCH_VIEW,
    isSearchView
  };
};

export const SEARCH_SET_IS_SEARCH_RESULTS_VIEW = "SEARCH_SET_IS_SEARCH_RESULTS_VIEW";
export const setIsSearchResultsView = ( isSearchResultsView = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_SET_IS_SEARCH_RESULTS_VIEW)

  return {
    type: SEARCH_SET_IS_SEARCH_RESULTS_VIEW,
    isSearchResultsView
  };
};

export const SEARCH_SET_SEARCH_INDEX_DATA = "SEARCH_SET_SEARCH_INDEX_DATA";
export const setSearchIndexData = ( indexData ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_SET_SEARCH_INDEX_DATA)

  return {
    type: SEARCH_SET_SEARCH_INDEX_DATA,
    indexData
  };
};

export const SEARCH_SET_RESULTS = "SEARCH_SET_RESULTS";
export const setSearchResults = ( results = [] ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_SET_RESULTS)

  return {
    type: SEARCH_SET_RESULTS,
    results
  };
};

export const SEARCH_SET_SEARCH_INDEX_DATA_LOADED = "SEARCH_SET_SEARCH_INDEX_DATA_LOADED";
export const setSearchIndexDataLoaded = ( loaded = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_SET_SEARCH_INDEX_DATA_LOADED)

  return {
    type: SEARCH_SET_SEARCH_INDEX_DATA_LOADED,
    loaded
  };
};


export const SEARCH_INITIAL_RESULTS = "SEARCH_INITIAL_RESULTS";
export const setIsSearchInitialResultsResults = ( initialResults = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_INITIAL_RESULTS)

  return {
    type: SEARCH_INITIAL_RESULTS,
    initialResults
  };
};


export const SEARCH_NO_RESULTS = "SEARCH_NO_RESULTS";
export const setIsSearchNoResults = ( noResults = false ) => {
  // console.log('index action: setIsMapView');
  console.log('anaction  : ', SEARCH_NO_RESULTS)

  return {
    type: SEARCH_NO_RESULTS,
    noResults
  };
};


