import React from "react";
import styled from '@emotion/styled'

export default function Social(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="60" 
      height="60" 
      viewBox="0 0 60 60"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <rect width="50" height="50" x="5" y="5" className={active? 'hov mi-bg-active' : 'hov mi-bg-inactive'} />
        <path fillRule="nonzero" d="M30,17 C26.1340068,17 23,20.1340068 23,24 C23,27.8659932 26.1340068,31 30,31 C33.8659932,31 37,27.8659932 37,24 C37,20.1340068 33.8659932,17 30,17 Z" className={active? 'hov mi-hd-active' : 'hov mi-hd-inactive'} />
        <path fillRule="nonzero" d="M15.5812382,24.1862665 L16.3312936,24.7284705 C22.7922305,29.4582241 24.9212036,32.6484586 24.9978387,37.7113957 L25,38 L25,46 C25,46.5522847 24.5522847,47 24,47 C23.4871642,47 23.0644928,46.6139598 23.0067277,46.1166211 L23,46 L23,38 C23,33.334561 21.1204278,30.6006377 14.4187618,25.8137335 C13.9693492,25.4927245 13.8652575,24.8681744 14.1862665,24.4187618 C14.5072755,23.9693492 15.1318256,23.8652575 15.5812382,24.1862665 Z M44.4186841,24.1862665 C44.8680967,23.8652575 45.4926468,23.9693492 45.8136558,24.4187618 C46.1346648,24.8681744 46.0305731,25.4927245 45.5811605,25.8137335 C39.0035995,30.5119914 37.0711898,33.2326005 37.0018747,37.7427708 L36.9999223,38 L36.9999223,46 L36.9931946,46.1166211 C36.9354295,46.6139598 36.5127582,47 35.9999223,47 C35.4870865,47 35.0644152,46.6139598 35.0066501,46.1166211 L34.9999223,46 L34.9999223,38 L35.0020836,37.7113957 C35.077245,32.7458228 37.1265672,29.581618 43.3006723,24.9996571 L43.6686287,24.7284705 L44.4186841,24.1862665 Z" className={active? 'hov mi-lg-active' : 'hov mi-lg-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .hov{
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-property: fill, transform;
    transition-duration: 500ms;
  }
  .mi-bg-active{
    fill: #57C18B;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-hd-active{
    fill: #ffffff;
  }
  .mi-hd-inactive{
    fill: #E9D8CF;
  }
  .mi-lg-active{
    fill: #000000;
  }
  .mi-lg-inactive{
    fill: #AE9C93;
  }
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  transition-property: fill, transform;
  transition-duration: 150ms;

  &:hover{
    transform: scale3d(1.2,1.2,1.2);
    .mi-bg-inactive{
      fill: #57C18B;
    }
    .mi-hd-inactive{
      fill:  #ffffff;
    }
    .mi-lg-inactive{
      fill:  #000000;
    }
  }
`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "resetActiveCategory" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/

/*
const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(50%)'};
  transition-timing-function: ${({ active }) => active ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ active }) => active ? '1250ms' : '1200ms'};
  transition-property: transform;
`
*/

