const GlobalStylesThemed = ( theme ) => (`
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;

  padding-left: 25px;
  padding-top: 33px;
  padding-bottom: 29px;
  padding-right: 25px;

  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  @media(min-width: 768px){
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  /* padding: 10px 20px; */
  padding-left: 20px;
}

.react-autosuggest__suggestion--highlighted {
  /* background-color: #ddd; */
  border-radius: 25px;
}

.react-autosuggest__suggestion-match {
  color: red;
  font-weight: bold;
}


  @font-face{
    font-family: Rockwell;
    src:url("/fonts/f60b9b8c-1fb9-43fc-a776-1ed969eec0b6.woff2") format("woff2"),url("/fonts/809557e9-7c0c-4021-8ec0-d11de2761d0f.woff") format("woff");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: Rockwell;
    src:url("/fonts/a96cf9d1-9a0e-455a-82ed-91ad7a84eea7.woff2") format("woff2"),url("/fonts/64a03e10-a3d3-4484-a566-ffaa4b860428.woff") format("woff");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: SofiaW01;
    src:url("/fonts/8b31e0d7-5f53-45c2-b318-064f0b532543.woff2") format("woff2"),url("/fonts/476fe015-ab32-40a2-8fed-0a5af9a3bba4.woff") format("woff");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: SofiaW01;
    src:url("/fonts/80042803-0630-405f-b2d2-af97b4e50b46.woff2") format("woff2"),url("/fonts/ec41d681-0313-4b04-88c0-820aebb51f4e.woff") format("woff");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  html, body {
    font-family:"Rockwell W01 Bold";
    margin: 0;
    padding: 0;
  }

  #gatsby-focus-wrapper{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    background: ${theme.mi_background_real};
    color: ${theme.mi_black};
    text-rendering: optimizeLegibility;
    padding-top: 80px;

		&.mi-home{
			background: #9ac9df;
		}
  }

  a {
    color: ${theme.mi_black};
    text-decoration: underline;

  }


  p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: SofiaW01;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: ${theme.mi_black};
  }
  @media(min-width: 769px){
    body{
      padding-top: 96px;
    }
    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      max-width: 750px;
      margin: 0 auto;
    }
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom{
    right: 50px !important;
  }

.misingle{
  background: ${theme.mi_white};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(0);
  pointer-events: none;
  overflow-y: scroll;
	transition-delay: 100ms;
	// opacity: 0.5;
	opacity: 1;
	transform: translate3d(-100%, 0, 0);
}
.misingle-direct{
	transform: translate3d(0%, 0, 0);
	opacity: 1;
	pointer-events: all;
}

.misingle-enter {
  transform: translate3d(-100%,0,0);
}
.misingle-enter-active {
  transform:translate3d(0,0,0);
  transition: transform 300ms, opacity 100ms;
  transition-delay: 400ms;
}
.misingle-exit {
  transform:translate3d(0,0,0);
}
.misingle-exit-active {
  transform:translate3d(-100%,0,0);
  transition: transform 500ms, opacity 800ms;
  transition-delay: 10ms;
}

.misingle-enter-done{
	pointer-events: all;
	opacity: 1;
	transform:translate3d(0,0,0);
}

@media(min-width:768px){
  .misingle{
		max-width: 50vw;
		border-top: 2px solid white;
  }
  .misingle-enter-active {
    transition: transform 800ms, opacity 300ms;
    transition-delay: 150ms;
  }
  .misingle-exit-active {
    transition: transform 200ms, opacity: 100ms;
    transition-delay: 100ms;
  }
}

.mi-tooltip{
    width: auto !important;
    height: 32px !important;
    background: ${theme.mi_black} !important;
    border-radius: 4px !important;

    color: ${theme.mi_white} !important;
    font-family: SofiaW01 !important;
    font-size: 13px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: 0.9px !important;
    text-align: center !important;

    display: flex !important;
    align-self: center !important;
    justify-content: center !important;

    padding: 5px 8px 9px 11px !important;
    margin-top: 23px !important;

    &.place-bottom {

      &:after {
        border: none !important;
      }
      &:before{
        border: none !important;
      }
    }


}





.fiba{
  position: absolute;
  left: 0;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  transition-delay: 200ms;
  top: 31px;
  width: 100%;
}
@media(min-width: 768px){
  .fiba{
    top: 20px;
    width: auto;
    z-index: 7; 
  }
}

.fiba-enter {
  opacity: 0;

}
.fiba-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fiba-exit {
  opacity: 1;
}
.fiba-exit-active {
  opacity: 0;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.0, 0, 1, 0.9);
  transition: opacity 500ms;
}

.seba{
  position: absolute;
  left: 0;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  transition-delay: 200ms;
  top: 31px;
  width: 100%;
}
@media(min-width: 768px){
  .seba{
    top: 20px;
    width: auto;
    z-index: 7; 
  }
}

.seba-enter {
  opacity: 0;

}
.seba-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.seba-exit {
  opacity: 1;
}
.seba-exit-active {
  opacity: 0;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.0, 0, 1, 0.9);
  transition: opacity 500ms;
}

.babu{
  position: absolute;
  left: 5px;
  top: 25px;
  transition-delay: 0ms;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.babu-enter {
  opacity: 0;
  transition-delay: 0ms;
}
.babu-enter-active {
  opacity: 1;
  transition-delay: 0ms;
  transition: opacity 500ms;
}
.babu-exit {
  opacity: 1;
  transition-delay: 0ms;
}
.babu-exit-active {
  opacity: 0;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  transition: opacity 500ms;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}



.button__bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 22px;
  display: flex !important;
  justify-content: center;
  padding: 0;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 7px;
  padding: 0;
  display: flex;
  align-items: center;
}

.button__bar li button {
  border: none;
  background: #fff;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  padding: 0;
}

.button__bar li.slick-active button {
  background-color: #fff;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.button__bar li button:hover,
.button__bar li button:focus {
  background: #fff;
  outline: 0;
}


@media(min-width: 768px){
  .button__bar{
    display: none !important;
    pointer-events: none;
  }
}


.tippy-touch {
	cursor: pointer!important
}

.tippy-notransition {
	transition: none!important
}

.tippy-popper {
	max-width: 400px;
	-webkit-perspective: 800px;
	perspective: 800px;
	z-index: 9999;
	outline: 0;
	transition-timing-function: cubic-bezier(.165, .84, .44, 1);
	pointer-events: none
}

.tippy-popper.html-template {
	max-width: 96%;
	max-width: calc(100% - 20px)
}

.tippy-popper[x-placement^=top] [x-arrow] {
	border-top: 7px solid #333;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	bottom: -7px;
	margin: 0 9px
}

.tippy-popper[x-placement^=top] [x-arrow].arrow-small {
	border-top: 5px solid #333;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	bottom: -5px
}

.tippy-popper[x-placement^=top] [x-arrow].arrow-big {
	border-top: 10px solid #333;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	bottom: -10px
}

.tippy-popper[x-placement^=top] [x-circle] {
	-webkit-transform-origin: 0 33%;
	transform-origin: 0 33%
}

.tippy-popper[x-placement^=top] [x-circle].enter {
	-webkit-transform: scale(1) translate(-50%, -55%);
	transform: scale(1) translate(-50%, -55%);
	opacity: 1
}

.tippy-popper[x-placement^=top] [x-circle].leave {
	-webkit-transform: scale(.15) translate(-50%, -50%);
	transform: scale(.15) translate(-50%, -50%);
	opacity: 0
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme [x-circle] {
	background-color: #fff
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme [x-arrow] {
	border-top: 7px solid #fff;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme [x-arrow].arrow-small {
	border-top: 5px solid #fff;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme [x-arrow].arrow-big {
	border-top: 10px solid #fff;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent
}

.tippy-popper[x-placement^=top] .tippy-tooltip.transparent-theme [x-circle] {
	background-color: rgba(0, 0, 0, .7)
}

.tippy-popper[x-placement^=top] .tippy-tooltip.transparent-theme [x-arrow] {
	border-top: 7px solid rgba(0, 0, 0, .7);
	border-right: 7px solid transparent;
	border-left: 7px solid transparent
}

.tippy-popper[x-placement^=top] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
	border-top: 5px solid rgba(0, 0, 0, .7);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent
}

.tippy-popper[x-placement^=top] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
	border-top: 10px solid rgba(0, 0, 0, .7);
	border-right: 10px solid transparent;
	border-left: 10px solid transparent
}

.tippy-popper[x-placement^=top] [data-animation=perspective] {
	-webkit-transform-origin: bottom;
	transform-origin: bottom
}

.tippy-popper[x-placement^=top] [data-animation=perspective].enter {
	opacity: 1;
	-webkit-transform: translateY(-10px) rotateX(0);
	transform: translateY(-10px) rotateX(0)
}

.tippy-popper[x-placement^=top] [data-animation=perspective].leave {
	opacity: 0;
	-webkit-transform: translateY(0) rotateX(90deg);
	transform: translateY(0) rotateX(90deg)
}

.tippy-popper[x-placement^=top] [data-animation=fade].enter {
	opacity: 1;
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px)
}

.tippy-popper[x-placement^=top] [data-animation=fade].leave {
	opacity: 0;
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px)
}

.tippy-popper[x-placement^=top] [data-animation=shift].enter {
	opacity: 1;
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px)
}

.tippy-popper[x-placement^=top] [data-animation=shift].leave {
	opacity: 0;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.tippy-popper[x-placement^=top] [data-animation=scale].enter {
	opacity: 1;
	-webkit-transform: translateY(-10px) scale(1);
	transform: translateY(-10px) scale(1)
}

.tippy-popper[x-placement^=top] [data-animation=scale].leave {
	opacity: 0;
	-webkit-transform: translateY(0) scale(0);
	transform: translateY(0) scale(0)
}

.tippy-popper[x-placement^=bottom] [x-arrow] {
	border-bottom: 7px solid #333;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	top: -7px;
	margin: 0 9px
}

.tippy-popper[x-placement^=bottom] [x-arrow].arrow-small {
	border-bottom: 5px solid #333;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	top: -5px
}

.tippy-popper[x-placement^=bottom] [x-arrow].arrow-big {
	border-bottom: 10px solid #333;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	top: -10px
}

.tippy-popper[x-placement^=bottom] [x-circle] {
	-webkit-transform-origin: 0 -50%;
	transform-origin: 0 -50%
}

.tippy-popper[x-placement^=bottom] [x-circle].enter {
	-webkit-transform: scale(1) translate(-50%, -45%);
	transform: scale(1) translate(-50%, -45%);
	opacity: 1
}

.tippy-popper[x-placement^=bottom] [x-circle].leave {
	-webkit-transform: scale(.15) translate(-50%, -5%);
	transform: scale(.15) translate(-50%, -5%);
	opacity: 0
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-circle] {
	background-color: #fff
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-arrow] {
	border-bottom: 7px solid #fff;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-arrow].arrow-small {
	border-bottom: 5px solid #fff;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-arrow].arrow-big {
	border-bottom: 10px solid #fff;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.transparent-theme [x-circle] {
	background-color: rgba(0, 0, 0, .7)
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.transparent-theme [x-arrow] {
	border-bottom: 7px solid rgba(0, 0, 0, .7);
	border-right: 7px solid transparent;
	border-left: 7px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
	border-bottom: 5px solid rgba(0, 0, 0, .7);
	border-right: 5px solid transparent;
	border-left: 5px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
	border-bottom: 10px solid rgba(0, 0, 0, .7);
	border-right: 10px solid transparent;
	border-left: 10px solid transparent
}

.tippy-popper[x-placement^=bottom] [data-animation=perspective] {
	-webkit-transform-origin: top;
	transform-origin: top
}

.tippy-popper[x-placement^=bottom] [data-animation=perspective].enter {
	opacity: 1;
	-webkit-transform: translateY(10px) rotateX(0);
	transform: translateY(10px) rotateX(0)
}

.tippy-popper[x-placement^=bottom] [data-animation=perspective].leave {
	opacity: 0;
	-webkit-transform: translateY(0) rotateX(-90deg);
	transform: translateY(0) rotateX(-90deg)
}

.tippy-popper[x-placement^=bottom] [data-animation=fade].enter {
	opacity: 1;
	-webkit-transform: translateY(10px);
	transform: translateY(10px)
}

.tippy-popper[x-placement^=bottom] [data-animation=fade].leave {
	opacity: 0;
	-webkit-transform: translateY(10px);
	transform: translateY(10px)
}

.tippy-popper[x-placement^=bottom] [data-animation=shift].enter {
	opacity: 1;
	-webkit-transform: translateY(10px);
	transform: translateY(10px)
}

.tippy-popper[x-placement^=bottom] [data-animation=shift].leave {
	opacity: 0;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.tippy-popper[x-placement^=bottom] [data-animation=scale].enter {
	opacity: 1;
	-webkit-transform: translateY(10px) scale(1);
	transform: translateY(10px) scale(1)
}

.tippy-popper[x-placement^=bottom] [data-animation=scale].leave {
	opacity: 0;
	-webkit-transform: translateY(0) scale(0);
	transform: translateY(0) scale(0)
}

.tippy-popper[x-placement^=left] [x-arrow] {
	border-left: 7px solid #333;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	right: -7px;
	margin: 6px 0
}

.tippy-popper[x-placement^=left] [x-arrow].arrow-small {
	border-left: 5px solid #333;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	right: -5px
}

.tippy-popper[x-placement^=left] [x-arrow].arrow-big {
	border-left: 10px solid #333;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	right: -10px
}

.tippy-popper[x-placement^=left] [x-circle] {
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0
}

.tippy-popper[x-placement^=left] [x-circle].enter {
	-webkit-transform: scale(1) translate(-50%, -50%);
	transform: scale(1) translate(-50%, -50%);
	opacity: 1
}

.tippy-popper[x-placement^=left] [x-circle].leave {
	-webkit-transform: scale(.15) translate(-50%, -50%);
	transform: scale(.15) translate(-50%, -50%);
	opacity: 0
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme [x-circle] {
	background-color: #fff
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme [x-arrow] {
	border-left: 7px solid #fff;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme [x-arrow].arrow-small {
	border-left: 5px solid #fff;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme [x-arrow].arrow-big {
	border-left: 10px solid #fff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.transparent-theme [x-circle] {
	background-color: rgba(0, 0, 0, .7)
}

.tippy-popper[x-placement^=left] .tippy-tooltip.transparent-theme [x-arrow] {
	border-left: 7px solid rgba(0, 0, 0, .7);
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
	border-left: 5px solid rgba(0, 0, 0, .7);
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
	border-left: 10px solid rgba(0, 0, 0, .7);
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent
}

.tippy-popper[x-placement^=left] [data-animation=perspective] {
	-webkit-transform-origin: right;
	transform-origin: right
}

.tippy-popper[x-placement^=left] [data-animation=perspective].enter {
	opacity: 1;
	-webkit-transform: translateX(-10px) rotateY(0);
	transform: translateX(-10px) rotateY(0)
}

.tippy-popper[x-placement^=left] [data-animation=perspective].leave {
	opacity: 0;
	-webkit-transform: translateX(0) rotateY(-90deg);
	transform: translateX(0) rotateY(-90deg)
}

.tippy-popper[x-placement^=left] [data-animation=fade].enter {
	opacity: 1;
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px)
}

.tippy-popper[x-placement^=left] [data-animation=fade].leave {
	opacity: 0;
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px)
}

.tippy-popper[x-placement^=left] [data-animation=shift].enter {
	opacity: 1;
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px)
}

.tippy-popper[x-placement^=left] [data-animation=shift].leave {
	opacity: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.tippy-popper[x-placement^=left] [data-animation=scale].enter {
	opacity: 1;
	-webkit-transform: translateX(-10px) scale(1);
	transform: translateX(-10px) scale(1)
}

.tippy-popper[x-placement^=left] [data-animation=scale].leave {
	opacity: 0;
	-webkit-transform: translateX(0) scale(0);
	transform: translateX(0) scale(0)
}

.tippy-popper[x-placement^=right] [x-arrow] {
	border-right: 7px solid #333;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	left: -7px;
	margin: 6px 0
}

.tippy-popper[x-placement^=right] [x-arrow].arrow-small {
	border-right: 5px solid #333;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	left: -5px
}

.tippy-popper[x-placement^=right] [x-arrow].arrow-big {
	border-right: 10px solid #333;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	left: -10px
}

.tippy-popper[x-placement^=right] [x-circle] {
	-webkit-transform-origin: -50% 0;
	transform-origin: -50% 0
}

.tippy-popper[x-placement^=right] [x-circle].enter {
	-webkit-transform: scale(1) translate(-50%, -50%);
	transform: scale(1) translate(-50%, -50%);
	opacity: 1
}

.tippy-popper[x-placement^=right] [x-circle].leave {
	-webkit-transform: scale(.15) translate(-50%, -50%);
	transform: scale(.15) translate(-50%, -50%);
	opacity: 0
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme [x-circle] {
	background-color: #fff
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme [x-arrow] {
	border-right: 7px solid #fff;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme [x-arrow].arrow-small {
	border-right: 5px solid #fff;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme [x-arrow].arrow-big {
	border-right: 10px solid #fff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.transparent-theme [x-circle] {
	background-color: rgba(0, 0, 0, .7)
}

.tippy-popper[x-placement^=right] .tippy-tooltip.transparent-theme [x-arrow] {
	border-right: 7px solid rgba(0, 0, 0, .7);
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.transparent-theme [x-arrow].arrow-small {
	border-right: 5px solid rgba(0, 0, 0, .7);
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.transparent-theme [x-arrow].arrow-big {
	border-right: 10px solid rgba(0, 0, 0, .7);
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent
}

.tippy-popper[x-placement^=right] [data-animation=perspective] {
	-webkit-transform-origin: left;
	transform-origin: left
}

.tippy-popper[x-placement^=right] [data-animation=perspective].enter {
	opacity: 1;
	-webkit-transform: translateX(10px) rotateY(0);
	transform: translateX(10px) rotateY(0)
}

.tippy-popper[x-placement^=right] [data-animation=perspective].leave {
	opacity: 0;
	-webkit-transform: translateX(0) rotateY(90deg);
	transform: translateX(0) rotateY(90deg)
}

.tippy-popper[x-placement^=right] [data-animation=fade].enter {
	opacity: 1;
	-webkit-transform: translateX(10px);
	transform: translateX(10px)
}

.tippy-popper[x-placement^=right] [data-animation=fade].leave {
	opacity: 0;
	-webkit-transform: translateX(10px);
	transform: translateX(10px)
}

.tippy-popper[x-placement^=right] [data-animation=shift].enter {
	opacity: 1;
	-webkit-transform: translateX(10px);
	transform: translateX(10px)
}

.tippy-popper[x-placement^=right] [data-animation=shift].leave {
	opacity: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0)
}

.tippy-popper[x-placement^=right] [data-animation=scale].enter {
	opacity: 1;
	-webkit-transform: translateX(10px) scale(1);
	transform: translateX(10px) scale(1)
}

.tippy-popper[x-placement^=right] [data-animation=scale].leave {
	opacity: 0;
	-webkit-transform: translateX(0) scale(0);
	transform: translateX(0) scale(0)
}

.tippy-popper .tippy-tooltip.transparent-theme {
	background-color: rgba(0, 0, 0, .7)
}

.tippy-popper .tippy-tooltip.transparent-theme[data-animatefill] {
	background-color: transparent
}

.tippy-popper .tippy-tooltip.light-theme {
	color: #26323d;
	box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
	background-color: #fff
}

.tippy-popper .tippy-tooltip.light-theme[data-animatefill] {
	background-color: transparent
}

.tippy-tooltip {
	display: none;
}
@media(min-width: 768px){


.tippy-tooltip {

	position: relative;
	// color: #fff;
	// border-radius: 4px;
	// font-size: .95rem;
	// padding: .4rem .8rem;
	text-align: center;
	will-change: transform;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
  // background-color: #333
  

  width: auto !important;
  height: 32px !important;
  background-color: ${theme.mi_black};
  border-radius: 4px !important;

  color: ${theme.mi_white} !important;
  font-family: SofiaW01 !important;
  font-size: 13px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.9px !important;
  text-align: center !important;

  display: flex !important;
  align-self: center !important;
  justify-content: center !important;

  padding: 5px 8px 9px 11px !important;
  margin-top: 11px !important;
  transition: all 500ms;
}

}

.tippy-tooltip[data-animatefill] {
	overflow: hidden;
	background-color: ${theme.mi_black} 
}

@media (max-width:450px) {
	.tippy-popper {
		max-width: 96%;
		max-width: calc(100% - 20px)
	}
}


@keyframes tooltipFadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
`)

export default GlobalStylesThemed
