/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { globalHistory } from "@reach/router"

import { updateRoute, setIsMapView, setIsCategoryView } from './src/state/actions'
import store from './src/state/createStore';
import wrapWithProvider from "./wrap-with-provider"

const wrapRootElement = wrapWithProvider;

// globalHistory.listen(({ action, location }) => {
//   if (action !== 'PUSH') {
//     console.log('\n\n\n action !== PUSH')
//     console.log(JSON.stringify(location, null, 4))
//     store.dispatch(updateRoute(location))
//     const firstPathSegment = location.pathname.split('/')[1]
//     console.log('firstPathSegment', firstPathSegment)
//     if( firstPathSegment && firstPathSegment === 'erkunden') {
//       store.dispatch(setIsMapView(true))
//       store.dispatch( setIsCategoryView(true))
//     }
//     if( !firstPathSegment ){
//       store.dispatch( setIsMapView(false))
//       store.dispatch( setIsCategoryView(false))
//     }
//   } else {
//     console.log('action ?== PUSH')
//   }
// })

globalHistory.listen(({ action, location }) => {
  if (action !== 'PUSH') {
    console.log('\n\n\n action !== PUSH')
    console.log(JSON.stringify(location, null, 4))
    // store.dispatch(updateRoute(location))
    // const firstPathSegment = location.pathname.split('/')[1]
    // console.log('firstPathSegment', firstPathSegment)
    // if( firstPathSegment && firstPathSegment === 'erkunden') {
    //   store.dispatch(setIsMapView(true))
    //   store.dispatch( setIsCategoryView(true))
    // }
    // if( !firstPathSegment ){
    //   store.dispatch( setIsMapView(false))
    //   store.dispatch( setIsCategoryView(false))
    // }
  } else {
    console.log('action ?== PUSH')
   
    const firstPathSegment = location.pathname.split('/')[1]
    console.log('firstPathSegment', firstPathSegment)
    if( firstPathSegment && firstPathSegment === 'erkunden') {
      // store.dispatch(setIsMapView(true))
      // store.dispatch( setIsCategoryView(true))
      store.dispatch(updateRoute(location))
    }
  }
})



function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty('--vh', `${window.innerHeight*.01  }px`);
}

window.addEventListener('resize', appHeight);
appHeight();

export { wrapRootElement }

export const shouldUpdateScroll = () => { window.scrollTo(0, 0); return false }