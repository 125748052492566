/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { css, ClassNames, jsx } from "@emotion/core";
import styled from '@emotion/styled'
import { globalHistory } from "@reach/router"
import { Link as GatsbyLink  , useStaticQuery, graphql } from 'gatsby'

import { connect } from "react-redux";
// import slugify from '../../utils/slugify'
import {
  Tooltip,
} from 'react-tippy';
// import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';
import { resetActiveCategory, setIsMapView, setIsCategoryView, setActiveParticipant, activateParticipant, setActivatedParticipantOffsetX, updateRoute, setInitializedSlider, initParticipantCategory, setIsSearchResultsView } from '../../state/actions'
import Link from '../Link'
import All from './FilterButtons/All'
import ArtsAndCrafts from './FilterButtons/ArtsAndCrafts'
import Back from './FilterButtons/Back'
import Business from './FilterButtons/Business'
import Gastronomy from './FilterButtons/Gastronomy'
import Social from './FilterButtons/Social'

const FilterCategories = (props) => {


  // console.log('>>>>FilterCategories props: ', props);

  const datas = useStaticQuery(graphql`
  query {
    allWpParticipantCategory {
        nodes {
          id
          name
          databaseId
          uri
          nodeType
        }
    }
    allWpTaxonomy( filter: { name: { eq: "moabit_participant_category" }} ) 
    {
      nodes {
        archivePath
        name
        nodeType
        restBase
      }
    }
  }
`)

  const data = [...datas.allWpTaxonomy.nodes, ...datas.allWpParticipantCategory.nodes ]


  const { 
    filter_category_id, 
    filter_category_name, 
    resetActiveCategory ,
    isMaView,
    isCaView,
    setIsMapView,
    setIsCategoryView,
    setActiveParticipant, activateParticipant, participants, setActivatedParticipantOffsetX, updateRoute, lastCategoryRoute, 
    activeCategorySlug, isPhone, setInitializedSlider,

    initialCategoryId, initialCategoryName, initParticipantCategory,
    isStateInitialized,
    isSearchView,
    setIsSearchResultsView,
    isSearchResultsView,
    resultCount,
    autoCompleteNoResults
  } = props

  const isSingle = isMaView && !isCaView;



  const [filterInProp, setFilterInProp] = useState(isCaView || isPhone);
  // const [searchInProp, setSearchInProp] = useState(isSearchView);
  const [backInProp, setBackInProp] = useState(isMaView && !isCaView && !isPhone);
  // const filterInProp = false;

  // setFilterInProp()
  // setBackInProp()
  // const initialFilterInProp = ;
  // const initialBackInProp = !filterInProp;

  useEffect(()=>{
    console.log('ANOW FilterCategoriesMobile useEffect')
    console.log('ANOW useEffect isCaView', isCaView)
    const isSingleView = isMaView && !isCaView;

    // single phone:
    // filter : true
    // back : false

    // category phone:
    // filter: true
    // back: false


    // else phone:
    // filter: false
    // back: false


    // single desktop:
    // filter: false
    // back: true


    // category desktiop:
    // filter: true
    // back false

    // else desktop:
    // filter: false
    // back: false

    if (isSearchView && !isPhone) {
      setFilterInProp(false)
      setBackInProp(false)
    } else if (isSearchView && isPhone){
      setFilterInProp(true)
      setBackInProp(false)
    } else if(isCaView) {
      setFilterInProp(true)
      setBackInProp(false)
      console.log('ANOW isCaView -> filter true, back false')
    } else if(isSingleView && isPhone){
      setFilterInProp(true)
      setBackInProp(false)
      console.log('ANOW isSingleView && isPhone -> filter true, back false')

    } else if(isSingleView && !isPhone){
      setFilterInProp(false)
      setBackInProp(true)
      console.log('ANOW isSingleView && !isPhone -> filter false, back true')
    } else {
      setFilterInProp(false)
      setBackInProp(false)
      console.log('ANOW else -> filter false, back false')


    }
    // if(isSingleView && !isPhone){ // single
    //   console.log('ANOW setFilterInProp(true)')
    //   console.log('ANOW setBackInProp(false)')
    //   setFilterInProp(false)
    //   setBackInProp(true)

    //   // setBackInProp(true)
    // } else if(isCaView && !isPhone) {
    //   console.log('ANOW setFilterInProp(false)')
    //   console.log('ANOW setBackInProp(true)')

    //   setFilterInProp(false)
    //   setBackInProp(false)
    //   // setBackInProp(false)
    // }
  }, [isCaView, isMaView, isPhone, isSearchView])


  // console.log('FILTERBAR: isSINGLE: ', isSingle);

  // console.log('SETIS_MAPIFJDLS: ', setIsMapView)
  const triggerAction = (cat) => {
    // setIsMapView(true)
    // setIsCategoryView(true)
    // const newLocation = {
    //   pathname: cat? cat.uri : '/erkunden/'
    // }
    // updateRoute(newLocation)

    if(cat){
      // console.log('triggerAction FilterCat: cat is there', cat )
      // console.log('participants: ', participants)
      // console.log('cat.databaseId: ', cat.databaseId)

      // console.log('Filter categories, cat: ', cat)
      // resetActiveCategory(cat.databaseId, cat.name, cat.uri)
      const firstOfCat = participants.find(p => p.categoryId === cat.databaseId)
      const firstOfCatId = firstOfCat? firstOfCat.id : null;


      initParticipantCategory(cat.databaseId, cat.name );
      console.log('FFF cat: ', cat);
      console.log('FFF participants: ', participants);
      console.log('FFF firstOfCatId: ', firstOfCatId);
      if(firstOfCatId){
        setActiveParticipant(firstOfCatId)
        activateParticipant(firstOfCatId)
        setActivatedParticipantOffsetX(24);
      }
    } else {
      // console.log('triggerAction FilterCat: cat is not there', cat )
      const firstId = participants[0].id
      console.log('FFF cat: ', cat);
      console.log('FFF participants: ', participants);
      console.log('FFF firstOfCatId: ', firstId);
      // console.log('triggerAction FilterCat: cat is not there firstParticipantId', firstId )

      setActiveParticipant(firstId)
      activateParticipant(firstId)
      setActivatedParticipantOffsetX(24);

      resetActiveCategory(null)
      initParticipantCategory(null, null)
    }
    setIsSearchResultsView(false)
  }

  // const toIgnore = [
  //   'Weekly Rundown',
  //   'Design',
  //   'Inspiration',
  //   'Uncategorised',
  //   'UX',
  // ]

  const Wrapper = styled.section`
  width: 100vw;
  height: 60px;
  height: 88px;
  border-top: solid 2px ${({ theme }) => theme.mi_white};
  border-bottom: solid 2px ${({ theme }) => theme.mi_white};
  background-color: ${({ theme }) => theme.mi_background_real};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1 0 100vw;
  order: 2;
  z-index: 8;
  flex-wrap: wrap;
  @media(min-width: 768px){
    order: 3;

    /* display: none !important; */
    /*width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);*/
    position: fixed;
    top: 0;
    left: 42px;
    width: auto;
    z-index: 16;
    display: flex;
    align-items: center;
    height: 96px;
    box-shadow: none;
    padding-left: 0;

    border: none;
  }
`;



  const FilterBar = styled.ul`
  flex: 1 0 100vw;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  position: relative;
  top: -2px;

  @media(min-width: 768px){
    flex: 1 0 auto;
    top: initial;
    padding: 0 24px 0 0;
  }
`;

  const FilterItem = styled.div`
  // width: 48px;
  // height: 48px;

  // margin-right: 15px;
  // overflow: hidden;
  // &:last-of-type{
  //   margin-right: 0;
  // }

  // &.active{

  // }
  // a {
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  // }
  // @media(min-width: 768px){
  //   height: 60px;
  //   width: 60px;
  // }
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

  const bigStyle1 =`
width: 48px;
height: 48px;

margin-right: 15px;
overflow: hidden;
&:last-of-type{
  margin-right: 0;
}

&.active{

}
a {
  display: block;
  width: 100%;
  height: 100%;
}
@media(min-width: 768px){
  height: 60px;
  width: 60px;
}
`

  const disabledStyle =`
width: 48px;
height: 48px;
background: green;

margin-right: 15px;
overflow: hidden;
&:last-of-type{
  margin-right: 0;
}

&.active{

}
a {
  display: block;
  width: 100%;
  height: 100%;
}
@media(min-width: 768px){
  height: 60px;
  width: 60px;
}
`

  const BackButton = styled(GatsbyLink)`
    display: block;
    width: 50px;
    height: 50px;

    margin-right: 15px;
    // overflow: hidden;
    &:last-of-type{
      margin-right: 0;
    }
    @media(min-width: 768px){
      position: relative;
      left: -75px;
    }
`;

  const FilterBarCurrentLabel = styled.div`
flex: 1 0 100vw;
width: 100vw;
height: 24px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
border: solid 2px  ${({ theme }) => theme.mi_white};
background-color:  ${({ theme }) => theme.mi_white};

font-family: SofiaW01;
font-size: 12px;
font-weight: bold;
line-height: 1.88;
letter-spacing: 0.5px;
margin-bottom: auto;
padding-left: 30px;
display: flex;
align-items: center;
@media(min-width: 768px){
  display: none;
}

`
  // from https://github.com/emotion-js/emotion/issues/1196
  // and from https://codesandbox.io/s/emotion-z8jpe?file=/index.js:264-308
  const styles = `
  width: 48px;
  height: 48px;

  margin-right: 15px;
  overflow: hidden;
  &:last-of-type{
    margin-right: 0;
  }

  &.active{

  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media(min-width: 768px){
    height: 60px;
    width: 60px;
  }
  position: relative;
  `;
  const disabled = false; // not actually necessary

  const handleBackButtonClick = e => {
    console.log('####handle back button click')
    // setIsMapView(true);
    // setIsCategoryView(true);
    setInitializedSlider(false)

  }
  const backButtonTarget = (lastCategoryRoute, activeCategorySlug, isSearchResultsView) => {
    if(isSearchResultsView) {
      return '/suchen'
    }
    if(lastCategoryRoute){
      return lastCategoryRoute
    }
    if(activeCategorySlug){
      return activeCategorySlug
    }
    return '/erkunden/'
  }

  return (
    <div
      style={
        {
          // width: '100vw',
          // height: '100%',
          // zIndex: '8',
          // left: '0',
          // overflowY: 'scroll',
          // position: 'absolute',
          // pointerEvents: 'none'
        }
      }
      css={css`
      width: 100vw;
      height: 60px;
      height: 88px;
      border-top: solid 2px #fff;
      border-bottom: solid 2px #fff;
      background-color: #f2ebe7;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      flex: 1 0 100vw;
      order: 2;
      z-index: 8;
      flex-wrap: wrap;
      position: relative;
      // transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
      // transition-property: transform;
      // transition-duration: 150ms;

      @media(min-width: 768px){
        order: 3;
    
        /* display: none !important; */
        /*width: 50vw;
        height: calc(100vh - 95px);
        height: calc(var(--vh, 1vh) * 100 - 95px);*/
        position: fixed;
        top: 0;
        left: 42px;
        width: auto;
        z-index: 16;
        display: flex;
        align-items: center;
        height: 96px;
        box-shadow: none;
        padding-left: 0;
    
        border: none;
        // transform: translateX(${isSearchView? '200px' : '0px'});
        transform: translateX(75px);
      }
      `}
    >
      <FilterBarCurrentLabel>
        {initialCategoryId && !isSearchView? initialCategoryName : (isSearchView || isSearchResultsView) && !autoCompleteNoResults ? `Suchergebnisse (${resultCount})` : (isSearchView || isSearchResultsView) ? 'Suchergebnisse' : 'Alle Kategorien'}
      </FilterBarCurrentLabel>
      <CSSTransition
        in={backInProp}
        timeout={300}
        classNames="babu"
        unmountOnExit
        key="fbabu"
      >
        <div className="babu">
          <BackButton onClick={handleBackButtonClick} title="Zur Kachelansicht" to={backButtonTarget(lastCategoryRoute, activeCategorySlug, isSearchResultsView)}>
            <Back /> 
          </BackButton>
        </div>

      </CSSTransition>

      <CSSTransition
        in={filterInProp}
        timeout={500}
        classNames="fiba"
        unmountOnExit
        key="ffiba"
      >
        <div className="fiba">
          <FilterBar>
            {data.map((cat) => {
              const [inHover, setHover] = useState(false);

              if (cat.nodeType === 'ParticipantCategory') {
                const filterCat = !isStateInitialized ? initialCategoryId : filter_category_id; // ||

                const isActive = filterCat === cat.databaseId && !isSearchResultsView;

                const id = cat.databaseId;
                // return (<div key={`kkkk${id}`}>dings</div>)
                return (
                  <ClassNames key={`cn-${cat.id}`}>   
                    {({ css }) => {
                      return (
                        <Tooltip
                          key={`tt-${cat.id}`}
                          title={cat.name}
                          position="bottom"
                          trigger="mouseenter"
                          tag="li"
                          className={css(styles)} 
                        >
          
                          <FilterItem 
                            key={cat.id}
                            // onMouseEnter={() => {
                            //   console.log('setHover to true')
                            //   setHover(true)}}
                            // onMouseLeave={() => {
                            //   console.log('setHover to false')
                            //   setHover(false)}}
                          >
                            <Link
                              to={cat.uri}
                              onClick={() => triggerAction(cat)}
                            >
                              {id === 9 ? <ArtsAndCrafts active={isActive}  /> : null }
                              {id === 7 ? <Social active={isActive}  /> : null }
                              {id === 5 ? <Business active={isActive}  /> : null }
                              {id === 8 ? <Gastronomy active={isActive}  /> : null }
                            </Link>
                            {/* <Link
                          key={`link_${cat.id}`}
                          to={cat.uri}
                          onClick={() => triggerAction(cat)}
                          activeClassName="active"
                        > */}
                            {/* {id === 9 ? <ArtsAndCrafts active={isActive}  /> : null } */}
                            {/* </Link> */}

                          </FilterItem>
                        </Tooltip>
                      )
                    }}
                  </ClassNames>
                )
              }
              if(cat.nodeType === 'Taxonomy') {
                const filterCat = !isStateInitialized ? initialCategoryId : filter_category_id; // ||

                // const isActive = filterCat === cat.databaseId;
                const isActive = !filterCat && !isSearchResultsView;
                // return (<div key="adfsjklasdjfö">dongs</div>)
                return (
                  <ClassNames key="cn-all">  
                    {({ css }) => {
                      return (
                        <Tooltip
                          key="tt-all"
                          title="Alle Kategorien"
                          position="bottom"
                          trigger="mouseenter"
                          tag="li"
                          className={css(styles)} 
                        >
                          <FilterItem 
                            className={isActive? 'active' : ''} 
                            key={cat.archivePath}
                            
                          >
                            <Link
                              key={`link_${cat.archivePath}`}
                              onClick={() => triggerAction(null)}
                              to={cat.archivePath}
                            >
                              <All active={isActive}  />
                            </Link>
                          </FilterItem>
                        </Tooltip>
                      )
                    }}
                  </ClassNames>
                )
              }
              return false
            })}
          </FilterBar>
        </div>
      </CSSTransition>

      {/* <ReactTooltip place="bottom" effect="solid" className="mi-tooltip" /> */}
    </div>
  )
}

// const FilterCategories = ( props ) => {
//   const { filter_category_id, filter_category_name, resetActiveCategory, setIsMapView, setIsCategoryView } = props;
//   // console.log('FilterCategories props', props)
//   return (
//     <StaticQuery
//       query={}

//     />
//   )}

const mapStateToProps = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    filter_category_id: state.participants.active_category.id,
    filter_category_name: state.participants.active_category.name,
    // isMaView: state.map.isMapView,
    // isCaView: state.view.isCategoryView,
    participants: state.participants.items,
    lastCategoryRoute: state.route.href,
    activeCategorySlug: state.participants.active_category.slug,
    isPhone: state.responsive.isPhone,
    isStateInitialized: state.isStateInitialized,
    isSearchResultsView: state.search.isSearchResultsView,
    resultCount: state.participants.filteredItems.length,
    autoCompleteNoResults: state.search.autocomplete.noResults,

  }
};


// export default FilterCategories
const mapDispatchToProps = {
  resetActiveCategory,
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant, 
  activateParticipant,
  setActivatedParticipantOffsetX,
  updateRoute,
  setInitializedSlider,
  initParticipantCategory,
  setIsSearchResultsView,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  // return { ...ownProps, todos: stateProps.todos[ownProps.userId],
  //   addTodo: text => dispatchProps.addTodo(ownProps.userId, text)}
  return { ...stateProps, ...ownProps, ...dispatchProps}
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // mergeProps
)(FilterCategories);