import React from 'react';
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
import { setIsMapView, setIsCategoryView } from '../../../state/actions'

const MapLink = (props) => {
  const { 
    isMapView,
    isCategoryView
    // setIsMapView,
    // setIsCategoryView
  } = props;
  return !isMapView? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledMapLink isMapView={isMapView} isCategoryView={isCategoryView} {...props}>
      <GatsbyLink 
        className='map_link'
        key="mi_header_map_link"
        to="/erkunden/"
        title="Zur Karte"
        // onClick={() => {
        //   setIsCategoryView(true)
        //   setIsMapView(true)}}
      >
        Zur Karte
        {' '}
      </GatsbyLink>
    </StyledMapLink>
  ) : null
}

const mapStateToProps = state => {
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};


const StyledMapLink = styled.div`
  display: ${({isMapView}) => isMapView ? 'none' : 'block' };
  margin-top: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .map_link{
    text-decoration: none;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    border-radius: 3.8px;
    background-color: ${({ theme }) => theme.mi_white };


    font-family: SofiaW01;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    text-align: center;
    color: ${({ theme }) => theme.mi_black};
  }
  @media(min-width: 768px){
    position: static;
    margin-left: 48px;
    .map_link{
      width: 163px;
      height: 50px;
      border-radius: 6px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
      transition-property: fill, transform;
      transition-duration: 150ms;
      &:hover{
        transform: scale3d(1.05,1.05,1.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      }
    }
    transform: none;

  }

  `

function mergeProps(stateProps, dispatchProps, ownProps) {
  // return { ...ownProps, todos: stateProps.todos[ownProps.userId],
  //   addTodo: text => dispatchProps.addTodo(ownProps.userId, text)}
  return { ...stateProps, ...ownProps, ...dispatchProps}
}

// export default connect(mapStateToProps, mapDispatchToProps, null)(MapLink)
export default MapLink
