import React, { useRef, useEffect } from "react";
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import Slider from "react-slick";

import { setSlideIndex, setInitializedSlider } from '../../../state/actions'
import FluidImage from "../SingleImage"
import IconNext from "./IconNext"
import IconPrev from "./IconPrev"

const SliderWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
`
const SliderContainer = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
// background: magenta;
`

const SliderItem = styled.div`

`
const ArrowOverlay = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  // background: rgba(255,255,0,0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: flex-end;
  display: none;
  @media(min-width: 768px){
    align-items: center;
    padding-bottom: 0;
    display: flex;
  }
`
const PrevButton = styled.button`

  width: 32px;
  height: 32px;

  pointer-events: all;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  margin-left: 15px;
  @media(min-width: 768px){
    margin-left: 25px;
    width: 50px;
    height: 50px;
  }
  cursor: pointer;


`
const NextButton = styled.button`

  width: 32px;
  height: 32px;
 
  pointer-events: all;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  margin-right: 15px;
  @media(min-width: 768px){
    margin-right: 25px;
    width: 50px;
    height: 50px;
  }
  cursor: pointer;
`

const ImageCarousel = (props) => {

  const { images, currentSlideIndex, setSlideIndex, setInitializedSlider,
    isMapView, isCategoryView, isSliderInitialized} = props;
  const hasImages = images && images.length;
  const moreThanOneImage = images && images.length > 1;

  const customSlider = useRef(null);
  const isSingleView =  isMapView && !isCategoryView;

  const handleNext = e => {
    e.preventDefault();
    if(customSlider && customSlider.current){
      customSlider.current.slickNext()
    }
  }

  const handlePrev = e => {
    e.preventDefault();
    if(customSlider && customSlider.current){
      customSlider.current.slickPrev()
    }
  }

  const handleChange = index => {
    setSlideIndex(index)
  }
  
  const renderArrows = (moreThanOne) => {
    return moreThanOne ? (
      <ArrowOverlay>
        <PrevButton onClick={handlePrev} title="Zum vorigen Bild">
          <IconPrev />
        </PrevButton>
        <NextButton onClick={handleNext} title="Zum nächsten Bild">
          <IconNext />
        </NextButton>
      </ArrowOverlay>
    ) : null
  };

  useEffect(() => {
    if(!isSliderInitialized && isSingleView){
      setInitializedSlider(true)
      customSlider.current.slickGoTo(0)
    } 
  }, [currentSlideIndex, isSingleView, isSliderInitialized])

  return (
    <SliderWrapper>
      <SliderContainer>
        {renderArrows(moreThanOneImage)}
        <Slider
          ref={customSlider}
          dots
          dotsClass="button__bar"
          arrows={false}
          slidesToShow={1}
          infinite
          afterChange={handleChange}
        >
          {hasImages? images.map(img => (
            <SliderItem key={img.databaseId} >
              <FluidImage image={img} style={{marginBottom: '96px', marginTop:'96px'}} />
            </SliderItem>
          )) : null }
        </Slider>
      </SliderContainer>
    </SliderWrapper>
  );
};

const mapDispatchToProps = {
  setSlideIndex,
  setInitializedSlider
};

const mapStateToProps = state => {
  return {
    currentSlideIndex : state.participants.current_slide_index,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    isSliderInitialized: state.participants.slider_initialized,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel)
