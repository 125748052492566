import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link as GatsbyLink  } from 'gatsby'
import logo from "../../images/logo-moabiterinsel.svg"

const LogoWallComplete = ({ data }) => {
  // console.log('LogoWallComplete data', data)
  return (
    <StyledLogoWallWrapper>
      <StyledLogoWall>
        {data.map((item) => { 
          // console.log('item.miSuppor', item)
          return(
            item.mi_supporter.miSupporterLogo && item.mi_supporter.miSupporterLink?  
              <a target='_blank' rel='noopener noreferrer' className="mi_logowall_link mi_logowall_is_linked" key={item.slug} href={item.mi_supporter.miSupporterLink}><img alt={item.mi_supporter.miSupporterLogo.altText} src={item.mi_supporter.miSupporterLogo.localFile.publicURL} /></a>
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              : <a className="mi_logowall_link" key={item.slug}><img alt={item.mi_supporter.miSupporterLogo.altText} src={item.mi_supporter.miSupporterLogo.localFile.publicURL} /></a>
          )}
        )}
      </StyledLogoWall>
    </StyledLogoWallWrapper>
  );
}

const StyledLogoWallWrapper = styled.div`
  display: flex;
  background-color:  ${({ theme }) => theme.mi_white};
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.mi_background_real};
  `

const StyledLogoWall = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 32px 25px 32px;
  width: 100%;
  max-width: 1140px;

  

  .mi_logowall_link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex: 0 1 33.33%;
    margin-bottom: 24px;
    &:nth-of-type(1){
      flex: 0 1 66%;
      img{
        // width: 400px;
        width: 100%;
        object-fit: contain;
      }
    }
    &:nth-of-type(3n){
      justify-content: flex-start;
    }
    &:nth-of-type(3n+1){
      justify-content: center;
    }
    &:nth-of-type(3n+2){
      justify-content: flex-end;
    }
  }
  @media(min-width: 768px){
    flex-wrap: none;
    padding: 48px 32px 44px 32px;
    .mi_logowall_link{
      flex: 0 1 16.66%;
      // transform: scale(1,1);
      opacity: 1;
      margin-bottom: 0;
      img{
        width: 90px;
      }
      &:nth-of-type(1){
        flex: 0 1 33%;
      }
      &:nth-of-type(3n+1){
        justify-content: center;
      }
      &:nth-of-type(3n+2){
        justify-content: center;
      }
      &:nth-of-type(3n+3){
        justify-content: center;
      }
      &:first-of-type{
        justify-content: flex-start;
      }
      &:last-of-type{
        justify-content: flex-end;
      }
      &.mi_logowall_is_linked {
        transition-duration: 200ms;
        // transform: scale(1,1); 
        transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
        transition-property: opacity;
        &:hover {
          // transform: scale(1.05,1.05); animating svg via css transform looks bad
          transition-duration: 250ms;
          transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
          opacity: 0.8;
        }
      }
    }
  }
  @media(min-width: 1140px){
    padding-left: 0;
    padding-right: 0;
  }
`

const LogoWall = ({ props }) => (
  <StaticQuery
    query={graphql`
      query {
        allWpSupporter {
          nodes {
            slug
            title
            link
            mi_supporter {
              miSupporterLink
              miSupporterLogo {
                sourceUrl
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        }
    `}
    render={(data) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <LogoWallComplete data={data.allWpSupporter? data.allWpSupporter.nodes : []} {...props} />
    }}
  />
)

export { LogoWall as default }
