import React from "react";
import styled from '@emotion/styled'

export default function IconPrev(props) {
  const { active } = props;

  return (
    <Icon>
      <StyledSVG 
        active={active}
        xmlns="http://www.w3.org/2000/svg"
        width="24" 
        height="24" 
        viewBox="0 0 24 24"
      >
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </StyledSVG>
    </Icon>
  )
}

const Icon = styled.div`
  filter: drop-shadow(0 0 2px rgba(0, 0, 0,0.55));
  width: 32px;
  height: 32px;
  @media(min-width: 768px){
    width: 50px;
    height: 50px;
  }
`

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #fff;
`
