import React from 'react';
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { setIsMapView, setIsCategoryView} from '../../state/actions'


const Menu = ( props ) => {
  const { data, open, setOpen, setIsMapView, setIsCategoryView } = props;
  const isHidden = !open;
  const tabIndex = isHidden ? 0 : -1;
  const triggerAction = (isOpen) => {
    setOpen(isOpen)
    setIsMapView(false)
    setIsCategoryView(false)
  }
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {data.map((item) => (
        <GatsbyLink key={item.url} tabIndex={tabIndex} to={item.path} onClick={() => triggerAction(!open)}>{item.label}</GatsbyLink>
      ))}
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.mi_white};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  transition-timing-function: ${({ open }) => open ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ open }) => open ? '250ms' : '200ms'};
  height: 100vh;
  text-align: left;
  padding: 48px;
  position: fixed;
  top: 0;
  right: 0;
  transition-property: transform opacity;

  opacity: ${({ open }) => open ? '1' : '0'};
  justify-content: center;
  z-index: 16;

  height: 100vh;
  background-color: ${({ theme }) => theme.mi_white };
  width: calc(100vw - 96px);
  align-items: flex-start;
  @media (min-width: ${({ theme }) => theme.tablet_p}) {

  }


  @media (max-width: ${({ theme }) => theme.mobile_l}) {

    }
  a {
    font-family: SofiaW01;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
    color: ${({ theme }) => theme.mi_black};
    text-decoration: none;
    transition: color .3s linear;
    margin-bottom: 42px;

    @media (max-width: ${({ theme }) => theme.mobile_l}) {

    }
    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;


const HamburgerMenu = (props) => {
  const {
    setIsMapView,
    setIsCategoryView
  } = props;
  return (
    <StaticQuery
      query={graphql`
            query {
                wpMenu(name: { eq: "Hauptnavigation" }) {
                    menuItems {
                        nodes {
                            url
                            label
                            target
                            path
                        }
                    }
                }
            }
        `}
      render={(data) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
        return <Menu setIsCategoryView={setIsCategoryView} setIsMapView={setIsMapView} data={data.wpMenu.menuItems? data.wpMenu.menuItems.nodes : []} {...props} />
      }}
    />
  )}

const mapStateToProps = state => {
  // console.log('mapStateToProps footer: ', state)
  return {
    isMapView: state.map.isMapView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu)