function initialized(state = 0, action ){
  switch( action.type ) {
  case "SET_ACTIVE_PAGE_ANCHOR":
    console.log('\n\n\nSET_ACTIVE_PAGE_ANCHOR')
    console.log('action: ', action)
    return {
      ...state,
      active_page_anchor_id: action.active_page_anchor.id,
    };
    
  default:
    // console.log('SET_INIATLIZED_TO_TRUE default count')
    return state
  }
}

export default initialized;




