import React from 'react'
import MoabitLayout from './moabit'

const Layout = ( props ) => {
  const { children, pageContext, location, data } = props;
  const participants = data && data.allWpParticipant && data.allWpParticipant.nodes.sort((a, b) => {
    // console.log('a: ', a);
    // console.log('b: ', b);
    const aT = a.mi_participant.miParticipantShorttitle ? a.mi_participant.miParticipantShorttitle : a.title;

    const bT = b.mi_participant.miParticipantShorttitle ? b.mi_participant.miParticipantShorttitle : b.title;


    return aT.toLowerCase().localeCompare(bT.toLowerCase());
  }) || [];




  const singleParticipant =  data && data.wpParticipant ? { ...data.wpParticipant.mi_participant, title: data.wpParticipant.title, id: data.wpParticipant.databaseId } : null;

  const initialLayout = pageContext.layout
  const initialCategoryId = pageContext.categoryDatabaseId || null;
  const initialCategoryName = pageContext.categoryName
  let initialIsCategoryView;
  let initialIsMapView;
  let initialParticipantId;

  const lunrIndex = data && data.LunrIndex;


  switch(initialLayout){
  case 'category':
    initialIsMapView = true;
    initialIsCategoryView = true;
    initialParticipantId = participants.length ? 
      participants.filter(
        p => p.participantCategories.nodes[0].databaseId === initialCategoryId
      )[0].databaseId
      : null;
    break;
  case 'category_index': 
    initialIsMapView = true;
    initialIsCategoryView = true;
    initialParticipantId = participants.length ? participants[0].databaseId : null;

    break;
  case 'participant': 
    initialIsMapView = true;
    initialIsCategoryView = false;
    initialParticipantId = singleParticipant.id;
    break;
  default:
    initialIsMapView = false;
    initialIsCategoryView = false;
    break;
  }

  return (
    <MoabitLayout 
      categoryDatabaseId={initialCategoryId} 
      pageContext={pageContext} 
      places={participants} 
      allSourceParticipants={participants} 
      pathName={location.pathname} 
      singleParticipant={singleParticipant}
      initialLayout={initialLayout}
      initialCategoryId={initialCategoryId}
      initialCategoryName={initialCategoryName}
      initialParticipantId={initialParticipantId}
      initialIsCategroyView={initialIsCategoryView}
      initialIsMapView={initialIsMapView}
      lunrIndex={lunrIndex || null}
    >
      {children}
    </MoabitLayout>
  )
}

export default Layout
